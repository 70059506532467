import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import _camelCase from "lodash/camelCase";
import _values from "lodash/values";
// import { loadSiteData } from 'store/localStorage';

/*
 * THEME EDITOR
 */

// reducer
const themeEditor = (state = Map({}), action) => {
  const { inProcess, message } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { design } = action; // ,
      return state.merge(fromJS(design));

    case CONST.THEME_EDITOR_REQUEST:
      return state.set("isLoaded", false);

    case CONST.THEME_EDITOR_SUCCESS:
      return state.set("isLoaded", true);

    case CONST.PREVIEW_THEME_STYLE:
      const { styleIndex } = action; // @NOTE do we want these as objects or Maps?

      if (!styleIndex) {
        return state.deleteIn(["previewData", "styleIndex"]);
      }
      return state.setIn(["previewData", "styleIndex"], styleIndex);
    case CONST.RESET_PREVIEW_DATA:
      return state.set("previewData", fromJS({}));

    /*
    TODO: Confirm definitively this is not necessary. As of 11/2024,
    no code is referencing this state value. The List component and
    its children were the only components in the app that had logic
    to handle a needRefresh prop, but that code has been removed.
    */
    // case CONST.SET_THEME_STYLE_SUCCESS:
    //   return state.set('needRefresh', true);

    case CONST.ADD_STYLE_REQUEST:
      return state.merge(fromJS({ inProcess, processComplete: undefined }));

    case CONST.ADD_STYLE_SUCCESS:
      return state.merge(fromJS({ inProcess, processComplete: "success" }));

    // case ADD_THEME_SUCCESS :
    // case UPDATE_THEME_SUCCESS:
    // case UPDATE_THEME_FAILURE:

    case CONST.ADD_STYLE_FAILURE:
    case CONST.ADD_THEME_FAILURE:
      return state.merge(fromJS({ inProcess, processComplete: message }));

    case CONST.RESET_PROCESS_COMPLETE:
      return state.merge(
        fromJS({ inProcess: false, processComplete: undefined })
      );

    default:
      return state;
  }
};

/**
 *
 * @description this selector will get the props that are needed for the ThemeBrowser component
 * @param state
 * @param ownProps
 * @return Object
 */
export const getThemeBrowserProps = (state, ownProps) => {
  const themeEditor = state.getIn(["ui", "themeEditor"]).toJS();

  const isUILoaded = state.getIn(["ui", "themeEditor", "isLoaded"]); // to know when the ui data is in place

  const slugBase = `${ownProps.location.pathname}/`; // gets passed down to compoents for linking and such

  // const editorUI = state.get(uiNode); // top level area of CMS
  const uiBaseScreen =
    isUILoaded && themeEditor.baseScreen ? themeEditor.baseScreen : {}; // target in the ui node..

  // we dont get the themelist until both the UINode and the data is present - 2 different loads
  const themeList = isUILoaded
    ? _values(state.getIn(["data", "themes"]).toJS())
    : [];
  const isLoading = !themeList;

  return {
    isLoading, // is data being retreived?
    // used by main container
    themeList,
    sidebarMode: state.getIn(["base", "mainSidebar", "mode"]),
    slugBase,
    // used by sidebar
    backAction: "/",
    header: uiBaseScreen.label || "",
    sidebar: uiBaseScreen.sidebar || ""
  };
};
/**
 *
 * @description this selector will get the props that are needed for the main themeEditor component
 * @param
 * @return Object
 */
export const getThemeEditorProps = (state, ownProps) => {
  const params = ownProps && ownProps.match ? ownProps.match.params : {};
  const { themeId, sectionId, subsectionId, subsubsectionId } = params; // used to target the theme in the collection
  // const uiNode = 'themeEditor'; // node we target to get UI configuration
  const dataNode = "themes"; // how we find and target the node with data for this part of CMS

  const slugBase = `${dataNode}/${themeId}`; // gets passed down to compoents for linking and such
  const themeEditor = state.getIn(["ui", "themeEditor"]).toJS();
  const editorUI = state.get(uiNode, Map());

  const isUILoaded = state.getIn(["ui", "themeEditor", "isLoaded"], false); // the sub compoents can't display until we have UI data
  let navigation = state.getIn(["ui", "themeEditor", "navigation"], Map([]));
  const themeIndex = _camelCase(themeId);
  // should have the index to select the theme so could use that...
  const activeData = state.getIn(["data", dataNode, themeIndex], Map()); // this will return a map, that could be empty
  const revertCopy = state.getIn(["data", dataNode, "revertCopy"], Map());

  const isLoaded = isUILoaded && activeData; // the sub compoents can't display until we have UI data & the users data
  // const savingMessage;
  const isSaving = isUILoaded && activeData && activeData.get("isSaving");
  const isSaved = isUILoaded && activeData && activeData.get("isSaved");

  let backAction = slugBase;

  // deal with targeting the screen UI data based on the url params
  const sectionData = activeData.get(_camelCase(sectionId), Map()); // data for the population of inputs

  let uiSectionData = Map(); // data related to the UI screen
  if (isUILoaded && sectionId) {
    uiSectionData = navigation.get(_camelCase(sectionId), Map()); // information about the UI section
    navigation = uiSectionData.get("children");
  }

  if (isUILoaded && subsectionId) {
    backAction += `/${sectionId}`;
    uiSectionData = navigation.get(_camelCase(subsectionId), Map());
    navigation = uiSectionData.get("children");
  }

  if (isUILoaded && subsubsectionId) {
    backAction += `/${subsectionId}`;
    uiSectionData = navigation.get(_camelCase(subsubsectionId), Map());
    navigation = uiSectionData.get("children");
  }

  let actionBarData = null;
  if (isUILoaded && uiSectionData && uiSectionData.get("actionBar")) {
    // const allStyles = state.getIn(['data','styles']).toJS(); // use a selector on reducer to get
    actionBarData = uiSectionData.get("actionBar", Map()).toJS();
    actionBarData.stylesStore = state.getIn(["data", "styles"], Map()); // @NOTE may not need to pass this if we handle in the action
  }

  // ---------------------
  // previewer related
  const siteInfo = loadSiteData() || {};
  const preview = state.getIn(["ui", "themeEditor", "previewData"], Map());
  const previewPathBase =
    siteInfo && siteInfo.previewPathBase ? siteInfo.previewPathBase : "";
  const previewPath = previewPathBase + (siteInfo.siteID || "");
  let previewTheme = Map(); //  // default to the active theme...
  previewTheme = previewTheme.set("style", activeData.get("style"));
  // @TODO add layout and whatever else here
  // if a an index is set then replace value from the active
  const styleIndex = preview.get("styleIndex");
  if (styleIndex) {
    const styleSet = state.getIn(["data", "styles", styleIndex]);
    previewTheme = previewTheme.set("style", styleSet);
  }
  const layoutIndex = preview.get("layoutIndex");
  if (layoutIndex) {
    const layoutSet = state.getIn(["data", "layouts", layoutIndex]);
    previewTheme = previewTheme.set("layout", layoutSet);
  }

  return {
    // uiNode :  uiNode, // passed down to sub components that are not connected
    // editorData : (editorUI && editorUI.toJS()) || {},
    sectionId,
    navigation: navigation.toJS(),

    // dataNode : dataNode, // passed down to sub components that are not connected
    themeIndex: _camelCase(themeId),
    themeData: activeData.toJS(),
    revertCopy: revertCopy.toJS(),
    sidebarMode: `${state.getIn(["base", "mainSidebar", "mode"])}Extra`,
    isSaving,
    isSaved,
    isLoaded,
    isLoading: !isLoaded, // used by content container
    inProcess: editorUI.get("inProcess", false), // indicators that something is in process the ui needs to wait on
    processComplete: editorUI.get("processComplete", ""),

    backAction: `/${backAction}`,
    slug: `${slugBase}/${sectionId}`,
    slugBase: `/${slugBase}`,

    header: activeData.get("label", ""),
    subheader: uiSectionData.get("label", ""),
    sectionData: sectionData.toJS(), // this will be passed down to the list to populate

    actionBarData, // @NOTE this is ONLY relavant if present in the node.
    previewPath,
    previewTheme: previewTheme.toJS()
  };
};

// export const getThemePreviewerProps = (state, ownProps) => {
//     const siteInfo = loadSiteData(); //from local storage
//     const {themeIndex} = ownProps;
//     const previewPath = siteInfo && siteInfo.previewPathBase? siteInfo.previewPathBase + (siteInfo.siteID || '') : '';
//     // from themeEditor preview data we learn the styleId, layoutId if present
//     // start
//     const activeTheme = state.getIn(['data', 'themes', themeIndex],Map());
//     const preview = state.getIn(['themeEditor', 'previewData'],Map());
//     let previewTheme = Map();//  // default to the active theme...
//         previewTheme = previewTheme.set('style', activeTheme.get('style'));
//
//     //@TODO add layout and whatever else here
//     // if a stlye index is set then replace
//     const styleIndex = preview.get('styleIndex',undefined);
//     if(styleIndex){
//         const styleSet = state.getIn(['data','styles', styleIndex]);
//         previewTheme = previewTheme.set('style', styleSet)
//     }
//     return {
//         //siteInfo : siteInfo ,//
//         previewPath : previewPath,
//         previewTheme : previewTheme && previewTheme.toJS()
//     }
// }

export default themeEditor;
