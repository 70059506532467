import React, { Component } from "react";
import PropTypes from "prop-types";

import { Slider } from "@material-ui/lab";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Image } from "@material-ui/icons";

import "./CardBrowserSlider.scss";

export class CardBrowserSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue,
      isDragging: false
    };
    this.handleSlider = this.handleSlider.bind(this);
  }

  handleSlider(event, value) {
    this.setState({ value: value });
    if (typeof this.props.changeValue !== "undefined") {
      this.props.changeValue(value);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.initialValue) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  render() {
    const muiTheme = createMuiTheme({
      slider: {
        trackColor: this.props.sliderRightTrackColor,
        selectionColor: this.props.sliderLeftTrackColor
      }
    });

    const iconColor = this.props.iconColor;

    const iconStyles = {
      left: {
        float: "left",
        height: 18,
        width: 18
      },
      right: {
        float: "right",
        height: 32,
        width: 32
      }
    };

    const sizes = {
      min: parseInt(this.props.minSize, 10),
      max: parseInt(this.props.maxSize, 10),
      def: parseInt(this.props.defSize, 10)
    };

    return (
      <div
        className={`card-browser-slider`}
        onClick={this.handleSearchButtonClick}
      >
        <div className="icon-container-left">
          <Image color={iconColor} style={iconStyles.left} />
        </div>

        <MuiThemeProvider muiTheme={muiTheme}>
          <Slider
            className="slider"
            min={sizes.min}
            max={sizes.max}
            defaultValue={sizes.def}
            value={this.state.value}
            onChange={this.handleSlider}
          />
        </MuiThemeProvider>

        <div className="icon-container-right">
          <Image color={iconColor} style={iconStyles.right} />
        </div>
      </div>
    );
  }
}

CardBrowserSlider.defaultProps = {
  initialValue: 1,
  iconColor: "rgba(255, 255, 255, 0.7)",
  minSize: 80,
  maxSize: 340,
  defSize: 200,
  minMargin: 10,
  sliderLeftTrackColor: "#029bE5",
  sliderRightTrackColor: "rgba(255, 255, 255, 0.27)"
};

CardBrowserSlider.propTypes = {
  initialValue: PropTypes.number,
  changeValue: PropTypes.func,
  iconColor: PropTypes.string,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  defSize: PropTypes.number,
  minMargin: PropTypes.number,
  sliderLeftTrackColor: PropTypes.string,
  sliderRightTrackColor: PropTypes.string
};
