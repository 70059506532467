import React, { Component } from "react";
import PropTypes from "prop-types";
import { Video } from "cloudinary-react";

import _debounce from "lodash/debounce";

import { Button, IconButton, Tooltip } from "@material-ui/core";
import { Crop, Delete } from "@material-ui/icons";

import "./AssetListEditor.scss";

export class AssetListEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFrame: 0
    };
    this.hasImageData = this.hasImageData.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
  }

  hasImageData() {
    const cloudPublicId =
      this.props.cloudPublicId ||
      (this.props.value && this.props.value.cloudPublicId);
    const src = this.props.src || (this.props.value && this.props.value.src);

    return (
      (typeof cloudPublicId !== "undefined" && cloudPublicId) ||
      (typeof src !== "undefined" && src)
    );
  }

  componentWillMount() {
    this.handleFrameSetterDebounced = _debounce(function (time) {
      // const currentTime = time.target.currentTime;
      console.log("handleFrameSetterDebounced - time", time);
      this.onTimeUpdate.apply(this, [time]);
    }, 1000);
  }

  render() {
    //console.log('[ASSET LIST EDITOR] - this.props', this.props);
    const iconButtonStyle = {
      width: 40,
      height: 40,
      padding: 0
    };

    const iconStyle = {
      width: 24,
      height: 24,
      color: "white"
    };

    const imgStyle = {
      objectFit: this.props.imageFit
    };
    const imgConfig = this.props.config
      ? this.props.config
      : this.props.assetConfig
      ? this.props.assetConfig
      : {};
    const cloudPublicId =
      this.props.cloudPublicId ||
      (this.props.value && this.props.value.cloudPublicId);
    const cloudName =
      this.props.cloudName || (this.props.value && this.props.value.cloudName);
    const src = this.props.src || (this.props.value && this.props.value.src);

    return (
      <div className="asset-list-editor">
        {this.props.hasLabel ? (
          <div className="label-container">{this.props.label}</div>
        ) : null}
        {this.hasImageData() ? (
          <div className="asset-container" style={{ background: " #eee" }}>
            {this.props.showButtons ? (
              <div className="actions-container">
                {typeof this.props.handleEditClick !== "undefined" &&
                this.props.assetType === "image" ? (
                  <Tooltip title="Crop Image">
                    <IconButton
                      className="icon crop"
                      onClick={() => {
                        this.props.handleEditClick(this.props);
                        if (
                          typeof this.props.handleInputChange !== "undefined"
                        ) {
                          this.props.handleInputChange(
                            this.props.param,
                            this.props
                          );
                        }
                      }}
                      style={iconButtonStyle}
                      disableTouchRipple={true}
                    >
                      <Crop style={iconStyle} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {typeof this.props.handleDeleteClick !== "undefined" ? (
                  <Tooltip title="Delete">
                    <IconButton
                      className="icon delete"
                      onClick={() => {
                        this.props.handleDeleteClick(this.props);
                        if (
                          typeof this.props.handleInputChange !== "undefined"
                        ) {
                          this.props.handleInputChange(
                            this.props.param,
                            this.props
                          );
                        }
                      }}
                      style={iconButtonStyle}
                      disableTouchRipple={true}
                    >
                      <Delete style={iconStyle} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            ) : null}
            <div className="gradient-top" />
            {this.props.assetType === "image" ? (
              <img
                src={`${
                  location.protocol
                }//res.cloudinary.com/${cloudName}/image/upload/${
                  imgConfig.cropCoordinates
                    ? "c_crop,h_" +
                      imgConfig.cropCoordinates.height +
                      ",w_" +
                      imgConfig.cropCoordinates.width +
                      ",x_" +
                      imgConfig.cropCoordinates.x +
                      ",y_" +
                      imgConfig.cropCoordinates.y +
                      "/"
                    : ""
                }f_auto,q_auto,w_${
                  this.props.cloudCropMaxSize
                },c_scale/v1/${cloudPublicId}.jpg`}
                style={this.props.imgStyle}
              />
            ) : this.props.assetType === "pdf" ? (
              <img
                src={`${
                  location.protocol
                }//res.cloudinary.com/${cloudName}/image/upload/${
                  imgConfig.cropCoordinates
                    ? "c_crop,h_" +
                      imgConfig.cropCoordinates.height +
                      ",w_" +
                      imgConfig.cropCoordinates.width +
                      ",x_" +
                      imgConfig.cropCoordinates.x +
                      ",y_" +
                      imgConfig.cropCoordinates.y +
                      "/"
                    : ""
                }f_auto,q_auto,w_${
                  this.props.cloudCropMaxSize
                },c_scale/v1/${cloudPublicId}.jpg`}
                style={this.props.imgStyle}
              />
            ) : (
              <Video
                style={{ width: "100%" }}
                publicId={cloudPublicId}
                onTimeUpdate={e =>
                  this.handleFrameSetterDebounced(e.target.currentTime)
                }
                cloudName={cloudName}
                controls
              />
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={() => this.props.handleAddClick(this.props)}
            >
              Choose an Asset
            </Button>
          </div>
        )}
        {this.hasImageData() &&
        this.props.assetType === "video" &&
        typeof this.props.handleFrameSetterFunc !== "undefined" ? (
          <div
            className="set-frame-container"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            <Button
              variant="contained"
              onClick={() =>
                this.props.handleFrameSetterFunc(this.state.currentFrame)
              }
            >
              Set Frame as Poster
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  onTimeUpdate(time) {
    console.log("[onTimeUPdate] time", time);
    this.setState({ currentFrame: time });
  }
}

AssetListEditor.defaultProps = {
  imageFit: "contain",
  showButtons: true,
  assetType: "image",
  cloudCropMaxSize: 600
};

AssetListEditor.propTypes = {
  cloudName: PropTypes.string,
  cloudPublicId: PropTypes.string,
  handleFrameSetterFunc: PropTypes.func,
  handleInputChange: PropTypes.func,
  imageFit: PropTypes.string,
  handleEditClick: PropTypes.func,
  handleAddClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  showButtons: PropTypes.bool,
  assetType: PropTypes.string
};
