import React, { Component } from "react";
import { Image } from "cloudinary-react";

import ContentContainer from "modules/ContentContainer";
import "./Dashboard.scss";
import config from "config";

class Dashboard extends Component {
  render() {
    return (
      <div className="cms-screen dashboard">
        <ContentContainer>
          <div className="logo-container">
            <Image
              cloudName={config("cloudName")}
              publicId={"system/storycrafter-logo-1.png"}
            />
          </div>
          {/* <FooterContainer /> */}
        </ContentContainer>
      </div>
    );
  }
}

export default Dashboard;
