import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, useLocation } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import { Dialog, Notification } from "components";
import * as baseActions from "shared/actions";
import { getAppLayoutProps } from "shared/reducers";
import MainSidebar from "shared/modules/MainSidebar";
import Dashboard from "modules/Dashboard";
import Logout from "modules/Logout";
import AccountManager from "modules/AccountManager";
import InstanceBrowser from "modules/InstanceBrowser";
import ProjectEditor from "modules/ProjectEditor";
import StoryEditor from "modules/StoryEditor";
import FeedbackBrowser from "modules/FeedbackBrowser";
import FeedbackViewer from "modules/FeedbackViewer";
import Analytics from "modules/Analytics";
import ThemeBrowser from "modules/ThemeBrowser";
import ThemeEditor from "modules/ThemeEditor";
import Library from "modules/Library/Library";
import Error404 from "modules/Error404";
import QuizEditor from "modules/QuizEditor";

import "./Layout.scss";

//import SceneBrowser from 'modules/StoryEditor/SceneBrowser';
//import SceneEditor from 'modules/StoryEditor/SceneEditor';

// import {getLayoutProps} from 'reducers/base';

// Layout Parts Components
// import Header from 'shared/components/Header';
// import Footer from 'shared/components/Footer';
// import Menu from 'shared/components/Menu';

// The Project/Site Layout Configuration
// TODO: show loader while we are initalize the app...after they have logged in, but before fully init, dont know if we show empty mainba or not
const Layout = ({
  account,
  activeSidebar,
  error,
  handleLoginActionFromModal,
  isFetching,
  isInit,
  isSaved,
  isUIError,
  open,
  message,
  queueItemShown,
  resetUIError,
  toggleSidebar,
  uiErrorMessage
}) => {
  const location = useLocation();

  const handleCloseActionFromModal = () => {
    resetUIError();
  };

  if (error) {
    return (
      <div className="layout">
        <h1>Oops!</h1>
        <p>There was an issue initializing the Application. {error}</p>
      </div>
    );
  } else if (!isFetching && isInit) {
    return (
      <div className="layout">
        <MainSidebar
          {...activeSidebar}
          isSaved={isSaved}
          account={account}
          activeNav={location.pathname}
          toggleAction={toggleSidebar}
        />

        {/* All props on the components mounted below are defined in map 
              state to props methods that are executed when the components
              are mounted. */}
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/logout" component={Logout} />

          {activeSidebar.navigation.projectEditor ? (
            <Route exact path="/projects" component={InstanceBrowser} />
          ) : null}

          {activeSidebar.navigation.projectEditor ? (
            <Route
              exact
              path="/projects/:projectID"
              component={ProjectEditor}
            />
          ) : null}

          {activeSidebar.navigation.projectEditor ? (
            <Route
              exact
              path="/projects/:projectID/:section"
              component={ProjectEditor}
            />
          ) : null}

          {activeSidebar.navigation.accountManager ? (
            <Route exact path="/account" component={AccountManager} />
          ) : null}

          {activeSidebar.navigation.accountManager ? (
            <Route exact path="/account/users" component={AccountManager} />
          ) : null}

          {activeSidebar.navigation.accountManager ? (
            <Route
              exact
              path="/account/users/:userID"
              component={AccountManager}
            />
          ) : null}

          <Route exact path="/stories" component={InstanceBrowser} />
          <Route exact path="/stories/:storyID" component={StoryEditor} />

          <Route exact path="/analytics" component={Analytics} />

          <Route exact path="/feedback" component={FeedbackBrowser} />
          <Route
            exact
            path="/feedback/filterByScene/:sceneID"
            component={FeedbackBrowser}
          />
          <Route
            exact
            path="/feedback/:feedbackID"
            component={FeedbackViewer}
          />

          <Route
            exact
            path="/stories/:storyID/:section"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/subscenes/:subsceneID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/subscenes/:subsceneID/feedback"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/subscenes/:subsceneID/feedback/:feedbackID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/elements/:elementID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/:subsceneID/gallery/:imageID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/stories/:storyID/:section/:sceneID/:elementID"
            component={StoryEditor}
          />

          <Route exact path="/collections" component={InstanceBrowser} />
          <Route
            exact
            path="/collections/:collectionID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/collections/:collectionID/:section"
            component={StoryEditor}
          />
          <Route
            exact
            path="/collections/:collectionID/items/:itemID"
            component={StoryEditor}
          />
          <Route
            exact
            path="/collections/:collectionID/items/:itemID/subscenes/:subsceneID"
            component={StoryEditor}
          />

          <Route exact path="/design" component={ThemeBrowser} />
          <Route exact path="/design/:themeId" component={ThemeEditor} />
          <Route
            exact
            path="/design/:themeId/:sectionId"
            component={ThemeEditor}
          />

          <Route exact path="/library" component={Library} />
          <Route exact path="/library/new" component={Library} />
          <Route exact path="/library/view/:assetId" component={Library} />

          <Route exact path="/quiz" component={QuizEditor} />
          <Route exact path="/quiz/:section" component={QuizEditor} />
          <Route component={Error404} />
        </Switch>

        <Notification
          open={open}
          message={message}
          handleClose={queueItemShown}
          displayDuration={4000}
        />

        <Dialog
          content={
            'Your session has expired. No further changes can be saved. Press "Login" to start a new session. Press "Cancel" to dismiss this dialog so you can copy unsaved changes to avoid losing them.'
          }
          onConfirm={handleLoginActionFromModal}
          confirmLabel="Login"
          onCancel={handleCloseActionFromModal}
          open={
            isUIError &&
            (uiErrorMessage === "Error: Request failed with status code 401" ||
              uiErrorMessage === "Session Expired")
          }
          title="Notice: Session Expiration"
        />
      </div>
    );
  }

  return (
    <div className="layout progress-container">
      <CircularProgress
        className="progress-indicator"
        size={80}
        thickness={10}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => getAppLayoutProps(state, ownProps);

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { queId } = stateProps;
  return Object.assign({}, ownProps, stateProps, {
    handleLoginActionFromModal: () => ownProps.history.push("/logout"),
    queueItemShown: () => dispatchProps.queueItemShown(queId),
    toggleSidebar: () => dispatchProps.toggleSidebar(),
    resetUIError: () => dispatchProps.resetUIError()
  });
}

export default withRouter(
  connect(mapStateToProps, baseActions, mergeProps)(Layout)
);
