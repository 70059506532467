import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  SortableContainer,
  SortableElement,
  // SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import _map from "lodash/map";
import _isEqual from "lodash/isEqual";
import _size from "lodash/size";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";

import { CircularProgress } from "@material-ui/core";

import { Card } from "../Card";
import "./CardBrowser.scss";

// const DragHandle = SortableHandle(() => <div className="drag-handle">::</div>);

const SequentiableCard = SortableElement(
  ({ parent, ItemCard, CardContentsComponent, item, params, style }) => {
    return (
      <div className="neon-resizable-card" key={params.id} style={style}>
        {/*<DragHandle />*/}
        <ItemCard
          {...item}
          item={item}
          CardContentsComponent={CardContentsComponent}
          advancedSelectMode={parent.props.advancedSelectMode}
          titleBackgroundColor={
            parent.props.cardStyle
              ? parent.props.cardStyle.titleBackgroundColor
              : undefined
          }
          subtitleStyle={
            parent.props.cardStyle
              ? parent.props.cardStyle.subtitleStyle
              : undefined
          }
          backgroundColor={
            parent.props.cardStyle
              ? parent.props.cardStyle.backgroundColor
              : undefined
          }
          imageFit={
            parent.props.cardStyle ? parent.props.cardStyle.imageFit : undefined
          }
          fontColor={
            parent.props.cardStyle
              ? parent.props.cardStyle.fontColor
              : undefined
          }
          titleColor={
            parent.props.cardStyle
              ? parent.props.cardStyle.titleColor
              : undefined
          }
          id={params.id}
          itemName={parent.props.itemName}
          convertTypeToIcon={parent.props.convertTypeToIcon}
          type={item.type}
          onlySelectMode={typeof parent.props.editClickHandler === "undefined"}
          cloudCropMaxSize={parent.props.cardBrowserMaxCardSize}
          bottomRightIcons={
            item.bottomRightIcons ? item.bottomRightIcons : undefined
          }
          bottomLeftIcons={
            item.bottomLeftIcons ? item.bottomLeftIcons : undefined
          }
          captionPosition={parent.props.captionPosition}
          captionAlignment={parent.props.captionAlignment}
          captionHeight={parent.props.captionHeight}
          showButtons={parent.props.showButtons}
          imageHeight={parseInt(params.imageHeight, 10)}
          displayCaption={params.displayCaption}
          placeholderImageCloudId={
            parent.props.placeholderImageCloudId
              ? parent.props.placeholderImageCloudId
              : undefined
          }
          onlyOneSelected={
            parent.state.selected ? parent.state.selected.length === 1 : false
          }
          lastSelected={parent.props.lastSelected}
          selectMode={parent.state.selectMode}
          isShiftPressed={
            !parent.props.enableKeys ? false : parent.state.isShiftPressed
          }
          isCmdPressed={
            !parent.props.enableKeys ? false : parent.state.isCmdPressed
          }
          selected={parent.isSelected.call(parent, params.id)}
          selectHandler={parent.selectHandler}
          exclusiveSelectHandler={parent.exclusiveSelectHandler}
          shiftSelectHandler={parent.shiftSelectHandler}
          editHandler={parent.handleClickCard.bind(parent)}
          imgSrc={item.src ? parent.props.pathPrefix + item.src : undefined}
          slugBase={parent.props.slugBase}
          moreMenuOptions={parent.props.moreMenuOptions}
          handleMoreMenuChange={parent.props.handleMoreMenuChange}
        />
      </div>
    );
  }
);

const CardComponent = props => {
  const { parent, ItemCard, CardContentsComponent, item, params, style } =
    props;
  return (
    <div className="neon-resizable-card" key={params.id} style={style}>
      <ItemCard
        {...item}
        item={item}
        CardContentsComponent={CardContentsComponent}
        advancedSelectMode={parent.props.advancedSelectMode}
        titleBackgroundColor={
          parent.props.cardStyle
            ? parent.props.cardStyle.titleBackgroundColor
            : undefined
        }
        subtitleStyle={
          parent.props.cardStyle
            ? parent.props.cardStyle.subtitleStyle
            : undefined
        }
        backgroundColor={
          parent.props.cardStyle
            ? parent.props.cardStyle.backgroundColor
            : undefined
        }
        imageFit={
          parent.props.cardStyle ? parent.props.cardStyle.imageFit : undefined
        }
        fontColor={
          parent.props.cardStyle ? parent.props.cardStyle.fontColor : undefined
        }
        titleColor={
          parent.props.cardStyle ? parent.props.cardStyle.titleColor : undefined
        }
        id={params.id}
        itemName={parent.props.itemName}
        convertTypeToIcon={parent.props.convertTypeToIcon}
        type={item.type}
        placeholderImageCloudId={
          parent.props.placeholderImageCloudId
            ? parent.props.placeholderImageCloudId
            : undefined
        }
        onlySelectMode={typeof parent.props.editClickHandler === "undefined"}
        cloudCropMaxSize={parent.props.cardBrowserMaxCardSize}
        bottomRightIcons={
          item.bottomRightIcons ? item.bottomRightIcons : undefined
        }
        bottomLeftIcons={
          item.bottomLeftIcons ? item.bottomLeftIcons : undefined
        }
        captionPosition={parent.props.captionPosition}
        captionAlignment={parent.props.captionAlignment}
        captionHeight={parent.props.captionHeight}
        showButtons={parent.props.showButtons}
        imageHeight={parseInt(params.imageHeight, 10)}
        displayCaption={params.displayCaption}
        onlyOneSelected={
          parent.state.selected ? parent.state.selected.length === 1 : false
        }
        lastSelected={parent.props.lastSelected}
        selectMode={parent.state.selectMode}
        isShiftPressed={
          !parent.props.enableKeys ? false : parent.state.isShiftPressed
        }
        isCmdPressed={
          !parent.props.enableKeys ? false : parent.state.isCmdPressed
        }
        selected={parent.isSelected(params.id)}
        selectHandler={parent.selectHandler}
        exclusiveSelectHandler={parent.exclusiveSelectHandler}
        shiftSelectHandler={parent.shiftSelectHandler}
        editHandler={parent.handleClickCard}
        imgSrc={item.src ? parent.props.pathPrefix + item.src : undefined}
        slugBase={parent.props.slugBase}
      />
    </div>
  );
};

const SequentiableContainer = SortableContainer(
  ({ parent, ItemCard, CardContentsComponent, params, style }) => {
    let indexCount = -1;
    return (
      <div
        className="items-container"
        ref={input => {
          parent.itemsContainer = input;
        }}
        id="itemsContainer"
      >
        {parent.state.items &&
          _map(parent.state.items, item => {
            if (item) {
              indexCount++;
              const id = item._id;
              return (
                <SequentiableCard
                  parent={parent}
                  ItemCard={ItemCard}
                  CardContentsComponent={CardContentsComponent}
                  item={item}
                  key={id}
                  params={{ ...params, id: id }}
                  style={style}
                  isSequentiable={true}
                  index={indexCount}
                />
              );
            }
          })}
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }
);

export class CardBrowser extends Component {
  constructor(props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSelectBack = this.handleSelectBack.bind(this);
    this.resizingWindow = this.resizingWindow.bind(this);
    this.handleClickCard = this.handleClickCard.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    // this.handleCardLoaded = this.handleCardLoaded.bind(this);
    this.recalculateMeasures = this.recalculateMeasures.bind(this);
    this.exclusiveSelectHandler = this.exclusiveSelectHandler.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.shiftSelectHandler = this.shiftSelectHandler.bind(this);

    let items = [];
    // NOTE: not sure how we maintain the order of the items passed, if the items are already and array do we need this map?

    _map(this.props.items, item => {
      items.push(item);
    });

    this.state = {
      isCmdPressed: false,
      isShiftPressed: false,
      cardWidth: null,
      cardMargin: null,
      items: items,
      deselectAll: false,
      //visibleItems: [],
      visibleItemCount: 0,
      minCardSize: this.props.minCardSize,
      pageSize: 0,
      isLoading: true,
      selected: [],
      selectMode: false,
      lastSelected: null
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (!nextProps.forceLoad && this.state.forceLoad) {
    //   this.setState({forceLoad: false}, () => this.recalculateMeasures());
    // }
    if (nextProps.selectMode !== this.props.selectMode) {
      this.setState({ selectMode: nextProps.selectMode });
    }
    if (
      !_isEqual(this.state.selected, nextProps.selected) &&
      typeof nextProps.selected !== "undefined"
    ) {
      // console.log('CardBrowser: componentWillReceiveProps: Setting selected to: '+JSON.stringify(nextProps.selected));
      this.setState({ selected: nextProps.selected });
    }
    if (nextProps.minCardSize !== this.props.minCardSize) {
      this.setState({ minCardSize: nextProps.minCardSize }, () =>
        this.recalculateMeasures()
      );
    }
    if (
      nextProps.deselectAll !== this.props.deselectAll &&
      nextProps.deselectAll
    ) {
      this.setState({ selected: [], selectMode: false });
      if (typeof this.props.exclusiveSelectHandler !== "undefined") {
        this.props.exclusiveSelectHandler(null);
      }
    }
    const firstItemDifferent =
      typeof nextProps.items[0] !== "undefined" &&
      typeof this.props.items[0] !== "undefined" &&
      nextProps.items[0]._id !== this.props.items[0]._id;
    const lastItemItemDifferent =
      typeof nextProps.items[nextProps.items.length - 1] !== "undefined" &&
      typeof this.props.items[this.props.items.length - 1] !== "undefined" &&
      nextProps.items[nextProps.items.length - 1]._id !==
        this.props.items[this.props.items.length - 1]._id;

    let anItemHasConfig = false;
    _map(nextProps.items, (item, index) => {
      if (item) {
        if (
          (item.config &&
            typeof this.state.items[index] !== "undefined" &&
            !_isEqual(this.state.items[index].config, item.config)) ||
          (item.assetConfig &&
            typeof this.state.items[index] !== "undefined" &&
            !_isEqual(this.state.items[index].assetConfig, item.assetConfig))
        ) {
          anItemHasConfig = true;
        }
      }
    });

    if (
      _size(nextProps.items) !== _size(this.state.items) ||
      lastItemItemDifferent ||
      firstItemDifferent ||
      anItemHasConfig
    ) {
      let items = [];
      // console.log('[CARD BROWSER] - componentWillReceiveProps - updating items...');
      _map(nextProps.items, item => {
        if (item) {
          items.push(item);
        }
      });
      this.setState({ items: items }, () => this.recalculateMeasures());
    }
  }

  componentDidMount() {
    // console.log('CardBrowser: componentDidMount');
    // if (typeof this.props.executeOnScroll !== 'undefined') {
    document
      .getElementById("cardBrowser")
      .addEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.onScroll, true);
    // }
    window.addEventListener("resize", this.resizingWindow);

    // If it's not modal we trigger once the forceLoad process
    // if (!this.props.isModal) {
    //   this.setState({forceLoad: true});
    // }
    // this.setState({breakInterval: false, forceLoad: true});
    if (
      !_isEqual(this.state.selected, this.props.selected) &&
      typeof this.props.selected !== "undefined"
    ) {
      this.setState({ selected: this.props.selected });
    }

    // Setting SCROLL TOP
    // this.setScrollTop(this.props.scrollTop);
    this.resizingWindow();
    setTimeout(() => {
      this.resizingWindow();
    }, 1000);
  }

  resizingWindow() {
    // console.log('CardBrowser: resizingWindow');
    let clientWidth =
      this.cardBrowser && this.cardBrowser.clientWidth > 0
        ? this.cardBrowser.clientWidth
        : document.getElementById("assetSelector")
        ? document.getElementById("assetSelector").clientWidth
        : 500;
    let clientHeight =
      this.cardBrowser && this.cardBrowser.clientHeight > 0
        ? this.cardBrowser.clientHeight
        : document.getElementById("assetSelector")
        ? document.getElementById("assetSelector").clientHeight
        : 500;
    let totalWidth =
      this.props.sideMargins > 0
        ? clientWidth - 2 * this.props.sideMargins
        : clientWidth;
    let totalHeight =
      this.props.sideMargins > 0
        ? clientHeight - 2 * this.props.sideMargins
        : clientHeight;
    // console.log('[CardBrowser] - resizing Window...');
    // console.log('WIDTHS client', clientWidth, ' - Total', totalWidth);
    this.setState(
      {
        isCmdPressed: false,
        isShiftPressed: false,
        totalWidth,
        totalHeight
      },
      () => this.recalculateMeasures()
    );
  }

  recalculateMeasures() {
    let cardWidth = this.props.minCardSize;
    let margin = this.props.setCardMargin;
    let columns;
    // If this is set we have a grid based on fix spacing, otherwise the grid is based on fix item widths.
    if (this.props.setCardMargin) {
      // console.log('Total Width, ', this.state.totalWidth);
      columns =
        Math.floor(
          this.state.totalWidth /
            (this.props.minCardSize + this.props.setCardMargin)
        ) || 1;
      // console.log('Columns ', columns);
      const availableSpace =
        this.state.totalWidth - this.props.setCardMargin * columns || 0;
      // console.log('Available Space ', availableSpace);
      cardWidth =
        availableSpace / columns <= 0
          ? this.props.minCardSize
          : Math.floor(availableSpace / columns - 0.1);
      // console.log('Card Width ', cardWidth);
      margin = this.props.setCardMargin;
    } else {
      // Calculating number of columns and margins
      columns = Math.floor(this.state.totalWidth / this.props.minCardSize);
      let totalSpace = this.state.totalWidth - this.props.minCardSize * columns;
      let auxMargin = totalSpace / (columns + 1) - 0.1;

      // Applying corrector for minimum margin
      if (auxMargin < this.props.minCardMargin) {
        columns--;
        totalSpace = this.state.totalWidth - this.props.minCardSize * columns;
        auxMargin = totalSpace / (columns + 1) - 0.1;
      }
      cardWidth = this.props.minCardSize;
      margin = auxMargin > 0 ? auxMargin : 0;
      // console.log('Total Width ', this.state.totalWidth);
      // console.log('Columns ', columns);
      // console.log('auxMargin ', auxMargin);
    }
    //Calculating ROWS
    const cardHeight = this.getCardHeightFromWidth(cardWidth);
    const rows =
      Math.floor(
        this.state.totalHeight /
          (this.props.minCardSize + this.props.setCardMargin)
      ) || 1;

    if (
      this.state.cardMargin !== margin ||
      this.state.cardWidth !== cardWidth
    ) {
      this.setState({
        cardWidth: cardWidth,
        cardHeight: cardHeight,
        cardMargin: margin,
        columns: columns,
        rows: rows,
        pageSize: columns * rows,
        visibleItemCount:
          columns * (rows + 1) > this.state.visibleItemCount
            ? columns * (rows + 1)
            : this.state.visibleItemCount,
        isLoading: false
      });
    }
  }

  // Handling KeyUp event to reset state
  handleKeyUp() {
    // console.log('CardBrowser: handleKeyUp');
    this.setState({ isShiftPressed: false, isCmdPressed: false });
  }

  // Handling keyDown events to propagate down the cards to react accordingly when clicking the items
  handleKeyDown(event) {
    // console.log('CardBrowser: handleKeyDown');
    if (!this.props.enableKeys) return;
    if (event.keyCode === 16) {
      // console.log('SHIFT PRESSED');
      this.setState({ isShiftPressed: true });
    } else if (event.keyCode === 91) {
      // console.log('CMD PRESSED');
      this.setState({ isCmdPressed: true });
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return !(this.state.isShiftPressed !== nextState.isShiftPressed ||
  //     this.state.isCmdPressed !== nextState.isCmdPressed);
  // }

  // Forcing focus on itemsContainer so we can capture always the onKeyDown events
  handleMouseEnter() {
    // console.log('CardBrowser: handleMouseEnter');
    // console.log('handleMouseEnter - ');
    if (!this.props.enableKeys) return;
    document.getElementById("cardBrowser").focus();
  }

  // Method to handle the click on the background of the card browser
  handleSelectBack(event) {
    // console.log('CardBrowser: handleSelectBack');
    event.preventDefault();
    event.stopPropagation();
    this.setState({ selected: [], selectMode: false, lastSelected: null });
    if (typeof this.props.backClickHandler !== "undefined") {
      this.props.backClickHandler(null);
    }
  }

  // Method to handle click on card editing button - which links to the URL
  handleClickCard(url, coords) {
    // console.log('CardBrowser: handleClickCard');
    if (
      typeof document.getElementById("cardBrowser") !== "undefined" &&
      document.getElementById("cardBrowser") !== null
    ) {
      const topScroll = document.getElementById("cardBrowser").scrollTop;
      if (typeof this.props.setScrollPosition !== "undefined") {
        this.props.setScrollPosition(topScroll);
      }
    }
    if (typeof this.props.setBrowsingMode !== "undefined") {
      this.props.setBrowsingMode(false);
    }
    this.props.editClickHandler(url, coords);
  }

  getCardHeightFromWidth(cardWidth) {
    // console.log('CardBrowser: getCardHeightFromWidth');
    const displayCaption =
      cardWidth >= this.props.minWidthCaption && this.props.displayCaption;
    if (this.props.captionPosition === "below" && displayCaption) {
      return cardWidth + this.props.captionHeight;
    }
    return cardWidth;
  }

  render() {
    const ItemCard = this.props.card || Card;
    const cardWidth = this.state.cardWidth;
    const margin = this.state.cardMargin;

    // Finding out if we have to display caption and the minimum width is enough to display the caption
    const displayCaption =
      cardWidth >= this.props.minWidthCaption && this.props.displayCaption;

    // For a 1:1 aspect ratio
    let cardHeight = this.getCardHeightFromWidth(cardWidth);
    const imageHeight = cardWidth;

    let style = {
      width: cardWidth,
      height: cardHeight,
      margin: margin / 2,
      display: "block"
    };
    let counter = 0;
    return (
      <div
        key="card-browser"
        className={`cms-card-browser ${this.props.className}`}
        id="cardBrowser"
        ref={input => {
          this.cardBrowser = input;
        }}
        onClick={this.handleSelectBack}
        onKeyDown={!this.props.enableKeys ? null : this.handleKeyDown}
        onKeyUp={!this.props.enableKeys ? null : this.handleKeyUp}
        tabIndex="1"
        style={{ padding: `0px ${this.props.sideMargins}px` }}
        onMouseEnter={this.handleMouseEnter}
      >
        {this.state.isLoading ? (
          <div key="progress-container" className="progress-container">
            <CircularProgress
              size={40}
              thickness={5}
              className="progress-indicator"
            />
          </div>
        ) : null}
        {this.props.headContent ? this.props.headContent : null}
        {this.props.isSequentiable ? (
          <SequentiableContainer
            parent={this}
            helperClass={`slide-thumb-helper ${this.props.thumbHelperClassName}`}
            axis="xy"
            onSortEnd={this.onSortEnd}
            ItemCard={ItemCard}
            CardContentsComponent={this.props.CardContentsComponent}
            pressDelay={200}
            params={{
              imageHeight: imageHeight,
              displayCaption: displayCaption
            }}
            style={style}
          />
        ) : (
          <div
            key="items-container"
            className="items-container"
            ref={input => {
              this.itemsContainer = input;
            }}
            id="itemsContainer"
          >
            {!this.state.isLoading &&
              this.state.items &&
              _map(this.state.items, item => {
                if (item) {
                  const id = item._id;
                  if (counter < this.state.visibleItemCount) {
                    counter++;
                    return (
                      <CardComponent
                        parent={this}
                        key={id}
                        ItemCard={ItemCard}
                        CardContentsComponent={this.props.CardContentsComponent}
                        item={item}
                        params={{
                          imageHeight: imageHeight,
                          displayCaption: displayCaption,
                          id: id
                        }}
                        style={style}
                      />
                    );
                  }
                }
              })}
            <div key="clear-div" style={{ clear: "both" }} />
          </div>
        )}
      </div>
    );
  }

  selectHandler(assetId) {
    // console.log('CardBrowser: selectHandler: assetId='+assetId);
    const existingSelected = _cloneDeep(this.state.selected);
    // console.log('CardBrowser: selectHandler: typeof existingSelected='+typeof existingSelected);
    if (typeof existingSelected === "string") {
      if (assetId === existingSelected) {
        this.setState({
          selected: [],
          selectMode: false,
          lastSelected: assetId
        });
      } else {
        this.setState({
          selected: assetId,
          selectMode: false,
          lastSelected: assetId
        });
      }
    } else {
      if (typeof this.props.selectHandler !== "undefined") {
        this.props.selectHandler(assetId);
      }
      const index = existingSelected.indexOf(assetId);
      // console.log('CardBrowser: selectHandler: index='+index);
      const selectMode = existingSelected.length > 0;
      // console.log('CardBrowser: selectHandler: selectMode='+selectMode);
      if (index === -1) {
        existingSelected.push(assetId);
        this.setState({ lastSelected: assetId });
      } else {
        existingSelected.splice(index, 1);
        this.setState({ lastSelected: null });
      }
      // console.log('CardBrowser: selectHandler: existingSelected='+JSON.stringify(existingSelected));
      this.setState({ selected: existingSelected, selectMode });
    }
  }

  exclusiveSelectHandler(assetId) {
    // console.log('CardBrowser: exclusiveSelectHandler: assetId='+assetId);
    // console.log('CardBrowser: exclusiveSelectHandler: this.state.selected='+JSON.stringify(this.state.selected));
    // console.log('CardBrowser: exclusiveSelectHandler: this.state.selected === 1 ?'+(this.state.selected === 1));
    if (this.state.selected) {
      // console.log('CardBrowser: exclusiveSelectHandler: this.state.selected.length='+this.state.selected.length);
      // console.log('CardBrowser: exclusiveSelectHandler: this.state.selected[0] === assetId ?'+(this.state.selected[0] === assetId));
    }
    // Why are we testing for this.state.selected === 1? That will never
    // be true. The state.selected property is an array. Did Omar mean to
    // check the length of the array?
    const removingOneAsset = this.state.selected
      ? this.state.selected.length === 1 && this.state.selected[0] === assetId
        ? true
        : false
      : false;
    // console.log('CardBrowser: exclusiveSelectHandler: removingOneAsset='+removingOneAsset);
    const selected = assetId && !removingOneAsset ? [assetId] : [];
    // console.log('CardBrowser: exclusiveSelectHandler: selected=',selected);
    this.setState({
      selected,
      selectMode: selected.length > 0,
      lastSelected: assetId
    });

    // console.log('CardBrowser: exclusiveSelectHandler: typeof this.props.exclusiveSelectHandler='+typeof this.props.exclusiveSelectHandler);
    if (typeof this.props.exclusiveSelectHandler !== "undefined") {
      this.props.exclusiveSelectHandler(assetId);
    }
  }

  shiftSelectHandler(assetId) {
    // console.log('CardBrowser: shiftSelectHandler');
    if (!this.state.lastSelected) {
      return this.exclusiveSelectHandler(assetId);
    }
    const items = this.state.items;
    const lastSelected = this.state.lastSelected;
    const existingSelected = this.state.selected;
    if (typeof existingSelected === "string") {
      return this.exclusiveSelectHandler(assetId);
    }
    const lastIndex = _findIndex(items, o => o._id === lastSelected);
    const selectedIndex = _findIndex(items, o => o._id === assetId);

    // If last and current clicked item are the same we execute an exclusive select
    if (lastIndex === selectedIndex) {
      return this.exclusiveSelectHandler(assetId);
    }
    let selectMode = true;
    // Finding out what items need to be selected in the range
    const first = lastIndex > selectedIndex ? selectedIndex : lastIndex;
    const last = lastIndex > selectedIndex ? lastIndex : selectedIndex;

    for (let i = first; i <= last; i++) {
      const activeItem = items[i];
      // Adding only assets that are not already selected
      if (_findIndex(existingSelected, o => o._id === activeItem._id) === -1) {
        existingSelected.push(activeItem._id);
      }
    }
    if (existingSelected.length < 1) selectMode = false;
    this.setState({
      selected: existingSelected,
      selectMode,
      lastSelected: assetId
    });
    if (typeof this.props.shiftSelectHandler !== "undefined") {
      this.props.shiftSelectHandler(assetId, items);
    }
  }

  onSortEnd({ oldIndex, newIndex }, event) {
    // console.log('CardBrowser: onSortEnd', oldIndex, newIndex);
    event.preventDefault();
    event.stopPropagation();
    // console.log('on Sort End', oldIndex, newIndex);
    if (oldIndex === newIndex) {
      const assetId =
        this.state.items[oldIndex]._id || this.state.items[oldIndex].id;
      this.exclusiveSelectHandler(assetId);
      return;
    }
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex)
    });
    if (typeof this.props.executeOnReSort !== "undefined") {
      this.props.executeOnReSort(oldIndex, newIndex);
    }
  }

  isSelected(assetId) {
    // console.log('CardBrowser: isSelected');
    if (typeof this.state.selected === "undefined") return false;
    if (
      typeof this.state.selected === "object" &&
      this.state.selected.length > 0
    ) {
      return this.state.selected.indexOf(assetId) !== -1;
    } else if (typeof this.state.selected === "string") {
      return this.state.selected === assetId;
    }
    return false;
  }

  setScrollTop(scrollTop) {
    // console.log('CardBrowser: setScrollTop');
    if (typeof scrollTop === "undefined") return false;
    // console.log('Setting scroll top - ');
    const curScrollTop = document.getElementById("cardBrowser").scrollTop;
    if (curScrollTop !== scrollTop) {
      document.getElementById("cardBrowser").scrollTop = scrollTop;
    }
  }

  componentWillUnmount() {
    // console.log('CardBrowser: componentWillUnmount');
    if (document.getElementById("cardBrowser")) {
      document
        .getElementById("cardBrowser")
        .removeEventListener("scroll", this.onScroll);
    }
    window.removeEventListener("scroll", this.onScroll);
    if (typeof this.props.exclusiveSelectHandler !== "undefined") {
      this.props.exclusiveSelectHandler(null);
    }
    window.removeEventListener("resize", this.resizingWindow);
    this.setState({ breakInterval: true });
  }

  isScrollThreshold(margin) {
    // console.log('CardBrowser: isScrollThreshold');
    const topScroll = this.cardBrowser ? this.cardBrowser.scrollTop : 0;
    const cardBrowser = this.cardBrowser ? this.cardBrowser.clientHeight : 0;
    const itemsContainer = this.itemsContainer
      ? this.itemsContainer.clientHeight
      : 0;

    // console.log(' MEASURES cardBrowser: ', this.cardBrowser.clientHeight, ' -  itemsContainer: ', this.itemsContainer.clientHeight, ' - srollTop', this.cardBrowser.scrollTop);
    if (itemsContainer === 0) return false;
    const bottomScroll = topScroll + cardBrowser;
    //console.log('isScrollThreshold - topScroll:', topScroll, ' - cardBrowser:', cardBrowser, ' - itemsContainer:', itemsContainer, " - bottomScroll: ", bottomScroll, " - result: ", itemsContainer - bottomScroll < margin);
    return itemsContainer - bottomScroll < margin;
  }

  onScroll() {
    // console.log('CardBrowser: onScroll');
    // if (!this.props.browsingMode && typeof this.props.setBrowsingMode !== 'undefined') {
    //   this.props.setBrowsingMode(true);
    //   return;
    // }
    // console.log('Scrolling....');
    // We trigger the addPage functionality only when we have a small difference between the scroll position and the
    // container
    // if (
    //   this.isScrollThreshold(100) &&
    //   typeof this.props.executeOnScroll !== 'undefined') {
    //   // Then needs to load more...
    //   // console.log('[CardBrowser] - OnScroll - Executing on Scroll...');
    //   this.props.executeOnScroll(this.state.columns);
    // }
    if (this.isScrollThreshold(100)) {
      // this.setState({isLoading: true});
      // console.log('[CARD BROWSER] items:', this.state.items.length, ", visibleCount:", this.state.visibleItemCount)
      // Increasing PAGE
      if (_size(this.state.items) > this.state.visibleItemCount) {
        // console.log('[CARD BROWSER] - onScroll - Increasing Page');
        const visibleItemCount =
          this.state.visibleItemCount + this.state.pageSize >
          _size(this.state.items)
            ? _size(this.state.items)
            : this.state.visibleItemCount + this.state.pageSize;
        // call the function for parent if passed...
        // if(this.props.executeOnScroll ){
        //   this.props.executeOnScroll(visibleItemCount);
        // }
        this.setState({ visibleItemCount: visibleItemCount });
      }
    }
  }
}

CardBrowser.defaultProps = {
  advancedSelectMode: true,
  items: [],
  minCardSize: 200,
  cardBrowserMaxCardSize: 600,
  lastSelected: null,
  minCardMargin: 10,
  captionHeight: 28,
  minWidthCaption: 135,
  browsingMode: false,
  enableKeys: true,
  setCardMargin: 0,
  displayCaption: true,
  captionPosition: "below",
  itemName: "Asset",
  captionAlignment: "left",
  pathPrefix: "",
  sideMargins: 0,
  convertTypeToIcon: false,
  openModal: false,
  isModal: false,
  placeholderImageCloudId: "system/scene_thumb_default"
};

CardBrowser.propTypes = {
  // Data
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Items containing the data to display
  slugBase: PropTypes.string, // Slug base to build URLs
  pathPrefix: PropTypes.string,
  headContent: PropTypes.node,
  // Functions
  setBrowsingMode: PropTypes.func, // Function to execute when setting browsing mode
  executeOnScroll: PropTypes.func, // Function to execute on Scroll
  selectHandler: PropTypes.func, // Function to execute when selecting a card
  backClickHandler: PropTypes.func, // Function to execute when clicking in the background
  exclusiveSelectHandler: PropTypes.func, //
  shiftSelectHandler: PropTypes.func,
  editClickHandler: PropTypes.func,
  setScrollPosition: PropTypes.func,
  executeOnReSort: PropTypes.func,
  //Configuration
  // isLoading: PropTypes.bool,
  advancedSelectMode: PropTypes.bool, // this flag forces the bind between select and exclusiveSelect
  placeholderImageCloudId: PropTypes.string,
  sideMargins: PropTypes.number,
  itemName: PropTypes.string, // The name of the card item (ie. 'asset', 'story', etc)
  minCardMargin: PropTypes.number,
  setCardMargin: PropTypes.number, // If set, the grid will be build considering the card margin and not the width
  displayCaption: PropTypes.bool, // Hide/Show caption
  captionPosition: PropTypes.string, // Allows 'over', 'below'
  captionAlignment: PropTypes.string, // Allows 'left','center','right', 'top-left','top-center','top-right','bottom-left','bottom-center','bottom-right'
  captionHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidthCaption: PropTypes.number, // Min width of the card in order to display caption
  browsingMode: PropTypes.bool,
  minCardSize: PropTypes.number, // Card Width
  cardBrowserMaxCardSize: PropTypes.number, // Max card size to set in Cloudinary Width
  scrollTop: PropTypes.number,
  convertTypeToIcon: PropTypes.bool, // If True, the card will expect a type string that will convert in a bottom icon.
  deselectAll: PropTypes.bool, // When set to true forces the cards to be unselected.
  // selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  lastSelected: PropTypes.string,
  showButtons: PropTypes.bool, // Boolean to enable/disable buttons and vignettes
  enableKeys: PropTypes.bool, // Boolean to enable Cmd/Ctrl and Shift keys actions when selecting items
  cardStyle: PropTypes.object, // Card styles
  openModal: PropTypes.bool, // Used to indicate that a modal has been opened
  isModal: PropTypes.bool, // Flag to indicate that is a modal
  isSequentiable: PropTypes.bool
};
