import React from "react";
import PropTypes from "prop-types";

import _debounce from "lodash/debounce";
import _map from "lodash/map";

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import ListItem from "../List/ListItem";
import "./MultiForm.scss";

export class MultiForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.list ? this.props.list : []
    };
    this.addNewAction = this.addNewAction.bind(this);
    this.removeRowAction = this.removeRowAction.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeDebounced = _debounce(function (row, param, value) {
      this.handleInputChange.apply(this, [row, param, value]);
    }, 300);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.list !== nextProps.list) {
      this.setState({
        rows: nextProps.list
      });
    }
  }

  addNewAction() {
    let currentRows = this.state.rows;
    const newEmptyRow = Object.assign({}, this.state.objectModel);
    currentRows.push(newEmptyRow);
    this.setState({ rows: currentRows });
    if (typeof this.props.handleInputChange !== "undefined") {
      this.props.handleInputChange(this.props.param, currentRows);
    }
  }

  removeRowAction(id) {
    console.log("removeRowAction", id);
    let currentRows = this.state.rows;
    currentRows.splice(id, 1);
    this.setState({ rows: currentRows });
    if (typeof this.props.handleInputChange !== "undefined") {
      this.props.handleInputChange(this.props.param, currentRows);
    }
  }

  componentDidMount() {
    let objectModelTemplate = {};
    _map(this.props.options, optionForm => {
      objectModelTemplate[optionForm.param] = null;
    });
    const objectModel = Object.assign({}, objectModelTemplate);
    this.setState({ objectModel: objectModel });
  }

  handleInputChange(row, param, value) {
    const parsedRow = parseInt(row);
    let rows = this.state.rows;
    if (this.props.injectLanguage) {
      const altRows = rows;
      altRows[parsedRow] = {
        ...this.state.rows[parsedRow],
        [param]: { [this.props.language]: value }
      };
      if (typeof this.props.handleInputChange !== "undefined") {
        this.props.handleInputChange(this.props.param, altRows);
      }
    } else {
      rows[parsedRow] = { ...this.state.rows[parsedRow], [param]: value };
      if (typeof this.props.handleInputChange !== "undefined") {
        this.props.handleInputChange(this.props.param, rows);
      }
    }

    this.setState({ rows: rows });
  }

  render() {
    let counter = 1;
    return (
      <div className="multiform-container">
        {this.props.label ? (
          <div className="label-container">{this.props.label}</div>
        ) : null}
        {this.state.rows ? (
          <Table
            className="mf-table"
            wrapperStyle={{ height: "auto", overflow: "visible" }}
            selectable={false}
          >
            <TableBody displayRowCheckbox={false}>
              {_map(this.state.rows, (rowData, key) => {
                const parsedKey = parseInt(key);
                if (isNaN(parsedKey)) return;
                return (
                  <TableRow className="multiform-row" key={key}>
                    <TableCell
                      className="mf-column"
                      style={{
                        width: 23,
                        paddingRight: 0,
                        paddingRight: 15
                      }}
                    >
                      {parsedKey + 1}.
                    </TableCell>
                    {_map(this.props.options, form => {
                      return (
                        <TableCell
                          className={`mf-column ${form.param}`}
                          key={form.param}
                        >
                          {
                            <ListItem
                              handleInputChange={(a, b) => {
                                form.type === "assetEditor"
                                  ? undefined
                                  : this.handleInputChangeDebounced(key, a, b);
                              }}
                              row={key}
                              handleAddClick={
                                form.type === "assetEditor"
                                  ? this.props.handleAddClick
                                  : undefined
                              }
                              handleDeleteClick={
                                form.type === "assetEditor"
                                  ? this.props.handleDeleteClick
                                  : undefined
                              }
                              handleEditClick={
                                form.type === "assetEditor"
                                  ? this.props.handleEditClick
                                  : undefined
                              }
                              data={this.state.rows[parsedKey][form.param]}
                              item={{ ...form, inlineLabel: true }}
                              styleName={this.props.styleName}
                            />
                          }
                        </TableCell>
                      );
                    })}
                    <TableCell className="mf-column" style={{ width: 30 }}>
                      <IconButton
                        onClick={() => this.removeRowAction(parsedKey)}
                      >
                        <Close />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : null}
        <Button
          className="add-new-label"
          onClick={this.addNewAction}
          color="primary"
        >
          {this.props.addNewLabel}
        </Button>
      </div>
    );
  }
}

MultiForm.defaultProps = {
  addNewLabel: "Add Option"
};

MultiForm.propTypes = {
  options: PropTypes.object,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  param: PropTypes.string,
  addNewLabel: PropTypes.string,
  styleName: PropTypes.string,
  injectLanguage: PropTypes.bool,
  language: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleAddClick: PropTypes.func,
  handleDeleteClick: PropTypes.func
};
