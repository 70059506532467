import React from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";

import "./InputText.scss";

const Parchment = Quill.import("parchment");

var classAttributor = new Parchment.Attributor.Class("class", "class", {
  scope: Parchment.Scope.BLOCK,
  whitelist: [
    "display",
    "title",
    "subtitle",
    "subheading",
    "body",
    "quote",
    "quote_attribution",
    "caption_title",
    "caption",
    "credit",
    "overline"
  ]
});
Quill.register(classAttributor);

var scaleAttributor = new Parchment.Attributor.Class("scale", "scale", {
  scope: Parchment.Scope.BLOCK,
  whitelist: [
    "primary",
    "secondary",
    "extra_large",
    "large",
    "medium",
    "small",
    "extra_small"
  ]
});
Quill.register(scaleAttributor);

const CustomToolbar = ({ type, id, onExtendedClick }) => {
  return (
    <div id={`toolbar-${id}`} className="ql-toolbar ql-snow">
      <div className="default-buttons">
        {type === "rte-ext-class" && (
          <span className="ql-formats">
            <select className="ql-class">
              <option value="display">Display</option>
              <option value="title">Title</option>
              <option value="subtitle">Subtitle</option>
              <option value="subheading">Subheading</option>
              <option value="body">Body</option>
              <option value="quote">Quote</option>
              <option value="quote_attribution">Quote Attribution</option>
              <option value="caption_title">Caption Title</option>
              <option value="caption">Caption</option>
              <option value="credit">Credit</option>
              <option value="overline">Overline</option>
            </select>
            <select className="ql-scale">
              <option value="extra_large">Extra Large</option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
              <option value="extra_small">Extra Small</option>
              <option value="primary">Primary</option>
              <option value="secondary">Secondary</option>
            </select>
          </span>
        )}

        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
        </span>

        <span className="ql-formats">
          <select className="ql-align" />
        </span>

        <span className="ql-formats extended-button-group group-2">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </span>
      </div>

      <div className="extended-toggle">
        <button onClick={onExtendedClick}></button>
      </div>

      <div className="extended-buttons">
        <span className="ql-formats extended-button-group group-1">
          <button className="ql-strike"></button>
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>

        <span className="ql-formats extended-button-group group-3">
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>

        <span className="ql-formats extended-button-group group-4">
          <button className="ql-link" value></button>
          <button className="ql-clean"></button>
        </span>
      </div>
    </div>
  );
};

export class QuillRTE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFromInput || props.defaultValue || ""
    };
    this.extended = false;
    this.formatclasses = {
      class: "",
      scale: ""
    };
    this.quillRef = null;
    this.preventOnChange = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelection = this.handleChangeSelection.bind(this);
    this.handleExtendedClick = this.handleExtendedClick.bind(this);
  }

  setFormatClasses() {
    if (this.quillRef) {
      const toolbarEl = document.querySelector(`#toolbar-${this.props.name}`);
      const fullEditor = this.quillRef.getEditor();
      const format = fullEditor.getFormat();

      if (this.formatclasses.class !== "") {
        toolbarEl.classList.remove(this.formatclasses.class);
      }
      if (this.formatclasses.scale !== "") {
        toolbarEl.classList.remove(this.formatclasses.scale);
      }

      this.formatclasses.class = format.class
        ? `ql-picker-item-class-${format.class}`
        : "";
      this.formatclasses.scale = format.scale
        ? `ql-picker-item-scale-${format.scale}`
        : "";

      if (this.formatclasses.class !== "") {
        toolbarEl.classList.add(this.formatclasses.class);
      }
      if (this.formatclasses.scale !== "") {
        toolbarEl.classList.add(this.formatclasses.scale);
      }
    }
  }

  handleChange(content, delta, source, editor) {
    if (this.state.value !== content && typeof this.state.value !== "object") {
      this.setState({ value: content });
      if (this.quillRef) {
        if (this.props.toolbarType === "rte-ext-class") {
          this.setFormatClasses();
        }
      }
      if (typeof this.props.onChange !== "undefined") {
        this.props.onChange(this.props.name, content);
      }
    }
  }

  handleChangeSelection(range, source, editor) {
    if (this.props.toolbarType === "rte-ext-class") {
      /*
      Don't try to setFormatClass if the editor doesn't have
      focus, which will be the case if the user clicked in the
      editor and then clicked another control. Without this
      check, focus will be restored to the editor as soon as
      the user clicks another control.
      */
      if (this.quillRef && editor.getSelection()) {
        const fullEditor = this.quillRef.getEditor();
        const tooltipEl = fullEditor.container.querySelector(".ql-tooltip");
        if (tooltipEl.classList.value.includes("ql-hidden")) {
          this.setFormatClasses();
        }
      }
    }
  }

  handleExtendedClick() {
    const toolbarEl = document.querySelector(`#toolbar-${this.props.name}`);
    if (this.extended) {
      toolbarEl.classList.remove("extended");
    } else {
      toolbarEl.classList.add("extended");
    }
    this.extended = !this.extended;
  }

  componentDidMount() {
    this.quillRef.blur();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({
        value: nextProps.defaultValue
      });
    }
  }

  render() {
    const modules = {};

    modules.clipboard = {
      matchVisual: false
    };

    const formats = ["bold", "italic", "underline", "strike", "script"];
    let rteClassName = "";

    switch (this.props.toolbarType) {
      case "rte":
        rteClassName = "rte-1";
        modules.toolbar = {
          container: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              { script: "sub" },
              { script: "super" },
              "clean"
            ]
          ]
        };
        break;

      case "rte-ext-class":
        rteClassName = "rte-3";
        modules.toolbar = {
          container: `#toolbar-${this.props.name}`
        };
        formats.push("list", "align", "indent", "class", "scale", "link");
        break;

      case "rte-ext":
        rteClassName = "rte-2";
        modules.toolbar = {
          container: `#toolbar-${this.props.name}`
        };
        formats.push("list", "align", "indent", "link");
        break;
    }
    if (ReactQuill) {
      return (
        <div
          style={this.props.inputStyle}
          className={`cms-input-text rte-container ${rteClassName} ${
            this.props.name
          } ${
            this.props.required &&
            (this.state.value === null ||
              this.state.value === undefined ||
              this.state.value === "")
              ? "invalid-input"
              : ""
          } ${this.props.styleName} ${this.props.theme}`}
        >
          <label
            className="quill-label"
            htmlFor={this.props.name}
            style={this.props.labelStyle}
          >
            {this.props.label}
          </label>

          {(this.props.toolbarType === "rte-ext" ||
            this.props.toolbarType === "rte-ext-class") && (
            <CustomToolbar
              type={this.props.toolbarType}
              id={this.props.name}
              onExtendedClick={this.handleExtendedClick}
            />
          )}

          <ReactQuill
            ref={el => (this.quillRef = el)}
            name={this.props.name}
            modules={modules}
            value={this.state.value}
            onChange={this.handleChange}
            theme={this.props.theme}
            formats={formats}
            onChangeSelection={this.handleChangeSelection}
            bounds={`.cms-input-text.${this.props.name} .quill`}
          />
        </div>
      );
    } else {
      // It doesn't appear that this condition is ever met.
      return (
        <div
          style={this.props.inputStyle}
          className={`cms-input-text rte-container ${
            this.props.required &&
            (this.state.value === null ||
              this.state.value === undefined ||
              this.state.value === "")
              ? "invalid-input"
              : ""
          }`}
        >
          <label htmlFor={param}>{this.props.label}</label>
        </div>
      );
    }
  }
}

QuillRTE.defaultProps = {
  defaultValue: "",
  inputStyle: {},
  labelStyle: {},
  theme: "snow"
};

QuillRTE.propTypes = {
  defaultValue: PropTypes.any,
  styleName: PropTypes.string,
  theme: PropTypes.string,
  inputStyle: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]),
  labelStyle: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};
