import React, { Component } from "react";
import PropTypes from "prop-types";

import _map from "lodash/map";
import _findIndex from "lodash/findIndex";

import ListItem from "./ListItem";
import "./List.scss";

/**
 * @description this component allows for the creation of a list of components
 * they could be inputs, buttons, or navigation items
 * the default type assumes an input, and its default is a text input
 * @props (Array)
 *
 */

class List extends Component {
  constructor(props) {
    super(props);
    this.state = { items: { ...props.items }, data: { ...props.data } };
  }

  // TODO: Determine if this is really necessary.
  // componentWillReceiveProps(nextProps) {
  //   this.setState({ items: nextProps.items, data: nextProps.data });
  // }

  render() {
    // Checking if items need sorting
    let itemsToBeOrdered = [];
    let itemsNotOrdered = [];
    let orderedTotal = [];
    _map(this.state.items, item => {
      if (typeof item.order !== "undefined") {
        itemsToBeOrdered.push(item);
      } else {
        itemsNotOrdered.push(item);
      }
    });
    itemsToBeOrdered.sort((a, b) => a.order - b.order);
    // _map(itemsToBeOrdered, item => {
    //   orderedTotal.push(item);
    // });
    // _map(itemsNotOrdered, item => {
    //   orderedTotal.push(item);
    // });
    orderedTotal = orderedTotal.concat(itemsToBeOrdered, itemsNotOrdered);

    return (
      <div className="cms-list col-sm-12" id={this.props.id}>
        {_map(orderedTotal, item => {
          const key = item.param || item.label;
          // If the item in the list is an asset for an assetEditor, we pass all the data inside and do not look for the key
          // @TODO: Find a better way of handling the above
          const data = this.state.data
            ? item.type === "assetEditor"
              ? this.state.data[key] || this.state.data
              : typeof this.state.data[key] !== "undefined"
              ? this.state.data[key]
              : ""
            : null;
          return (
            <ListItem
              data={data}
              handleInputChange={this.props.handleInputChange}
              handleEditClick={this.props.handleEditClick}
              handleAddClick={this.props.handleAddClick}
              handleDeleteClick={this.props.handleDeleteClick}
              handleFrameSetterFunc={this.props.handleFrameSetterFunc}
              icon={this.props.icon}
              injectLanguage={this.props.injectLanguage}
              language={this.props.language}
              styleName={this.props.styleName}
              slugBase={this.props.slugBase}
              key={item.param || item.label}
              item={item}
            />
          );
        })}
      </div>
    );
  }
}

List.defaultProps = {
  styleName: "standard"
};

List.propTypes = {
  handleInputChange: PropTypes.func,
  icon: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  muiTheme: PropTypes.object, // Object inheriting Material UI Context styles
  styleName: PropTypes.string,
  slugBase: PropTypes.string,
  injectLanguage: PropTypes.bool,
  language: PropTypes.string,
  handleEditClick: PropTypes.func,
  handleAddClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleFrameSetterFunc: PropTypes.func,
  data: PropTypes.object
};

// export default List;
export default List;
