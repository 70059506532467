import React, { Component } from "react";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";

import { Clear, Search } from "@material-ui/icons";

import "./CardBrowserSearch.scss";

export class CardBrowserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.search ? this.props.search : "",
      focused: this.props.search ? true : false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.search) {
      this.setState({ value: nextProps.search });
    }
  }

  componentWillMount() {
    this.handleSearchDebounced = _debounce(function () {
      this.props.action.apply(this, [this.state.value]);
    }, 1000);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.handleSearchDebounced();
  }

  handleSearchButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    let newState = this.state;
    newState.focused = true;
    this.setState(newState);
    document.getElementById("search-input-field").focus();
  }

  handleClearButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ value: "", focused: false });
    this.props.action("");
  }

  handleBlur(event) {
    event.preventDefault();
    event.stopPropagation();
    let newState = this.state;
    newState.focused = this.state.value ? true : false;
    this.setState(newState);
  }

  render() {
    const iconStyles = {
      clear: {
        float: "right",
        marginRight: 0,
        marginTop: 0,
        color: "#2E2E2E",
        cursor: "pointer",
        height: 24,
        width: 24
      },
      search: {
        float: "left",
        marginLeft: 0,
        marginTop: 0,
        height: 24,
        width: 24,
        color: this.props.iconColor,
        cursor: "pointer"
      }
    };

    const cardSearchStyle = {
      color: this.props.cardSearchColor,
      height: this.props.cardSearchHeight,
      minWidth: this.props.cardSearchMinWidth
    };

    return (
      <div
        className={`card-browser-search ${this.state.focused ? "focused" : ""}`}
        style={cardSearchStyle}
        onClick={this.handleSearchButtonClick}
      >
        <Search className="search-icon" style={iconStyles.search} />
        <input
          className={`search-input ${
            this.state.value.length > 0 ? "with-value" : ""
          }`}
          onChange={this.handleChange}
          placeholder="Search"
          onBlur={this.handleBlur}
          id="search-input-field"
          value={this.state.value}
        />
        {this.state.value.length > 0 ? (
          <Clear
            className="clear-icon"
            style={iconStyles.clear}
            onClick={this.handleClearButtonClick}
          />
        ) : null}
      </div>
    );
  }
}

CardBrowserSearch.defaultProps = {
  action: [],
  search: "",
  iconColor: "rgba(255, 255, 255, 0.7)",
  cardSearchColor: "rgba(255, 255, 255, 0.30)",
  cardSearchHeight: 44,
  cardSearchMinWidth: 400
};

CardBrowserSearch.propTypes = {
  action: PropTypes.func,
  search: PropTypes.string,
  // Styles
  iconColor: PropTypes.string,
  cardSearchColor: PropTypes.string,
  cardSearchHeight: PropTypes.number,
  cardSearchMinWidth: PropTypes.number
};
