import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import _find from "lodash/find";

import { List } from "components";
import Sidebar from "../../../Sidebar/Sidebar";
import ContentContainer from "modules/ContentContainer";
import { basemapEditorMapStateToProps } from "mapToProps/sceneEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";
import config from "config";

export class BasemapEditor extends React.Component {
  constructor(props) {
    super(props);
    const existingData =
      typeof this.props.activeEditingScene !== "undefined" &&
      typeof this.props.activeEditingScene.elements !== "undefined"
        ? this.props.activeEditingScene.elements.basemap
        : null;
    this.state = {
      tab: "details",
      isDirty: this.props.activeEditingScene.isSaved
        ? !this.props.activeEditingScene.isSaved
        : false,
      styleDropdown: null,
      mapboxAccessToken: null,
      mapboxStyleURL: null,
      data: {
        basemap: {
          config: {
            longitude: existingData ? existingData.config.longitude : 0,
            latitude: existingData ? existingData.config.latitude : 0,
            zoomLevel: existingData ? existingData.config.zoomLevel : 1,
            pitch: existingData ? existingData.config.pitch : 0,
            bounds: existingData ? existingData.config.bounds : null,
            style: existingData
              ? existingData.config.style
              : "mapbox://styles/mapbox/streets-v10",
            bearing: existingData ? existingData.config.bearing : 0,
            layers: existingData ? existingData.config.layers : null,
            maxBounds: existingData ? existingData.config.maxBounds : null,
            minZoom: existingData ? existingData.config.minZoom : null,
            maxZoom: existingData ? existingData.config.maxZoom : null
          },
          accessToken: existingData ? existingData.accessToken : null
        }
      }
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMapConfig = this.setMapConfig.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeEditingScene.elements) {
      if (typeof nextProps.activeEditingScene.isSaved !== "undefined") {
        this.setState({
          isDirty: !nextProps.activeEditingScene.isSaved || this.state.isDirty
        });
      }
      this.setMapConfig(nextProps);
    }
  }

  setMapConfig(nextProps) {
    this.setState({
      styleDropdown: _find(nextProps.forms.basemapStyle.options, {
        value: nextProps.activeEditingScene.elements.basemap.config.style
      })
        ? nextProps.activeEditingScene.elements.basemap.config.style
        : "custom",
      mapboxStyleURL:
        nextProps.activeEditingScene.elements.basemap.config.style,
      mapboxAccessToken:
        nextProps.activeEditingScene.elements.basemap.accessToken,
      data: {
        ...nextProps.activeEditingScene.elements
      }
    });

    // Adjust settings that can be adjusted in the editor. Things like
    // min/max Zoom and layers can't be adjusted in the CMS as of 9/3/2022.
    const mapConfig = nextProps.activeEditingScene.elements.basemap.config;
    this.map.setStyle(mapConfig.style);
    this.map.setCenter([
      this.getParsedCoord(mapConfig.longitude),
      this.getParsedCoord(mapConfig.latitude)
    ]);
    this.map.setPitch(this.getParsedCoord(mapConfig.pitch));
    this.map.setBearing(this.getParsedCoord(mapConfig.bearing));
    this.map.setZoom(this.getParsedCoord(mapConfig.zoomLevel, 1));
    // It appears the call to setMaxBounds was accidentally committed.
    // Leaving this code as an example of how to use this feature if
    // we ever decide we need it in the destination app, where it would
    // be most appropriate.
    // this.map.setMaxBounds(mapConfig.bounds);
  }

  getParsedCoord(value, def) {
    const defaultVal = typeof def === "undefined" ? 0 : def;
    if (typeof value === "undefined" || value === null) return defaultVal;
    return parseFloat(value);
  }

  getParsedBounds(bounds) {
    const parsedBounds = [
      [bounds._sw.lng, bounds._sw.lat],
      [bounds._ne.lng, bounds._ne.lat]
    ];
    return parsedBounds;
  }

  componentDidMount() {
    const mapboxgl = require("mapbox-gl");
    const mapboxGeocoder = require("mapbox-gl-geocoder");
    mapboxgl.accessToken = this.state.data.basemap.accessToken
      ? this.state.data.basemap.accessToken
      : config("mapboxAccessToken");

    const mapConfig =
      this.state.data && this.state.data.basemap
        ? this.state.data.basemap.config
        : null;

    let mapProps;
    if (mapConfig) {
      mapProps = {
        style: mapConfig.style,
        container: this.mapContainer,
        center: [
          this.getParsedCoord(mapConfig.longitude),
          this.getParsedCoord(mapConfig.latitude)
        ],
        pitch: [this.getParsedCoord(mapConfig.pitch)],
        bearing: [this.getParsedCoord(mapConfig.bearing)],
        zoom: [this.getParsedCoord(mapConfig.zoomLevel, 1)]
      };

      // Account for the fact that not all base maps have
      // certain properties defined.
      if (mapConfig.bounds) {
        mapProps.bounds = mapConfig.bounds;
      }
      if (mapConfig.minZoom) {
        mapProps.minZoom = mapConfig.minZoom;
      }
      if (mapConfig.maxZoom) {
        mapProps.maxZoom = mapConfig.maxZoom;
      }
      if (mapConfig.maxBounds) {
        mapProps.maxBounds = mapConfig.maxBounds;
      }
    }
    this.map = new mapboxgl.Map(mapProps);
    this.map.addControl(
      new mapboxGeocoder({
        accessToken: mapboxgl.accessToken
      })
    );
    this.map.addControl(new mapboxgl.NavigationControl());

    if (mapConfig.layers) {
      this.map.on("load", () => {
        mapConfig.layers.forEach(layer => {
          const { type, paint, beforeId, source } = layer;
          this.map.addSource(source.id, {
            type: source.config.type,
            url: `https://res.cloudinary.com/${config(
              "cloudName"
            )}/image/upload/${source.config.cloudPublicId}`,
            coordinates: source.config.coordinates
          });
          this.map.addLayer(
            {
              id: `${source.id}-layer`,
              type,
              source: source.id,
              paint
            },
            beforeId
          );
        });
      });
    }

    this.map.on("move", () => {
      const { lng, lat } = this.map.getCenter();
      this.setState({
        data: {
          ...this.state.data,
          basemap: {
            ...this.state.data.basemap,
            config: {
              ...this.state.data.basemap.config,
              latitude: this.getParsedCoord(lat.toFixed(8)),
              bearing: this.getParsedCoord(this.map.getBearing()),
              pitch: this.getParsedCoord(this.map.getPitch()),
              longitude: this.getParsedCoord(lng.toFixed(8)),
              zoomLevel: this.getParsedCoord(this.map.getZoom().toFixed(2), 1),
              bounds: this.getParsedBounds(this.map.getBounds())
            }
          }
        },
        isDirty: true
      });
    });
    if (this.props.activeEditingScene.elements) {
      this.setMapConfig(this.props);
    }
  }

  render() {
    return (
      <div className="basemap-editor">
        <Helmet>
          <title>Map Basemap Editor</title>
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v2.2.0/mapbox-gl-geocoder.css"
            type="text/css"
          />
        </Helmet>
        <Sidebar
          goBackAction={this.props.slugBase}
          showButtons={this.state.isDirty}
          handleSave={this.handleSave}
          saveLabel="Save"
          title="Base Map"
        >
          <br />
          <div>
            {this.state.styleDropdown !== "custom" &&
            _find(this.props.forms.basemapStyle.options, {
              value: this.state.styleDropdown
            }) ? (
              <List
                items={
                  this.props.forms
                    ? { basemapStyle: this.props.forms.basemapStyle }
                    : null
                }
                data={{ style: this.state.styleDropdown }}
                handleInputChange={this.handleInputChange}
                styleName={"plain"}
              />
            ) : (
              <List
                items={
                  this.props.forms
                    ? {
                        basemapStyle: this.props.forms.basemapStyle,
                        divider: this.props.forms.customBasemapSettings.divider,
                        mapboxAccessToken:
                          this.props.forms.customBasemapSettings
                            .mapboxAccessToken,
                        mapboxStyleURL:
                          this.props.forms.customBasemapSettings.mapboxStyleURL
                      }
                    : null
                }
                data={{
                  style: "custom",
                  mapboxAccessToken: this.state.mapboxAccessToken,
                  mapboxStyleURL: this.state.mapboxStyleURL
                }}
                handleInputChange={this.handleInputChange}
                styleName={"plain"}
              />
            )}
          </div>
        </Sidebar>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="basemap-content-container"
          sidebarMode={this.props.sidebarMode}
          headerTheme="light"
        >
          <div
            className="geography-form-container"
            ref={el => (this.mapContainer = el)}
          />
        </ContentContainer>
      </div>
    );
  }

  handleDeleteAsset(data) {
    this.props.sceneSetAssetElement("featuredAsset", "");
    this.setState({ isDirty: true });
  }

  handleAddAsset(data) {
    this.props.openAssetSelectorFunc("featuredAsset", false);
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.updateScene(this.state.data);
  }

  handleInputChange(param, value) {
    if (param === "style") {
      if (value !== "custom") {
        this.map.setStyle(value);
      }
    } else if (param === "mapboxStyleURL") {
      if (value !== "") {
        const mapboxGeocoder = require("mapbox-gl-geocoder");
        if (this.state.data.basemap.accessToken) {
          this.map.addControl(
            new mapboxGeocoder({
              accessToken: this.state.data.basemap.accessToken
            })
          );
          this.map.setStyle(value);
        }
      }
    }

    switch (param) {
      case "style":
        this.setState({
          data: {
            ...this.state.data,
            basemap: {
              ...this.state.data.basemap,
              config: {
                ...this.state.data.basemap.config,
                style:
                  value !== "custom"
                    ? value
                    : this.state.data.basemap.config.style
              }
            }
          },
          styleDropdown: value,
          mapboxStyleURL: this.state.data.basemap.mapboxStyleURL,
          mapboxAccessToken: this.state.data.basemap.mapboxAccessToken,
          isDirty: true
        });
        break;
      case "mapboxStyleURL":
        this.setState({
          data: {
            ...this.state.data,
            basemap: {
              ...this.state.data.basemap,
              config: { ...this.state.data.basemap.config, style: value }
            }
          },
          mapboxStyleURL: value,
          isDirty: true
        });
        break;

      case "mapboxAccessToken":
        this.setState({
          data: {
            ...this.state.data,
            basemap: {
              ...this.state.data.basemap,
              config: { ...this.state.data.basemap.config },
              accessToken: value
            }
          },
          mapboxAccessToken: value,
          isDirty: true
        });
        break;
    }
  }

  handleTabChange(value) {
    this.setState({
      tab: value
    });
  }
}

BasemapEditor.defaultProps = {};

BasemapEditor.propTypes = {
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  slugBase: PropTypes.string,
  sidebarMode: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return basemapEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { activeEditingScene } = stateProps;
  return Object.assign({}, stateProps, {
    updateScene: sceneData => {
      const sceneType = "map-places";

      dispatchProps.updateScene(
        activeEditingScene._id,
        sceneData,
        null,
        sceneType
      );
    }
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps)(BasemapEditor)
);
