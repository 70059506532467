import React from "react";
import { Link, useLocation } from "react-router-dom";
import _map from "lodash/map";

import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { MenuItem } from "components";
import "./MainSidebar.scss";
import styles from "./MainSidebar.scss";
const iconColor = styles.iconColor;

const MainSidebar = ({
  bottomNavigation,
  isSaved = true,
  logo = { textLarge: "", textSmall: "" },
  mode = "large",
  navigation,
  siteName,
  toggleAction
}) => {
  const location = useLocation();
  const handleSidebarToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    toggleAction();
  };

  const homeTo = logo && logo.to ? logo.to : "/";
  const iconStyleProps = {
    iconStyle: {
      width: 34,
      height: 34,
      color: iconColor
    },
    style: {
      width: 60,
      height: 60,
      padding: 1
    }
  };

  let itemsToBeOrdered = [];
  let itemsNotOrdered = [];
  let orderedTotal = [];
  _map(navigation, item => {
    if (typeof item.order !== "undefined") {
      itemsToBeOrdered.push(item);
    } else {
      itemsNotOrdered.push(item);
    }
  });
  itemsToBeOrdered.sort((a, b) => a.order - b.order);
  _map(itemsToBeOrdered, item => {
    orderedTotal.push(item);
  });
  _map(itemsNotOrdered, item => {
    orderedTotal.push(item);
  });

  return (
    <div
      className={`main-sidebar ${mode === "large" ? "expanded" : "collapsed"}`}
    >
      <div className="logo-container">
        <Link to={homeTo}>
          {mode === "large" ? (
            <h1>{logo.textLarge}</h1>
          ) : (
            <h1>{logo.textSmall}</h1>
          )}
        </Link>
      </div>
      <div className="main-menu">
        {orderedTotal &&
          _map(orderedTotal, item => (
            <MenuItem
              key={item.param || item.label}
              preventLink={!isSaved}
              preventLinkMessage="If you exit without saving, your changes will be lost."
              activeNav={location.pathname}
              {...item}
              mode={mode}
            />
          ))}
      </div>

      <div className="bottom-navigation">
        <div className="site-name-container">
          {mode === "large" ? siteName : null}
        </div>

        <div className="bottom-menu">
          {bottomNavigation &&
            _map(bottomNavigation, item => (
              <MenuItem
                key={item.param || item.label}
                {...item}
                preventLink={!isSaved}
                preventLinkMessage="If you exit without saving, your changes will be lost."
                activeNav={location.pathname}
                smallIcons={true}
                mode={mode}
              />
            ))}
        </div>

        <div className="sidebar-toggle" onClick={handleSidebarToggle}>
          <IconButton {...iconStyleProps} onClick={handleSidebarToggle}>
            {mode === "large" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          {mode === "large" ? (
            <div className="toggle-label">Close Menu</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MainSidebar;
