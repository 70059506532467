import React, { Component } from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

// import "./CardBrowserSelectedDisplay.scss";

export class CardBrowserSelectedDisplay extends Component {
  render() {
    return (
      <div className="card-browser-selected-display">
        <IconButton onClick={() => this.props.deselectAllAction(null)}>
          <Close />
        </IconButton>
        <span className="selected-container">
          {this.props.selectedCount} &nbsp; {this.props.selectedLabel}
        </span>
        {this.props.children}
      </div>
    );
  }
}

CardBrowserSelectedDisplay.defaultProps = {
  selectedCount: null,
  selectedLabel: "Selected"
};

CardBrowserSelectedDisplay.propTypes = {
  selectedCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deselectAllAction: PropTypes.func,
  selectedLabel: PropTypes.string
};
