import React from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";

export class LabelInfo extends React.Component {
  render() {
    const styles = {
      styles: {
        width: 30,
        height: 30,
        padding: 0,
        color: "ccc",
        top: 3
      }
      // TODO: Figure out how to apply this style
      // iconStyles: {
      //   width: 15,
      //   height: 15
      // }
    };

    return (
      <Tooltip title={this.props.info} placement="top-right">
        <IconButton style={styles.styles}>
          <Help />
        </IconButton>
      </Tooltip>
    );
  }
}
