import React from "react";
import PropTypes from "prop-types";

import _map from "lodash/map";

import { ExpandMore, ExpandLess } from "@material-ui/icons";

import ListItem from "../List/ListItem";
import { Icon } from "../Icon";
import "./Accordion.scss";

export class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion(event) {
    event.preventDefault();
    event.stopPropagation();
    let active = this.state.active;
    this.setState({ active: !active });
  }

  render() {
    return (
      <div className="cms-accordion">
        <div
          onClick={this.toggleAccordion}
          className={`col-sm-12 link accordion-link ${
            this.state.active ? "active" : ""
          }`}
        >
          <div className="col-sm-12 cms-list-item link">
            <div className="col-sm-8 left-item">
              {this.props.icon ? (
                <Icon
                  icon={this.props.icon}
                  width={20}
                  style={{ float: "left", marginRight: 10 }}
                />
              ) : null}
              <span className="list-item-text">{this.props.label} </span>
            </div>
            <div className="col-sm-4 right-item">
              {this.state.active ? <ExpandLess /> : <ExpandMore />}
            </div>
          </div>
        </div>
        <div
          className={`accordion-container ${this.state.active ? "active" : ""}`}
        >
          {this.props.children &&
            _map(this.props.children, child => (
              <ListItem
                {...child}
                key={child.param || child.label}
                item={child}
              />
            ))}
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

Accordion.defaultProps = {};

Accordion.propTypes = {
  param: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.object
};
