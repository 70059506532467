import React from "react";
import PropTypes from "prop-types";
import _isEqual from "lodash/isEqual";
import Draggable from "react-draggable";
import SwipeableViews from "react-swipeable-views";
import { SketchPicker } from "react-color";

import {
  Checkbox,
  Button,
  FormControlLabel,
  Tabs,
  Tab
} from "@material-ui/core";

import Logger from "utils/logger";
import { CardBrowser, List } from "components";
import "./BackgroundEditModal.scss";

export class BackgroundEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.backgroundData,
      slideIndex: 0,
      color: this.props.initialColor
        ? this.props.initialColor
        : this.props.defaultColor,
      nullBackground: this.props.initialColor ? false : true
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.updateNullBackground = this.updateNullBackground.bind(this);
  }

  handleTabChange(event, value) {
    this.setState({ slideIndex: value });
  }

  handleColorPickerChange(value) {
    this.setState({ color: value });
  }

  componentWillReceiveProps(nextProps) {
    const isNewScene = !_isEqual(nextProps.backgroundData, this.state.data);
    if (isNewScene) {
      this.setState({
        slideIndex: 0,
        data: nextProps.backgroundData,
        color: nextProps.initialColor
          ? nextProps.initialColor
          : nextProps.defaultColor
      });
    }

    this.setState({
      nullBackground: nextProps.initialColor ? false : true
    });
  }

  handleInputChange(property, value) {
    Logger.debug(
      { property, value },
      "[BACKGROUND EDIT MODAL] - handleInputChange"
    );
    let paramProperty;
    if (typeof property === "object" && property.target) {
      paramProperty = property.target.name;
    } else {
      paramProperty = property;
    }
    let currentData = this.state.data;
    if (paramProperty) {
      currentData[paramProperty] = value;
    }
    this.setState({ data: { ...currentData } });
  }

  selectHandler(id) {
    this.handleInputChange("themeBackground", id);
  }

  updateNullBackground() {
    this.setState(oldState => {
      return {
        nullBackground: !oldState.nullBackground
      };
    });
  }

  render() {
    const labelStyle = {
      fontSize: 13
    };

    let themeBackgroundForm = {
      themeBackground: {
        type: "select",
        label: "Theme Backgrounds",
        param: "themeBackground",
        order: 1,
        inlineLabel: true,
        options: [
          {
            label: "Background 1",
            value: 1,
            _id: 1
          },
          {
            label: "Background 2",
            value: 2,
            _id: 2
          },
          {
            label: "Background 3",
            value: 3,
            _id: 3
          },
          {
            label: "Background 4",
            value: 4,
            _id: 4
          }
        ]
      }
    };

    let backgroundOptionsForm = {
      objectFit: {
        type: "select",
        label: "Background Size",
        param: "objectFit",
        order: 1,
        inlineLabel: true,
        options: [
          {
            label: "Cover",
            value: "cover"
          },
          {
            label: "Contain",
            value: "contain"
          }
        ]
      },
      objectPosition: {
        type: "select",
        label: "Background Position",
        param: "objectPosition",
        order: 2,
        inlineLabel: true,
        options: [
          {
            label: "Left Top",
            value: "left top"
          },
          {
            label: "Left Center",
            value: "left center"
          },
          {
            label: "Left Bottom",
            value: "left bottom"
          },
          {
            label: "Right Top",
            value: "right top"
          },
          {
            label: "Right Center",
            value: "right center"
          },
          {
            label: "Right Bottom",
            value: "right bottom"
          },
          {
            label: "Center Top",
            value: "center top"
          },
          {
            label: "Center Center",
            value: "center center"
          },
          {
            label: "Center Bottom",
            value: "center bottom"
          }
        ]
      },
      backgroundFilter: {
        type: "select",
        label: "Filter (Beta)",
        param: "backgroundFilter",
        order: 3,
        inlineLabel: true,
        options: [
          {
            label: "Theme Default",
            value: "filter-default"
          },
          {
            label: "No Filter",
            value: "filter-none"
          },
          {
            label: "1977",
            value: "filter-_1977"
          },
          {
            label: "Aden",
            value: "filter-aden"
          },
          {
            label: "Brannan",
            value: "filter-brannan"
          },
          {
            label: "Brooklyn",
            value: "filter-brooklyn"
          },
          {
            label: "Clarendon",
            value: "filter-clarendon"
          },
          {
            label: "Earlybird",
            value: "filter-earlybird"
          },
          {
            label: "Gingham",
            value: "filter-gingham"
          },
          {
            label: "Hudson",
            value: "filter-hudson"
          },
          {
            label: "Inkwell",
            value: "filter-inkwell"
          },
          {
            label: "Kelvin",
            value: "filter-kelvin"
          },
          {
            label: "Lark",
            value: "filter-lark"
          },
          {
            label: "Lo-Fi",
            value: "filter-lofi"
          },
          {
            label: "Maven",
            value: "filter-maven"
          },
          {
            label: "Mayfair",
            value: "filter-mayfair"
          },
          {
            label: "Moon",
            value: "filter-moon"
          },
          {
            label: "Nashville",
            value: "filter-nashville"
          },
          {
            label: "Perpetua",
            value: "filter-perpetua"
          },
          {
            label: "Reyes",
            value: "filter-reyes"
          },
          {
            label: "Rise",
            value: "filter-rise"
          },
          {
            label: "Slumber",
            value: "filter-slumber"
          },
          {
            label: "Stinson",
            value: "filter-stinson"
          },
          {
            label: "Toaster",
            value: "filter-toaster"
          },
          {
            label: "Valencia",
            value: "filter-valencia"
          },
          {
            label: "Walden",
            value: "filter-walden"
          },
          {
            label: "Willow",
            value: "filter-willow"
          },
          {
            label: "X-pro II",
            value: "filter-xpro2"
          }
        ]
      },
      backgroundEffect: {
        type: "select",
        label: "Effect (Beta)",
        param: "backgroundEffect",
        order: 4,
        inlineLabel: true,
        options: [
          {
            label: "Theme Default",
            value: "effect-default"
          },
          {
            label: "No Effect",
            value: "effect-none"
          },
          {
            label: "Darken (10%)",
            value: "darken-10"
          },
          {
            label: "Darken (20%)",
            value: "darken-20"
          },
          {
            label: "Darken (30%)",
            value: "darken-30"
          },
          {
            label: "Darken (40%)",
            value: "darken-40"
          },
          {
            label: "Darken (50%)",
            value: "darken-50"
          },
          {
            label: "Darken (60%)",
            value: "darken-60"
          },
          {
            label: "Darken (70%)",
            value: "darken-70"
          },
          {
            label: "Darken (80%)",
            value: "darken-80"
          },
          {
            label: "Darken (90%)",
            value: "darken-90"
          },
          {
            label: "Darken (100%)",
            value: "darken-100"
          },
          {
            label: "Screen (10%)",
            value: "screen-10"
          },
          {
            label: "Screen (20%)",
            value: "screen-20"
          },
          {
            label: "Screen (30%)",
            value: "screen-30"
          },
          {
            label: "Screen (40%)",
            value: "screen-40"
          },
          {
            label: "Screen (50%)",
            value: "screen-50"
          },
          {
            label: "Screen (60%)",
            value: "screen-60"
          },
          {
            label: "Screen (70%)",
            value: "screen-70"
          },
          {
            label: "Screen (80%)",
            value: "screen-80"
          },
          {
            label: "Screen (90%)",
            value: "screen-90"
          },
          {
            label: "Screen (100%)",
            value: "screen-100"
          },
          {
            label: "Multiply (10%)",
            value: "multiply-10"
          },
          {
            label: "Multiply (20%)",
            value: "multiply-20"
          },
          {
            label: "Multiply (30%)",
            value: "multiply-30"
          },
          {
            label: "Multiply (40%)",
            value: "multiply-40"
          },
          {
            label: "Multiply (50%)",
            value: "multiply-50"
          },
          {
            label: "Multiply (60%)",
            value: "multiply-60"
          },
          {
            label: "Multiply (70%)",
            value: "multiply-70"
          },
          {
            label: "Multiply (80%)",
            value: "multiply-80"
          },
          {
            label: "Multiply (90%)",
            value: "multiply-90"
          },
          {
            label: "Multiply (100%)",
            value: "multiply-100"
          }
        ]
      },
      backgroundAnimation: {
        type: "select",
        label: "Animation (Beta)",
        param: "backgroundAnimation",
        order: 5,
        inlineLabel: true,
        options: [
          {
            label: "None",
            value: ""
          },
          {
            label: "Ken Burns",
            value: "kenBurns"
          },
          {
            label: "Ken Burns (Down)",
            value: "kenBurnsDown"
          },
          {
            label: "Ken Burns (Left)",
            value: "kenBurnsLeft"
          },
          {
            label: "Ken Burns (Right)",
            value: "kenBurnsRight"
          },
          {
            label: "Ken Burns (Up)",
            value: "kenBurnsUp"
          }
        ]
      },
      backgroundAnimationSpeed: {
        type: "select",
        label: "Animation Speed (Beta)",
        param: "backgroundAnimationSpeed",
        order: 6,
        inlineLabel: true,
        options: [
          {
            label: "Slower",
            value: "slower"
          },
          {
            label: "Slow",
            value: "slow"
          },
          {
            label: "Regular",
            value: ""
          },
          {
            label: "Fast",
            value: "fast"
          },
          {
            label: "Faster",
            value: "faster"
          }
        ]
      }
    };

    let backgroundOptionsFormFuture = {
      repeat: {
        type: "select",
        label: "Background Repeat",
        param: "repeat",
        order: 3,
        inlineLabel: true,
        options: [
          {
            label: "Repeat",
            value: "repeat"
          },
          {
            label: "No Repeat",
            value: "no repeat"
          }
        ]
      }
    };

    let assetForm = {
      asset: {
        type: "assetEditor",
        isRequired: true,
        label: "",
        param: "asset",
        inlineLabel: true,
        order: 1
      }
    };
    assetForm =
      this.state.data.assetID !== null
        ? { ...assetForm, ...backgroundOptionsForm }
        : assetForm;

    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)"
      }
    };

    const tabsStyle = {};

    const ThemeCard = ({ item }) => {
      const { label, value } = item;

      return (
        <div className={`thumbnail theme-bg-${value}`}>
          <div className="thumbnail-container">
            <div className="content-container-wrapper">
              <div className="content-container"></div>
            </div>
            <div className="background-container">
              <div className="background-color"></div>
              <div className="asset"></div>
              <div className="background-overlay-scrim"></div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        className={`background-modal-container ${
          this.props.open ? "open" : ""
        }`}
      >
        <Draggable handle=".handler">
          <div
            className={`scene-editor-modal`}
            style={{
              left: this.props.leftPosition,
              top: this.props.topPosition
            }}
          >
            <div className="modal-relative-container">
              <div className="header-container handler">
                <h2>{this.props.title}</h2>
              </div>
              <div className="form-container">
                <Tabs
                  {...tabsStyle}
                  onChange={this.handleTabChange}
                  value={this.state.slideIndex}
                >
                  <Tab {...tabStyle} label="THEME" value={0} />
                  <Tab {...tabStyle} label="COLOR" value={1} />
                  <Tab {...tabStyle} label="ASSET" value={2} />
                </Tabs>
                <SwipeableViews
                  index={this.state.slideIndex}
                  onChangeIndex={this.handleTabChange}
                  enableMouseEvents
                >
                  <div className="cms-list">
                    <div className="label-container">
                      <span>Theme Backgrounds</span>
                    </div>
                    <CardBrowser
                      className="theme-bg"
                      items={themeBackgroundForm.themeBackground.options}
                      CardContentsComponent={ThemeCard}
                      enableKeys={false}
                      // minCardSize={cardBrowserConfig.cardSizeMultiplier}
                      minCardSize={150}
                      // showButtons
                      setCardMargin={20}
                      // isSequentiable={!cardBrowserConfig.selectMode}
                      // executeOnReSort={setSceneOrder}
                      // selectMode={cardBrowserConfig.selectMode}
                      selected={[this.state.data.themeBackground]}
                      selectHandler={this.selectHandler}
                      // cardStyle={{
                      //   backgroundColor: "whitesmoke",
                      //   titleBackgroundColor: "whitesmoke",
                      //   titleColor: "black",
                      //   imageFit: "contain"
                      // }}
                      // editClickHandler={editClickHandler}
                      exclusiveSelectHandler={this.selectHandler}
                      // backClickHandler={backClickHandler}
                    />
                  </div>
                  <div>
                    <div className="null-color-container">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!this.state.nullBackground}
                            onChange={this.updateNullBackground}
                          />
                        }
                        label="Select a custom color for the background"
                      />
                    </div>
                    {this.state.nullBackground ? null : (
                      <SketchPicker
                        color={this.state.color}
                        width={300}
                        onChangeComplete={this.handleColorPickerChange}
                      />
                    )}
                  </div>
                  <div>
                    <List
                      styleName="plain"
                      data={this.state.data}
                      handleInputChange={this.handleInputChange}
                      handleDeleteClick={this.handleDeleteAsset}
                      handleAddClick={this.props.openAssetSelectorFunc}
                      handleEditClick={props => {
                        this.props.handleImageEditClick(props);
                      }}
                      items={assetForm}
                    />
                  </div>
                </SwipeableViews>
              </div>

              <div className="modal-actions-container">
                <Button onClick={this.props.closeAction}>
                  {this.props.revertLabel}
                </Button>
                <Button onClick={() => this.handleSave()} color="primary">
                  {this.props.saveLabel}
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
        <div className="modal-overlay" onClick={this.handleCloseAction} />
      </div>
    );
  }

  handleDeleteAsset() {
    this.props.handleSave({
      backgroundColor: this.state.nullBackground ? null : this.state.color,
      background: { ...this.state.data, assetID: null }
    });
  }

  handleSave() {
    this.props.handleSave({
      backgroundColor: this.state.nullBackground ? null : this.state.color,
      background: this.state.data
    });
  }
}
BackgroundEditModal.defaultProps = {
  open: false,
  leftPosition: "40%",
  topPosition: "15%",
  revertLabel: "Cancel",
  saveLabel: "Save",
  title: "Edit Background",
  defaultColor: "rgb(0,0,0,100)",
  backgroundData: {}
};

BackgroundEditModal.propTypes = {
  open: PropTypes.bool,
  closeAction: PropTypes.func,
  initialColor: PropTypes.string,
  backgroundData: PropTypes.object,
  leftPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSave: PropTypes.func,
  saveLabel: PropTypes.string,
  revertLabel: PropTypes.string,
  openAssetSelectorFunc: PropTypes.func
};

export default BackgroundEditModal;
