import React from "react";
import PropTypes from "prop-types";

import { Checkbox as MUICheckbox } from "@material-ui/core";

import "./Checkbox.scss";

export class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: !!props.valueFromInput };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const newValue = event.target.checked;
    this.setState({ checked: newValue });
    if (typeof this.props.onChange !== "undefined") {
      this.props.onChange(this.props.dataProperty, newValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps - state',this.props.name, this.props.defaultValue, ' - nextProps', nextProps.defaultValue);
    if (this.state.checked !== nextProps.valueFromInput) {
      // console.log('change happening');
      this.setState({
        checked: !!nextProps.valueFromInput
      });
    }
  }

  render() {
    return (
      <MUICheckbox
        className={`cms-checkbox ${this.props.dataProperty}`}
        id={this.props.id}
        name={this.props.dataProperty}
        checked={this.state.checked}
        labelPosition={this.props.labelPosition}
        label={this.props.label}
        onCheck={this.handleChange}
      ></MUICheckbox>
    );
  }
}

Checkbox.defaultProps = {
  labelPosition: "right"
};

Checkbox.propTypes = {
  labelPosition: PropTypes.oneOf(["left", "right"]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valueFromInput: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  dataProperty: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};
