import React from "react";
import PropTypes from "prop-types";

import { Snackbar } from "@material-ui/core";

import "./Notification.scss";

/**
 * Notification Center
 *
 * @type  Component
 * @description  Notification component is used to show user feedback from their actions
 * @props {Boolean} open
 * @props {String | Component} message
 * @props {Function} onClose
 * @props {Number} displayDuration
 *
 */
export class Notification extends React.Component {
  // state = {
  //     open: (this.props && this.props.open) || false,
  //     message: (this.props && this.props.message) || ""
  // };

  render() {
    //console.log('[NOTIFICATION] PROPS-', this.props);
    return (
      <Snackbar
        className="Notification-container"
        open={this.props.open}
        message={this.props.message}
        autoHideDuration={this.props.displayDuration}
        onRequestClose={this.props.handleClose}
      />
    );
  }
}

Notification.defaultProps = {
  open: false,
  displayDuration: 3000
};

Notification.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  displayDuration: PropTypes.number,
  handleClose: PropTypes.func
};
