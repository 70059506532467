import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { openSearchWidget } from "common/j-story";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { Button } from "@material-ui/core";

import Logger from "utils/logger";
import { Dialog } from "components";
import ContentContainer from "modules/ContentContainer";
import AssetViewer from "modules/Library/AssetViewer";
import LibrarySidebar from "./LibrarySidebar";
import * as libraryActions from "actions/library";
import { getLibraryMainContainerProps } from "reducers/ui/library";
import ImageCropper from "modules/Library/ImageCropper";
import AssetSelector from "./AssetSelector";
import "./Library.scss";
import Auth from "common/Auth";
import styles from "./Library.scss";
import config from "config";

const Library = ({
  account,
  asset,
  assetNext,
  assetPrev,
  backClickHandler,
  cardViewerMaxCardSize,
  copyAsset,
  createAssetRequest,
  cropAsset,
  cropData,
  deleteMultiple,
  editClickHandler,
  error,
  exclusiveSelectHandler,
  isAddNewAsset,
  isAssetLoading,
  isLoading,
  isSaved,
  librarySideBarMode,
  onSortByClick,
  onSortByOrderClick,
  revertAsset,
  selectHandler,
  setLibraryAssetIsLoading,
  setLibraryIsLoading,
  setUIError,
  shiftSelectHandler,
  sidebarMode,
  updateAsset,
  uploadPreset = "vibeotib"
}) => {
  const history = useHistory();
  const routeMatch = useRouteMatch("/library/view/:assetId");
  const location = useLocation();

  const cloudName = config("cloudName");

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [imageCropperOpen, setImageCropperOpen] = useState(false);

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const proceedToDelete = () => {
    setDeleteModalOpen(false);
    setLibraryIsLoading(true);
    deleteMultiple();
  };

  const processCropAction = cropData => {
    const x = Math.round((cropData.x * asset.width) / 100);
    const y = Math.round((cropData.y * asset.height) / 100);
    const newWidth = Math.round((cropData.width * asset.width) / 100);
    const newHeight = Math.round((cropData.height * asset.height) / 100);
    cropAsset({
      config: {
        cropCoordinates: {
          x: x,
          y: y,
          width: newWidth,
          height: newHeight
        }
      }
    });
  };

  const getPercentageCropData = cropData => {
    const x = (cropData.x * 100) / asset.width;
    const y = (cropData.y * 100) / asset.height;
    const newWidth = (cropData.width * 100) / asset.width;
    const newHeight = (cropData.height * 100) / asset.height;
    return { x: x, y: y, width: newWidth, height: newHeight };
  };

  const openImageCropper = () => {
    if (Auth.isAuthenticated()) {
      setImageCropperOpen(true);
    } else {
      setUIError("Session Expired");
    }
  };

  const closeImageCropper = () => {
    setImageCropperOpen(false);
  };

  const openUploadWidget = () => {
    if (Auth.isAuthenticated()) {
      if (typeof cloudinary !== "undefined") {
        // @ TODO: Decide whether or not we need to set a folder with the account ID
        // account ID can be accessed with this.props.account
        cloudinary.openUploadWidget(
          {
            // eslint-disable-line
            folder: account,
            uploadPreset,
            showPoweredBy: false,
            sources: [
              "local",
              "url",
              "camera",
              "dropbox",
              "image_search",
              "google_photos",
              "facebook"
            ],
            cloudName
            // stylesheet: `https://storycrafter.co/cloudinary-widget-external.css?noCache=${Date.now()}`
          },
          (error, result) => {
            if (!error && result) {
              if (result.event === "queues-end") {
                createAssetRequest(result.info.files, account);

                onSortByClick("modifiedDate");
                onSortByOrderClick("desc");
              }
            }
          }
        );
      }
    } else {
      setUIError("Session Expired");
    }
  };

  const openJHNUploadWidget = () => {
    if (Auth.isAuthenticated()) {
      openSearchWidget(
        {
          cloud_name: config("cloudName"), // "dnva5y44r",
          folder: account,
          upload_preset: "vibeotib" // "lhiqbrlx"
        },
        (result, error) => {
          if (typeof result !== "undefined" && result.length > 0) {
            Logger.debug({ result }, "[LIBRARY] openJHNUploadWidget");
            // Save through API - Add to Data Library Items
            // @TODO pass an array of items, even if there is only on
            createAssetRequest(result, account);
            onSortByClick("modifiedDate");
            onSortByOrderClick("desc");
          }
        }
      );
    } else {
      setUIError("Session Expired");
    }
  };

  const scrollToTop = () => {
    document.getElementById("cardBrowser").scrollTop = 0;
    window.scrollTop = 0;
  };

  const assetId =
    routeMatch &&
    routeMatch.params &&
    typeof routeMatch.params.assetId !== "undefined"
      ? routeMatch.params.assetId
      : false;
  const backRoute = assetId
    ? location.pathname.replace("/view/" + assetId, "")
    : isAddNewAsset
    ? "/library"
    : "/";
  const pathname = typeof location.pathname ? location.pathname : false;

  let assetEditorModalContentContainer = null;

  let activeSlideAssetCloudId =
    asset && asset.cloudPublicId
      ? "//res.cloudinary.com/dgpzfuyzy/image/upload/c_crop,f_auto,q_auto/v1/" +
        asset.cloudPublicId
      : null;

  let assetDownloadURL = "https://res.cloudinary.com/dgpzfuyzy";
  if (asset) {
    const fileExtRegEx = /\.[^/.]+$/;
    let matches = asset.filename.match(fileExtRegEx);
    let fileExt = matches ? matches[0] : ".jpg";

    switch (asset.type) {
      case "image":
        assetDownloadURL += "/image/upload/";
        assetDownloadURL += fileExt === ".svg" ? "fl_sanitize/" : "";
        assetDownloadURL += asset.cloudPublicId + fileExt;
        break;

      // Under development. Started by copying logic for handling video.
      case "pdf":
        assetDownloadURL += "/image/upload/" + asset.cloudPublicId + fileExt;
        break;

      case "video":
      case "cloudinaryVideo":
        fileExt = ".mp4";
        assetDownloadURL += "/video/upload/v1/" + asset.cloudPublicId + fileExt;
        break;
    }
  }

  const topBarAssetEditor = (
    <div className="asset-editor-topbar">
      {asset && asset.type === "image" ? (
        <Button variant="contained" onClick={openImageCropper} color="primary">
          CROP
        </Button>
      ) : null}
    </div>
  );

  assetEditorModalContentContainer = (
    <ContentContainer
      addClassName="asset-viewer"
      isLoading={isLoading}
      error={error}
      sidebarMode={sidebarMode}
      topBar={topBarAssetEditor}
    >
      <AssetViewer
        asset={asset}
        isSaved={isSaved}
        cardViewerMaxCardSize={cardViewerMaxCardSize}
        prev={assetPrev}
        revertAssetAction={revertAsset}
        setLibraryIsLoading={setLibraryAssetIsLoading}
        copyAssetFunction={copyAsset}
        resetScrollAction={scrollToTop}
        saveFrameAsPosterFunction={time => {
          updateAsset({
            ...asset,
            config: {
              posterFrame: time
            }
          });
        }}
        isLoading={isAssetLoading}
        next={assetNext}
        history={history}
      />
    </ContentContainer>
  );

  let assetSelectorContentContainer = (
    <AssetSelector
      title="Assets"
      isLoading={isLoading}
      selectBarButtonLabel="DELETE"
      standardBarButtonLabel="UPLOAD"
      minWidthCaption={parseInt(styles.minWidthCaption, 10)}
      minCardMargin={parseInt(styles.minCardMargin, 10)}
      captionHeight={parseInt(styles.captionHeight, 10)}
      setCardMargin={20}
      error={error}
      isAssetSelectorMode={false}
      isModal={false}
      sidebarMode={librarySideBarMode}
      showButtons={true}
      enableKeys={true}
      selectBarDeselectButtonAction={exclusiveSelectHandler}
      selectBarButtonAction={e => {
        e.stopPropagation();
        openDeleteModal();
      }}
      standardBarButtonAction={e => openUploadWidget()}
      onJHNButtonClick={e => openJHNUploadWidget()}
      selectHandlerAction={selectHandler}
      exclusiveSelectHandlerAction={exclusiveSelectHandler}
      backClickHandlerAction={backClickHandler}
      shiftSelectHandlerAction={shiftSelectHandler}
      editClickHandlerAction={editClickHandler}
    />
  );

  const percCropData = cropData ? getPercentageCropData(cropData) : null;

  Logger.debug("[Library] assetId=", assetId);

  return (
    <div className="cms-screen library">
      <Helmet>
        <title>Assets</title>
      </Helmet>
      {assetId ? (
        <LibrarySidebar
          dataNode="library"
          assetDownloadURL={assetDownloadURL}
          assetId={assetId}
          history={history}
          backRoute={backRoute}
          pathname={pathname}
          isAddNewAsset={isAddNewAsset}
        />
      ) : isAddNewAsset ? (
        <LibrarySidebar
          dataNode="library"
          history={history}
          backRoute={backRoute}
          pathname={pathname}
          isAddNewAsset={isAddNewAsset}
        />
      ) : null}

      {asset ? assetEditorModalContentContainer : null}
      {assetSelectorContentContainer}
      <Dialog
        content="Once deleted, it cannot be restored"
        onConfirm={closeDeleteModal}
        confirmLabel="Cancel"
        onCancel={proceedToDelete}
        cancelLabel="Delete"
        open={deleteModalOpen}
        title="Delete this asset?"
      />
      {asset && asset.type === "image" ? (
        <ImageCropper
          open={imageCropperOpen}
          closeAction={closeImageCropper}
          processCropAction={processCropAction}
          imageScr={activeSlideAssetCloudId + ".jpg"}
          data={percCropData}
        />
      ) : null}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return getLibraryMainContainerProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    revertCopy,
    assetId,
    asset,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;

  Logger.debug("[Library: mergeProps: assetId=", assetId);

  return Object.assign({}, stateProps, {
    removeAssetCopy: () => dispatchProps.removeAssetCopy(),
    resetAssetEditor: () => dispatchProps.resetAssetEditor(),
    cropAsset: configData => dispatchProps.updateAsset(assetId, configData),
    revertAsset: () => dispatchProps.revertAsset(assetId, revertCopy),
    copyAsset: () => dispatchProps.copyAsset(asset),
    requestLibrary: () => {
      return dispatchProps.requestLibrary(account);
    },
    updateAsset: assetData => dispatchProps.updateAsset(assetId, assetData),
    selectHandler: id => {
      Logger.debug("[Library] - selectHandler");
      dispatchProps.selectToggle(id);
    },
    exclusiveSelectHandler: id => {
      Logger.debug("[Library] - exclusiveSelectHandler");
      dispatchProps.exclusiveSelectToggle(id);
    },
    shiftSelectHandler: (id, items) =>
      dispatchProps.shiftSelectToggle(id, items),
    backClickHandler: id => dispatchProps.exclusiveSelectToggle(id),
    editClickHandler: path => {
      Logger.debug("[CARD BROWSER - Library] - editClickHandler: path=", path);
      // Sets path to /library/view/{assetId}
      ownProps.history.push(path);
    },
    deleteMultiple: () => dispatchProps.deleteMultiple(stateProps.selected),
    setLibraryIsLoading: value => dispatchProps.setLibraryIsLoading(value),
    setLibraryAssetIsLoading: value => {
      dispatchProps.setLibraryAssetIsLoading(value);
    },
    createAssetRequest: data => dispatchProps.createAssetRequest(data, account),
    onSortByClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByType",
        value
      ),
    onSortByOrderClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByOrder",
        value
      ),
    setUIError: message => dispatchProps.setUIError(message)
  });
}

export default connect(mapStateToProps, libraryActions, mergeProps)(Library);
