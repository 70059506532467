import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { MenuItem, Select } from "@material-ui/core";

import previewSelectorMapStateToProps from "mapToProps/previewSelector";
import * as actions from "actions/userPreferences";

class PreviewSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, index, value) {
    this.props.handleChange(value);
  }

  render() {
    // Mount-Point Props
    const { value } = this.props;

    // Map State Props
    const { previewOptions, defaultPreview } = this.props;

    return (
      <Select
        className="header-select preview-size"
        value={value || defaultPreview}
        onChange={this.handleChange}
      >
        {previewOptions &&
          previewOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return previewSelectorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { userPreferencesSelector } = ownProps;

  return Object.assign({}, stateProps, {
    handleChange: value =>
      dispatchProps.dispatchUserPreferenceChange(
        userPreferencesSelector,
        "preview",
        value
      )
  });
}

export default withRouter(
  connect(mapStateToProps, actions, mergeProps)(PreviewSelector)
);
