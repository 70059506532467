import React from "react";
import Draggable from "react-draggable";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _debounce from "lodash/debounce";

import { Button, MenuItem, Select } from "@material-ui/core";

import { List } from "components";
import "./SceneElementEditor.scss";

export class SceneElementEditor extends React.Component {
  /*
  The SceneElementEditor's constructor will be executed every time
  the SceneEditor component is mounted. The constructor is not
  executed when the user advances to another scene without closing
  the Scene Editor. In that case, the componentWillReceiveProps()
  lifecycle method is called to update the modal form contents.
  */
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      formData: _cloneDeep(this.props.formData) || {},
      activeLanguage: this.props.langID
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.debounceInputChange = _debounce(function (property, value) {
      this.handleInputChange.apply(this, [property, value]);
    }, 500);
  }

  componentDidMount() {
    this.formContainerRef.scrollTo(0, 0);
  }

  /*
  The componentWillReceiveProps() lifecycle method will be 
  called every time the SceneElementEditor is opened to update the
  modal form contents.
  */
  componentWillReceiveProps(nextProps) {
    this.setState({
      isDirty: nextProps.isDirty,
      formData: nextProps.formData ? _cloneDeep(nextProps.formData) : {}
    });
  }

  componentDidUpdate(prevProps) {
    // The prevProps formData prop is undefined when the user switches from
    // one asset to another.
    if (!prevProps.formData) {
      this.formContainerRef.scrollTo(0, 0);
    }
  }

  handleInputChange(property, value) {
    /*
    The forms are bound to state.formData. Therefore, we have to keep
    state.formData up to date with changes made by the user in the forms.
    Otherwise, the user's changes will be overwritten.
    */
    const formData = _cloneDeep(this.state.formData);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    */
    if (formData[property] === "" && value === "<p><br></p>") {
      return;
    }

    /*
    If the value of the altered form input doesn't equal the previous
    form input value, update state.data and trigger an update to the 
    store. Note that select list values are of type 'object'
    */
    if (typeof value === "object") {
      formData[property] = { ...formData[property], ...value };
      //this.props.storySetIsSaved(false);
      this.setState({
        isDirty: true,
        formData
      });
      this.props.handleInputChange(property, value);
    } else if (formData[property] !== value) {
      formData[property] = value;
      //this.props.storySetIsSaved(false);
      this.setState({
        isDirty: true,
        formData
      });
      this.props.handleInputChange(property, value);
    }

    // Old code:
    // let currentData = this.state.data;
    // currentData[property] = value;
    // this.setState({data: {...currentData}});
    // this.props.handleInputChange(property, value);
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.handleLanguageChange(value);
  }

  render() {
    return (
      <div
        className={`scene-editor-modal-container ${
          this.props.open ? "open" : ""
        } ${this.state.formData.formType !== "text" && "asset-editor"}`}
      >
        <Draggable handle=".handler">
          <div
            className={`scene-editor-modal`}
            style={{
              left: this.props.leftPosition,
              top: this.props.topPosition
            }}
          >
            <div
              className="modal-relative-container"
              id="modal-relative-container"
            >
              <div className="header-container handler">
                <h2>{this.props.title}</h2>
              </div>
              <div
                className="form-container"
                ref={el => (this.formContainerRef = el)}
              >
                {this.state.formData.formType === "text" && (
                  <Select
                    className="language-select"
                    value={this.state.activeLanguage}
                    onChange={this.handleLanguageChange}
                  >
                    {this.props.languages &&
                      _map(this.props.languages, item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                )}

                {this.props.form ? (
                  this.state.formData.formType === "text" ? (
                    <List
                      styleName="plain"
                      items={this.props.form}
                      data={this.state.formData}
                      handleEditClick={this.props.openImageCropperFunc}
                      handleInputChange={(property, value) =>
                        this.debounceInputChange(property, value)
                      }
                    />
                  ) : (
                    <div className="columns-container">
                      <div className="column-asset">
                        <List
                          styleName="plain"
                          items={{
                            ..._filter(
                              this.props.form,
                              item =>
                                item.param === "assetData" ||
                                item.param === "assetFit" ||
                                item.param === "focalPoint"
                            )
                          }}
                          data={this.state.formData}
                          handleEditClick={this.props.openImageCropperFunc}
                          handleInputChange={(property, value) =>
                            this.debounceInputChange(property, value)
                          }
                        />
                      </div>
                      <div className="column-details">
                        <Select
                          className="language-select"
                          value={this.state.activeLanguage}
                          onChange={this.handleLanguageChange}
                        >
                          {this.props.languages &&
                            _map(this.props.languages, item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Select>
                        <List
                          styleName="plain"
                          items={{
                            ..._filter(
                              this.props.form,
                              item =>
                                item.param !== "assetData" &&
                                item.param !== "assetFit" &&
                                item.param !== "focalPoint"
                            )
                          }}
                          data={this.state.formData}
                          handleEditClick={this.props.openImageCropperFunc}
                          handleInputChange={(property, value) =>
                            this.debounceInputChange(property, value)
                          }
                        />
                      </div>
                    </div>
                  )
                ) : null}
              </div>
              <div className="modal-actions-container">
                <Button onClick={() => this.props.closeAction(true)}>
                  {this.props.revertLabel}
                </Button>
                <Button
                  disabled={!this.state.isDirty}
                  onClick={() => this.props.handleSave(this.state.formData)}
                  color="primary"
                >
                  {this.props.saveLabel}
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
        <div className="modal-overlay" onClick={this.handleCloseAction} />
      </div>
    );
  }
}
SceneElementEditor.defaultProps = {
  open: false,
  leftPosition: "20%",
  topPosition: "20%",
  revertLabel: "Cancel",
  saveLabel: "Save"
};

SceneElementEditor.propTypes = {
  open: PropTypes.bool,
  closeAction: PropTypes.func,
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  viewFeedbackAction: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  form: PropTypes.object,
  title: PropTypes.string,
  formData: PropTypes.object,
  leftPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSave: PropTypes.func,
  type: PropTypes.string, // Scene Type
  openImageCropperFunc: PropTypes.func,
  saveLabel: PropTypes.string,
  revertLabel: PropTypes.string
};

export default SceneElementEditor;
