import React from "react";
import PropTypes from "prop-types";

import { CircularProgress } from "@material-ui/core";

import Logger from "utils/logger";
import "./ContentContainer.scss";
import HeaderContainer from "modules/ContentContainer/HeaderContainer";
import styles from "./ContentContainer.scss";

class ContentContainer extends React.Component {
  render() {
    let sidebarsWidth;
    switch (this.props.sidebarMode) {
      case "none":
        sidebarsWidth = 0;
        break;
      case "small":
        sidebarsWidth = styles.sidebarCollapsedWidth;
        break;
      case "large":
        sidebarsWidth = styles.sidebarExpandedWidth;
        break;
      case "smallExtra":
        sidebarsWidth = styles.auxSidebarPlusSidebarCollapsedWidth;
        break;
      case "smallMediumExtra":
        Logger.debug("[CONTENT CONTAINER] - render : smallMediumExtra");
        sidebarsWidth = styles.mediumSidebarPlusSidebarCollapsedWidth;
        break;
      case "largeMediumExtra":
        Logger.debug("[CONTENT CONTAINER] - render : largeMediumExtra");
        sidebarsWidth = styles.mediumSidebarPlusSidebarExpandedWidth;
        break;
      case "largeExtra":
      default:
        sidebarsWidth = styles.auxSidebarPlusSidebarExpandedWidth;
        break;
    }

    const headHeight =
      this.props.headerMode === "simple"
        ? styles.headerHeight
        : this.props.headerMode === "extended-extra"
        ? 120
        : styles.headerExtendedHeight;

    const barsHeight =
      this.props.topBar && this.props.bottomBar
        ? headHeight + styles.footerHeight
        : this.props.topBar
        ? headHeight
        : this.props.bottomBar
        ? styles.footerHeight
        : 0;

    const contentContainerstyle = {
      width: `calc(100% - ${sidebarsWidth})`,
      backgroundColor: this.props.backgroundColor,
      overflow: this.props.overflow
    };

    const containerBodyStyle = {
      height:
        barsHeight === 0
          ? "100%"
          : `calc(100% - ${
              !isNaN(barsHeight) ? `${barsHeight}px` : barsHeight
            })`,
      bottom: this.props.bottomBar ? styles.footerHeight : 0
    };

    // @TODO allow to read the class so we know how to account for the size of the sidebar
    return (
      // @TODO allow for passing width and loading... may not need all these classes will refine
      // TODO: check to see if when not loading IF there was an error...
      <div
        className={`content-container ${
          this.props.addClassName ? this.props.addClassName : ""
        }`}
        style={contentContainerstyle}
      >
        {this.props.isLoading ? (
          <div className="relative-content-container">
            <div className="progress-container">
              <CircularProgress
                className="progress-indicator"
                size={80}
                thickness={10}
              />
            </div>
          </div>
        ) : null}
        <div className="relative-content-container">
          {this.props.topBar ? (
            <HeaderContainer
              alignRight={!this.props.selectMode}
              selectMode={this.props.selectMode}
              title={this.props.title}
              history={this.props.history}
              confirmNavigation={this.props.confirmNavigation}
              goBackAction={this.props.goBackAction}
              closeAction={this.props.closeAction}
              handleCloseClicked={this.props.handleCloseClicked}
              theme={this.props.headerTheme}
              actions={this.props.headerActions}
              mode={this.props.headerMode}
              tabs={this.props.headerTabs}
            >
              {this.props.topBar}
            </HeaderContainer>
          ) : null}
          <div className="container-body" style={containerBodyStyle}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

ContentContainer.defaultProps = {
  addClassName: "",
  isLoading: false,
  error: "",
  sidebarMode: "small",
  backgroundColor: styles.backgroundDefault,
  overflow: "hidden",
  topBar: null,
  bottomBar: null,
  headerTheme: "dark",
  confirmNavigation: false,
  headerMode: "simple"
};

ContentContainer.propTypes = {
  addClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  confirmNavigation: PropTypes.bool,
  history: PropTypes.object,
  error: PropTypes.string, // if there was an issue loading data we want to pass it fdown for display
  sidebarMode: PropTypes.string,
  backgroundColor: PropTypes.string,
  overflow: PropTypes.string,
  topBar: PropTypes.element,
  bottomBar: PropTypes.element,
  selectMode: PropTypes.bool,
  title: PropTypes.string,
  headerTheme: PropTypes.string,
  goBackAction: PropTypes.string,
  headerMode: PropTypes.string,
  headerTabs: PropTypes.element,
  headerActions: PropTypes.element,
  closeAction: PropTypes.string
};

export default ContentContainer;
