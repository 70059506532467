import React from "react";

import { Switch as MUISwitch } from "@material-ui/core";

import "./Switch.scss";

export class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: props.toggled
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps - state',this.props.name, this.props.defaultValue, ' - nextProps', nextProps.defaultValue);
    if (this.props.toggled !== nextProps.toggled) {
      // console.log('change happening');
      this.setState({
        toggled: nextProps.toggled
      });
    }
  }

  handleChange(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ toggled: !this.state.toggled });
    if (typeof this.props.onChange !== "undefined") {
      this.props.onChange(this.props.name, !this.state.toggled);
    }
  }

  render() {
    return (
      <MUISwitch
        className="cms-toggle"
        labelStyle={this.props.labelStyle}
        toggled={this.state.toggled}
        label={this.props.label}
        onToggle={this.handleChange}
      ></MUISwitch>
    );
  }
}
