import React, { Component } from "react";
import cloudinary from "cloudinary-core";
import PropTypes from "prop-types";

import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";

import Loader from "../Loader";

/*
 * The image tag can be pass props and it will determine if it need to use
 * cloudinary or just a image path
 * @props
 * - src (optional, only need if not using cloudinary)
 * - cloudPublicId
 * - cloudName
 * - cloudTintOpacity
 * - cloudTintColor
 * - imageStyle
 * - className -- we are puttin it on the wrapper, and we can target the img tag inside...
 */
export class ImageTag extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoaded: false, loadError: "" }; // used as "in" for transitions
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.handleImageLoadError = this.handleImageLoadError.bind(this);
  }

  handleImageLoad(e, src) {
    this.setState({ isLoaded: true, src: src });
  }

  handleImageLoadError(e) {
    let { message } = e || "Error Loading";
    this.setState({ loadError: message });
  }

  render() {
    const {
      className, // used to add a custom class to img tag
      cloudTintOpacity, // ?? should't these props be inside assetConfig?
      cloudTintColor, // ?? should't these props be inside assetConfig?
      assetConfig, // special configuration props used by cloudinary
      cloudPublicId, // id Cloudinary uses to find asset and return it to us
      cloudName, // our acount name, also needs to be passed to Cloudinary
      imageStyle, // style set on img tag - used to override theme styles for a unique use by putting inline
      page, // used to determine pdf page for thumbnail
      format, // used for pdf and video to determine file format for thumb/poster
      quality, // defaults to auto unless a pdf
      crop, // related to pdf thumbnail
      startOffset, // poster frame to use
      width, // Value to assign to img tag width attribute. Auto by default.
      height, // Value to assign to img tag height attribute. Auto by default.
      heightLimit, // allows us to pass the largest height of image to be served -- note setting a limit of 1800 in trnasformation below
      widthLimit, //  allows us to pass the largest width of image to be served-- note setting a limit of 1800 in trnasformation below
      type,
      metaConfig,
      onClick //give the image ability to be clicked
    } = this.props;
    let { src } = this.props || this.state; // this is a backup if we are not using cloudinary
    const altText = metaConfig ? metaConfig.altText : "";
    const { isLoaded, loadError } = this.state;
    const effect =
      cloudTintOpacity && cloudTintColor
        ? `colorize:${cloudTintOpacity},co_rgb:${cloudTintColor}`
        : null;
    const hasCropConfig =
      assetConfig && typeof assetConfig.cropCoordinates !== "undefined";
    const cropCoordinates = hasCropConfig ? assetConfig.cropCoordinates : {};
    const imgClass = `${className || "image-container"}`;
    let publicId = cloudPublicId; // this may need to be modified for some formats
    let fileFormat = type === "video" || type === "pdf" ? "jpg" : format; // Ensure other formats will revert to jpg

    if (this.props && (cloudPublicId || src)) {
      // let contents;
      // if (isPoster && cloudPublicId) {
      //   // looad like we can't fade in the poster, or else looking for wrong envet
      //   //  data-fade={true}
      //   contents = (
      //     <Video
      //       ref={c => {
      //         this.element = c ? c.element : null;
      //       }}
      //       cloudName={cloudName}
      //       preload={"none"}
      //       poster={src}
      //       onLoad={this.onImageLoad}
      //       onError={this.onImageLoadError}
      //     />
      //   );
      // } else if (cloudPublicId) {
      //   contents = (
      //     <Image
      //       ref={c => {
      //         this.element = c ? c.element : null;
      //       }}
      //       cloudName={cloudName}
      //       publicId={cloudPublicId}
      //       width={context === "scene-browser" ? 300 : 1800}
      //       style={imageStyle}
      //       onLoad={this.onImageLoad}
      //       onError={this.onImageLoadError}
      //       data-fade={true}
      //       alt={altText}
      //     >
      //       <Transformation
      //         effect={effect}
      //         {...cropCoordinates}
      //         crop={hasCropConfig ? "crop" : "scale"}
      //       />
      //     </Image>
      //   );
      // } else {
      //   contents = (
      //     <img
      //       ref={el => {
      //         this.element = el;
      //       }}
      //       style={imageStyle}
      //       src={src}
      //       width={1800}
      //       style={imageStyle}
      //       onLoad={this.onImageLoad}
      //       onError={this.onImageLoadError}
      //       data-fade={true}
      //       alt={altText}
      //     />
      //   );
      // }

      const extension = /(?:\.([^.]+))?$/;

      if ((type === "video" || type === "pdf") && cloudPublicId) {
        /*
        Both PDF and videos have to add an extension to the cloudPublicId to 
        insure an image is returned. They still use the same tag, but pass a
        few extra params. Seems there are some cases where a native extension
        is added so we remove here.
        */
        publicId = `${cloudPublicId}${
          typeof extension.exec(cloudPublicId)[1] !== "undefined" ? "" : ".jpg"
        }`;
      }

      // Set up an object so we can do some conditional attribute adds
      let imageProps = {
        style: imageStyle,
        width: width || "auto",
        height: height || "auto"
      };

      if (altText) {
        imageProps.alt = altText;
      }

      let loaderProps = {};

      if (!src) {
        const cloudinaryCore = new cloudinary.Cloudinary({
          cloud_name: cloudName
        });
        // create object for transformations
        const transformationObj = {
          format: fileFormat,
          fetch_format: "auto",
          resource_type: type === "video" ? type : "image"
        };
        const transformations = []; // array of transformations to apply in order

        // add page information for pdf thumb
        if (type === "pdf") {
          transformations.push({
            page: type === "pdf" ? page : "",
            startOffset: startOffset
          });
        }
        if (type === "video") {
          transformations.push({ startOffset: startOffset });
        }

        // add crop information if present
        if (cropCoordinates && !_isUndefined(cropCoordinates)) {
          transformations.push({
            crop: hasCropConfig ? "crop" : type === "pdf" ? crop : "fit",
            ...cropCoordinates
          });
        }

        // make sure things fit if limits were passed
        transformations.push({
          width: widthLimit || "1800",
          height: heightLimit || "1800",
          crop: "limit"
        });

        if (effect) {
          transformations.push({ effect });
        }

        transformations.push({
          client_hints: true,
          dpr: "auto",
          quality: type === "pdf" ? quality : "auto"
        });

        // TODO pass information about transformations to url generation
        transformationObj.transformation = transformations;
        src = cloudinaryCore.url(cloudPublicId, transformationObj);
      }

      imageProps.src = src;

      return (
        <figure className={imgClass} onClick={onClick}>
          {this.props.overlay ? this.props.overlay : null}
          <img
            {...imageProps}
            onLoad={e => this.handleImageLoad(e, src)}
            onError={e => this.handleImageLoadError(e)}
            // data-fade={!isLoaded}
          />
          {this.props.children}
          {!isLoaded ? (
            <Loader
              {...loaderProps}
              type={"asset-loader"}
              message={loadError}
              size={20}
              thickness={5}
            />
          ) : null}
        </figure>
      );
    } else {
      return null;
    }
  }
}

ImageTag.defaultProps = {};

ImageTag.propTypes = {
  src: PropTypes.string,
  cloudPublicId: PropTypes.string,
  cloudName: PropTypes.string,
  cloudTintOpacity: PropTypes.string,
  cloudTintColor: PropTypes.string,
  config: PropTypes.object,
  imageStyle: PropTypes.object,
  className: PropTypes.string,
  assetConfig: PropTypes.object,
  descriptionStyle: PropTypes.object
};
