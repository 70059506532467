import React, { useEffect, useState } from "react";
import _map from "lodash/map";

import { IconButton, Tooltip } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

import { PopupMenu } from "../PopupMenu";

export const CardBrowserFilter = ({
  sortByType: initialSortByType,
  sortByTypeOptions,
  sortByOrder: initialSortByOrder,
  onSortByClick,
  onSortByOrderClick,
  iconColor = "rgba(255, 255, 255, 0.7)",
  labelBackHover = "rgba(255, 255, 255, 0.10)"
}) => {
  const [sortByType, setSortByType] = useState(initialSortByType);
  const [sortByOrder, setSortByOrder] = useState(initialSortByOrder);
  const [iconBack, setIconBack] = useState("transparent");

  useEffect(() => {
    setSortByType(initialSortByType);
  }, [initialSortByType]);

  useEffect(() => {
    setSortByOrder(initialSortByOrder);
  }, [initialSortByOrder]);

  const handleSortByTypeChange = value => {
    setSortByType(value);
    if (onSortByClick) {
      onSortByClick(value);
    }
  };

  const handleIconHover = () => {
    setIconBack(labelBackHover);
  };

  const handleIconOut = () => {
    setIconBack("transparent");
  };

  const iconButtonStyle = {
    height: 36,
    width: 36,
    background: iconBack,
    color: iconColor
  };

  return (
    <div
      className="card-browser-filter"
      style={{ marginLeft: 12, marginRight: 8 }}
    >
      <PopupMenu
        options={
          sortByTypeOptions &&
          _map(sortByTypeOptions, option => ({
            label: option.label,
            value: option.value
          }))
        }
        value={sortByType}
        onChange={handleSortByTypeChange}
      />

      {/* 
      TODO: Figure out why reversing sort order when sort
      by is filesize does nothing.
      */}
      <Tooltip title="Reverse sort direction">
        <IconButton
          onClick={() =>
            onSortByOrderClick(sortByOrder === "desc" ? "asc" : "desc")
          }
          onMouseEnter={handleIconHover}
          onMouseLeave={handleIconOut}
          style={iconButtonStyle}
        >
          {sortByOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
        </IconButton>
      </Tooltip>
    </div>
  );
};
