import React from "react";
import Draggable from "react-draggable";
import SwipeableViews from "react-swipeable-views";

import _map from "lodash/map";

import { Button, Tabs, Tab } from "@material-ui/core";

import Logger from "utils/logger";
import { List } from "components";
import "./StyleEditModal.scss";

export class StyleEditModal2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, index) {
    this.setState({ tabIndex: index });
  }

  render() {
    const { title, tabs, forms, formData, onInputChange, onCancel, onSave } =
      this.props;

    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)"
      }
    };

    const tabsStyle = {};

    return (
      <div className="style-modal-container open">
        <Draggable handle=".handler">
          <div
            className="style-editor-modal"
            style={{
              left: "40%",
              top: "15%"
            }}
          >
            <div className="modal-relative-container">
              <div className="header-container handler">
                <h2>{title}</h2>
              </div>
              <div className="form-container">
                {/* Only render tabs if there's more than one. */}
                {tabs.length > 1 && (
                  <Tabs
                    {...tabsStyle}
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}
                  >
                    {tabs &&
                      _map(tabs, (tab, index) => {
                        return (
                          <Tab
                            key={`tab-${tab.label}`}
                            {...tabStyle}
                            label={tab.label}
                            value={index}
                          />
                        );
                      })}
                  </Tabs>
                )}

                <SwipeableViews
                  index={this.state.tabIndex}
                  onChangeIndex={this.handleTabChange}
                >
                  {tabs &&
                    _map(tabs, tab => {
                      Logger.debug(
                        { tab },
                        "[STYLE EDIT MODAL] - swipeable view"
                      );
                      return (
                        <div key={`panel-${tab.label}`}>
                          <List
                            styleName="plain"
                            items={forms[tab.id]}
                            data={formData}
                            handleInputChange={onInputChange}
                          />
                        </div>
                      );
                    })}
                </SwipeableViews>
              </div>

              <div className="modal-actions-container">
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onSave} color="primary">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
        <div className="modal-overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default StyleEditModal2;
