import React from "react";

import { ImageTag } from "../ImageTag";

export class Chooser extends React.Component {
  render() {
    const { thumbCloudPublicId, cloudName, title, actionLabel, actionOnClick } =
      this.props;

    return (
      <div className="form-option form-option_open-theme-selector">
        <div className="thumbnail">
          {thumbCloudPublicId && (
            <ImageTag
              cloudName={cloudName}
              cloudPublicId={thumbCloudPublicId}
              width="100%"
              height="100%"
            />
          )}
        </div>
        <div className="controls">
          <div className="text-block">
            {title && <div className="sc-label-3">{title}</div>}
          </div>

          <button className="sc-button">
            <a href="#" className="label sc-button-3" onClick={actionOnClick}>
              {actionLabel}
            </a>
          </button>
        </div>
      </div>
    );
  }
}
