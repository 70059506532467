import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { ArrowBack, Close } from "@material-ui/icons";

import "./ContentContainer.scss";
import { Dialog } from "components";

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialogOpen: false,
      confirmDialogLink: null
    };
    this.openConfirmDialog = this.openConfirmDialog.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
  }

  render() {
    return (
      <div
        className={`header-container ${
          this.props.selectMode ? "select-mode" : ""
        } ${this.props.theme}-theme ${this.props.mode}-mode `}
      >
        {this.props.goBackAction && !this.props.selectMode ? (
          <IconButton
            style={{ paddingTop: 20, float: "left", marginLeft: 12 }}
            disableTouchRipple
            className="back-arrow"
            onClick={
              this.props.confirmNavigation
                ? e => this.openConfirmDialog(e, this.props.goBackAction)
                : e => this.proceedToLink(e, this.props.goBackAction)
            }
          >
            <ArrowBack
              viewBox="4 0 24 24"
              color={
                this.props.theme === "dark" ? "#b1b1b1" : "rgba(0, 0, 0, .87)"
              }
            />
          </IconButton>
        ) : null}
        {this.props.closeAction ? (
          <IconButton
            style={{ paddingTop: 22, float: "left", marginLeft: 12 }}
            disableTouchRipple
            className="close"
            onClick={
              this.props.handleCloseClicked
                ? this.props.handleCloseClicked
                : this.props.confirmNavigation
                ? e => this.openConfirmDialog(e, this.props.closeAction)
                : e => this.proceedToLink(e, this.props.closeAction)
            }
          >
            <Close
              viewBox="4 0 24 24"
              color={
                this.props.theme === "dark" ? "#b1b1b1" : "rgba(0, 0, 0, .87)"
              }
            />
          </IconButton>
        ) : null}
        {this.props.title && !this.props.selectMode ? (
          <h2
            className="header-title"
            style={this.props.goBackAction ? { padding: 8 } : null}
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />
        ) : null}
        {this.props.tabs ? (
          <div className="tabs-container">{this.props.tabs}</div>
        ) : null}
        {this.props.actions ? (
          <div className="actions-container">{this.props.actions}</div>
        ) : null}
        <div
          className={`header-content ${
            this.props.alignRight ? "align-right" : "align-left"
          }`}
        >
          {this.props.children}
        </div>

        <Dialog
          content="If you exit without saving, your changes will be lost."
          onConfirm={this.proceedToLink}
          confirmLabel="Exit without saving"
          onCancel={this.closeConfirmDialog}
          open={this.state.confirmDialogOpen}
          title="You have unsaved changes"
        />
      </div>
    );
  }

  openConfirmDialog(e, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ confirmDialogLink: link, confirmDialogOpen: true });
  }

  closeConfirmDialog() {
    this.setState({ confirmDialogLink: null, confirmDialogOpen: false });
  }

  proceedToLink(e, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const proceedLink = link || this.state.confirmDialogLink;
    this.closeConfirmDialog();
    this.props.history.push(proceedLink);
  }
}

HeaderContainer.defaultProps = {
  alignRight: true,
  selectMode: false,
  theme: "dark",
  mode: "simple"
};

HeaderContainer.propTypes = {
  alignRight: PropTypes.bool,
  selectMode: PropTypes.bool,
  confirmNavigation: PropTypes.bool,
  history: PropTypes.object,
  title: PropTypes.string,
  goBackAction: PropTypes.string,
  closeAction: PropTypes.string,
  theme: PropTypes.string,
  mode: PropTypes.string,
  tabs: PropTypes.element,
  actions: PropTypes.element
};

export default withRouter(HeaderContainer);
