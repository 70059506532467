import React from "react";
import { SvgIcon } from "@material-ui/core";

import {
  AccountCircle,
  AddCircle,
  ArrowBack,
  ArrowForward,
  ChevronLeft,
  ChevronRight,
  Close,
  Collections,
  Comment,
  DevicesOther,
  Equalizer,
  ExitToApp,
  Event,
  Favorite,
  Feedback,
  Folder,
  Gavel,
  Help,
  HelpOutline,
  Home,
  Image,
  InsertChart,
  Launch,
  LocationOn,
  Map,
  ModeComment,
  Palette,
  Settings,
  SupervisorAccount,
  SystemUpdateAlt,
  TouchApp,
  ViewList,
  Web,
  WebAsset
} from "@material-ui/icons";

import PropTypes from "prop-types";

import "./Icon.scss";

/*
 * CUSTOM SVG ICONS ---------
 * SVG PATHS taken from the attached files
 */

const Category = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path d="M12 2l-5.5 9h11z" />
      <circle cx="17.5" cy="17.5" r="4.5" />
      <path d="M3 13.5h8v8H3z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  );
};

const DesignIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path d="M16.24 11.51l1.57-1.57-3.75-3.75-1.57 1.57-4.14-4.13c-.78-.78-2.05-.78-2.83 0l-1.9 1.9c-.78.78-.78 2.05 0 2.83l4.13 4.13L3 17.25V21h3.75l4.76-4.76 4.13 4.13c.95.95 2.23.6 2.83 0l1.9-1.9c.78-.78.78-2.05 0-2.83l-4.13-4.13zm-7.06-.44L5.04 6.94l1.89-1.9L8.2 6.31 7.02 7.5l1.41 1.41 1.19-1.19 1.45 1.45-1.89 1.9zm7.88 7.89l-4.13-4.13 1.9-1.9 1.45 1.45-1.19 1.19 1.41 1.41 1.19-1.19 1.27 1.27-1.9 1.9zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34c-.47-.47-1.12-.29-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </SvgIcon>
  );
};

const RateDislikeIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path
        id="color"
        d="M12,5c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S15.9,5,12,5z M16,14.6L14.6,16L12,13.4L9.4,16L8,14.6
	l2.6-2.6L8.2,9.6l1.4-1.4l2.4,2.4L14.6,8L16,9.4L13.4,12L16,14.6z"
        style={iconStyle}
      />
      <g id="iconAndRing" fill={props.color2}>
        <path
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7
		S15.9,19,12,19z"
        />
        <polygon points="14.6,8 12,10.6 9.6,8.2 8.2,9.6 10.6,12 8,14.6 9.4,16 12,13.4 14.6,16 16,14.6 13.4,12 16,9.4 	" />
      </g>
    </SvgIcon>
  );
};

const RateLikeIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path
        id="color"
        d="M12,5c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S15.9,5,12,5z M11,16l-3-3l1.4-1.4l1.6,1.6l4.6-4.6
	L17,10L11,16z"
      />
      <g id="iconAndRing" fill={props.color2}>
        <path
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7
		S15.9,19,12,19z"
        />
        <polygon points="11,13.2 9.4,11.6 8,13 11,16 17,10 15.6,8.6" />
      </g>
    </SvgIcon>
  );
};

const RateNeutralIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path
        id="iconAndRing"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,16c-2.2,0-4-1.8-4-4
	s1.8-4,4-4s4,1.8,4,4S14.2,16,12,16z"
        fill={props.color2}
      />
      <circle id="color" cx="12" cy="12" r="4" />
    </SvgIcon>
  );
};

const RateStronglyDislikeIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path
        id="color"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,15.6L15.6,17L12,13.4L8.4,17
	L7,15.6l3.6-3.6L7,8.4L8.4,7l3.6,3.6L15.6,7L17,8.4L13.4,12L17,15.6z"
      />
      <polygon
        id="iconAndRing"
        points="17,15.6 15.6,17 12,13.4 8.4,17 7,15.6 10.6,12 7,8.4 8.4,7 12,10.6 15.6,7 17,8.4
	13.4,12 "
        fill={props.color2}
      />
    </SvgIcon>
  );
};

const RateStronglyLikeIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor
  };
  return (
    <SvgIcon {...iconProps}>
      <path
        id="color"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,17l-5-5l1.4-1.4l3.6,3.6
	l7.6-7.6L19,8L10,17z"
      />
      <polygon
        id="iconAndRing"
        points="10,17 5,12 6.4,10.6 10,14.2 17.6,6.6 19,8 "
        fill={props.color2}
      />
    </SvgIcon>
  );
};

const NavChevronLeftIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor,
    viewBox: "0 0 100 100"
  };
  //<polygon id="arrow" points="29,59.7 4.1,31.7 29,3.8 30.5,5.1 6.8,31.7 30.5,58.4 "/>
  //<path d="M33.985,7.861 L34.692,8.568 L19.261,24 L34.692,39.431 L33.985,40.138 L18.2,24.353 L17.847,24 L18.2,23.646 L33.985,7.861 z"/>

  return (
    <SvgIcon {...iconProps}>
      <g id="nav-arrow-left">
        <path d="M71.49,0 L73.589,2.19 L27.786,50.002 L73.589,97.81 L71.49,100 L24.637,51.095 L23.589,50.002 L24.637,48.905 L71.49,0 z" />
      </g>
    </SvgIcon>
  );
};

const NavChevronRightIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor,
    viewBox: "0 0 100 100"
  };
  return (
    <SvgIcon {...iconProps}>
      <g id="nav-arrow-right">
        <path d="M25.688,0 L23.589,2.19 L69.392,50.002 L23.589,97.81 L25.688,100 L72.541,51.095 L73.589,50.002 L72.541,48.905 L25.688,0 z" />
      </g>
    </SvgIcon>
  );
};

const PlayIcon = props => {
  const iconProps = {
    style: props.style,
    color: props.color,
    hoverColor: props.hoverColor,
    viewBox: "0 0 100 100"
  };

  return (
    <SvgIcon {...iconProps}>
      <g id="play">
        <path
          id="ground"
          d="M50,0.833 C22.958,0.833 0.833,22.958 0.833,50 C0.833,77.042 22.958,99.167 50,99.167 C77.042,99.167 99.167,77.042 99.167,50 C99.167,22.958 77.042,0.833 50,0.833 z"
        />
        <path id="arrow" d="M40.167,72.125 L40.167,27.875 L69.667,50 z" />
      </g>
    </SvgIcon>
  );
};

export class Icon extends React.Component {
  // @todo Add a tooltip for collapsed icons with the label text
  getIcon(icon, size, color, hoverColor, color2) {
    const iconProps = {
      style: {
        width: size,
        height: size
      },
      color: color,
      color2: color2,
      hoverColor: hoverColor
    };

    if (!color2) delete iconProps.color2;

    switch (icon) {
      case "account-circle":
        return <AccountCircle {...iconProps} />;
      case "add-circle":
        return <AddCircle {...iconProps} />;
      case "analytics":
        return <InsertChart {...iconProps} />;
      case "arrow-back":
        return <ArrowBack {...iconProps} />;
      case "arrow-forward":
        return <ArrowForward {...iconProps} />;
      case "chevron-left":
        return <ChevronLeft {...iconProps} />;
      case "chevron-right":
        return <ChevronRight {...iconProps} />;
      case "close":
        return <Close {...iconProps} />;
      case "collections":
        return <Collections {...iconProps} />;
      case "comment":
        return <Comment {...iconProps} />;
      case "design":
        return <DesignIcon {...iconProps} />;
      case "devices-other":
        return <DevicesOther {...iconProps} />;
      case "cover":
      case "system-update-alt":
        return <SystemUpdateAlt {...iconProps} />;
      case "event":
        return <Event {...iconProps} />;
      case "exit-to-app":
        return <ExitToApp {...iconProps} />;
      case "feedback":
        return <Feedback {...iconProps} />;
      case "folder":
        return <Folder {...iconProps} />;
      case "favorite":
        return <Favorite {...iconProps} />;
      case "gavel":
        return <Gavel {...iconProps} />;
      case "help":
        return <Help {...iconProps} />;
      case "home":
        return <Home {...iconProps} />;
      case "help-outline":
        return <HelpOutline {...iconProps} />;
      case "image":
        return <Image {...iconProps} />;
      case "launch":
        return <Launch {...iconProps} />;
      case "location":
        return <LocationOn {...iconProps} />;
      case "map":
        return <Map {...iconProps} />;
      case "mode-comment":
        return <ModeComment {...iconProps} />;
      case "nav-chevron-left":
        return <NavChevronLeftIcon {...iconProps} />;
      case "nav-chevron-right":
        return <NavChevronRightIcon {...iconProps} />;
      case "play":
        return <PlayIcon {...iconProps} />;
      case "palette":
        return <Palette {...iconProps} />;
      case "rate-dislike":
        return <RateDislikeIcon {...iconProps} />;
      case "rate-like":
        return <RateLikeIcon {...iconProps} />;
      case "rate-neutral":
        return <RateNeutralIcon {...iconProps} />;
      case "rate-strongly-dislike":
        return <RateStronglyDislikeIcon {...iconProps} />;
      case "rate-strongly-like":
        return <RateStronglyLikeIcon {...iconProps} />;
      case "results":
      case "equalizer":
        return <Equalizer {...iconProps} />;
      case "settings":
        return <Settings {...iconProps} />;
      case "supervisor-account":
        return <SupervisorAccount {...iconProps} />;
      case "category":
        return <Category {...iconProps} />;
      case "touch":
        return <TouchApp {...iconProps} />;
      case "view-list":
        return <ViewList {...iconProps} />;
      case "web":
        return <Web {...iconProps} />;
      case "web-asset":
        return <WebAsset {...iconProps} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div
        className={`${
          this.props.className ? this.props.className : ""
        } cms-icon`}
        style={this.props.style}
      >
        {this.getIcon(
          this.props.icon,
          this.props.width,
          this.props.color,
          this.props.hoverColor,
          this.props.color2
        )}
        {this.props.label ? (
          <div className="tooltip">{this.props.label}</div>
        ) : null}
      </div>
    );
  }
}

Icon.defaultProps = {
  width: 28,
  style: {},
  color: "rgba(0, 0, 0, 0.54)",
  label: "",
  hoverColor: undefined
};

Icon.propTypes = {
  icon: PropTypes.string,
  width: PropTypes.number,
  color: PropTypes.string,
  color2: PropTypes.string,
  hoverColor: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string
};
