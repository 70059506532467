import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Logger from "utils/logger";

import { IconButton, Tooltip } from "@material-ui/core";
import { List } from "@material-ui/icons";

import { CardBrowser, Dialog, TopBar } from "components";
import { galleryEditorMapStateToProps } from "mapToProps/sceneEditor";
import ContentContainer from "modules/ContentContainer";
import * as storyActions from "actions/storyEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";

/**
 * GalleryEditor
 * card browser for subscenes
 */
class GalleryEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModalOpen: false,
      addModalOpen: false,
      scenes: this.props.scenes
    };

    // this.resizingWindow = this.resizingWindow.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    // this.checkAndUpdateTotalCount = this.checkAndUpdateTotalCount.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.injectFeedbackIcon = this.injectFeedbackIcon.bind(this);

    // this.injectFeedbackIcon();
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  goToFeedback(value) {
    Logger.debug({ value }, "[GALERY EDITOR] Goto feedback");
  }

  injectFeedbackIcon() {
    const scenes = this.state.scenes;
    const iconButtonStyle = {
      width: 40,
      height: 40,
      padding: 0
    };
    const iconStyle = {
      width: 24,
      height: 24,
      color: "white"
    };

    scenes.forEach((item, index) => {
      if (this.props.scenesWithFeedback.indexOf(item._id) > -1) {
        scenes[index].bottomRightIcons = (
          <Tooltip title="Browse Feedback">
            <IconButton
              className="icon select"
              onClick={this.goToFeedback}
              style={iconButtonStyle}
              disableTouchRipple
            >
              <List style={iconStyle} />
            </IconButton>
          </Tooltip>
        );
      }
    });
    this.setState({ scenes });
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.scenes !== "undefined") {
      this.setState({ scenes: nextProps.scenes });
    }
  }

  proceedToAdd() {
    this.props.openAssetSelectorFunc();
  }

  openDeleteModal() {
    Logger.debug("[GALLERY EDITOR] Show Delete Modal");
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    // this.props.setLibraryIsLoading(true); //NOTE: is this correct?
    this.props.deleteSubScene();
    this.props.exclusiveSelectHandler(null); // deselect
  }

  render() {
    const {
      cardBrowserConfig,
      isLoading,
      sidebarMode,
      storyID,
      slugBase,
      needsSaving,
      scenes,
      // functions
      goBackAction,
      setactiveEditingScene, // only effects subscene
      resetOrderFunc,
      setSubSceneOrder,
      editClickHandler,
      selectHandler,
      exclusiveSelectHandler,
      backClickHandler,
      setSliderMultiplier
    } = this.props;

    // if (isLoading) {
    //   return <div>loading gallery subScenes....</div>;
    // }
    const topBar = (
      <TopBar
        cardBrowserConfig={cardBrowserConfig}
        setSliderMultiplier={setSliderMultiplier}
        exclusiveSelectHandler={exclusiveSelectHandler}
        proceedToAdd={() => {
          this.proceedToAdd();
        }}
        openDeleteModal={this.openDeleteModal}
      />
    );

    return (
      <div className="gallery-editor">
        <ContentContainer
          backgroundColor="white"
          isLoading={isLoading}
          className="content-container"
          sidebarMode={sidebarMode}
          selectMode={cardBrowserConfig.selectMode}
          title="Asset Browser"
          goBackAction={this.props.goBackAction}
          topBar={topBar}
        >
          {Object.keys(this.state.scenes).length > 0 ? (
            <CardBrowser
              items={this.state.scenes}
              minCardSize={cardBrowserConfig.cardSizeMultiplier}
              enableKeys={false}
              cardBrowserMaxCardSize={cardBrowserConfig.cardBrowserMaxCardSize}
              showButtons
              setCardMargin={20}
              displayCaption={false}
              isSequentiable
              executeOnReSort={setSubSceneOrder}
              selectHandler={selectHandler}
              cardStyle={{
                backgroundColor: "whitesmoke",
                titleBackgroundColor: "whitesmoke"
              }}
              selectMode={cardBrowserConfig.selectMode}
              selected={cardBrowserConfig.selected}
              editClickHandler={editClickHandler}
              exclusiveSelectHandler={exclusiveSelectHandler}
              backClickHandler={backClickHandler}
            />
          ) : null}
        </ContentContainer>
        <Dialog
          content="Once deleted, it cannot be restored"
          onConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          onCancel={this.closeDeleteModal}
          open={this.state.deleteModalOpen}
          title="Delete this Asset?"
        />
      </div>
    );
  }

  componentDidMount() {}
}

GalleryEditor.defaultProps = {
  isLoading: false
};

GalleryEditor.propTypes = {
  isLoading: PropTypes.bool,
  scenes: PropTypes.array,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  openSceneEditorModalFunc: PropTypes.func, // Function that opens the modal for scene editing
  openAssetSelectorFunc: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return galleryEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    storyID,
    sceneID,
    cardBrowserConfig,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;

  const cardBrowserSelector = ["sceneTypes", "gallery", "cardBrowserConfig"]; // immutable path to locate config
  return Object.assign({}, stateProps, {
    deleteSubScene: () => {
      Logger.debug("[GALLERYEDITOR] delete subscene");
      const { selected } = cardBrowserConfig;
      dispatchProps.deleteSubScene(sceneID, selected);
    },
    setSubSceneOrder: (oldIndex, newIndex) =>
      dispatchProps.setSubSceneOrder(sceneID, oldIndex, newIndex),
    backClickHandler: id => {
      dispatchProps.exclusiveSelectToggle(null, cardBrowserSelector);
    },
    openAssetSelectorFunc: value => {
      ownProps.openAssetSelectorFunc(value);
    },
    setSliderMultiplier: value => {
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "cardSizeMultiplier",
        value
      );
    },
    editClickHandler: (id, coords) => {
      ownProps.openSceneEditorModalFunc(id, coords.left, coords.top);
    },
    selectHandler: id => dispatchProps.selectToggle(id, cardBrowserSelector),
    exclusiveSelectHandler: id =>
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector)
  });
}

export default connect(
  mapStateToProps,
  { ...storyActions, ...sceneActions },
  mergeProps
)(GalleryEditor);
