import React, { Component } from "react";

import "./TextContainer.scss";

export class TextContainer extends Component {
  constructor(props) {
    super(props);
    this.processEditClick = this.processEditClick.bind(this);
  }

  // Functions used by Editing Mode
  processEditClick(value, event) {
    event.preventDefault();
    event.stopPropagation();
    if (
      typeof this.props.triggerEditFunc !== "undefined" &&
      this.props.isEditMode
    ) {
      this.props.triggerEditFunc(value);
    }
  }

  render() {
    const {
      layout, // slideshow or other scene layout name
      className,
      text,
      // cms related
      elementID,
      textBoxStyle,
      stylesString,
      styles,
      isEditMode,
      assetFit,
      isActiveEditingElement,
      textRef,
      classes,
      style,
      shadow,
      buildInAnimation,
      buildInAnimationSpeed,
      buildInAnimationDelay
    } = this.props;

    // Custom classes that will be added to the Text Container if they're defined
    let customClasses = classes || "";
    customClasses += style ? ` block-0${style}` : "";
    customClasses += buildInAnimation
      ? ` animate ${buildInAnimation} ${buildInAnimationSpeed} ${buildInAnimationDelay}`
      : "";
    customClasses += shadow ? ` ${shadow}` : "";

    if (isEditMode) {
      // NOTE: NOT sure how languages fit into this....
      // DONT think this is still needed handled i a global function
      // let stylesString = "";
      // if (this.props.styles) {
      //   _map(this.props.styles, (style, key) => {
      //     let parsedKey = key.replace(/\*/g, ".");
      //     let result = "";
      //     _map(style, (value, key) => {
      //       result += key + ":" + value + ";";
      //     });
      //     stylesString +=
      //       " ." + elementID + " " + parsedKey + " {" + result + "}";
      //   });
      // }
      // we want to set the ref on the top most element, the one that will be a direct child of column
      return (
        <div className={`sc-module text ${customClasses}`}>
          <div
            className={`${className} ${layout} edit-mode ${
              isActiveEditingElement ? "active-editing" : ""
            } ${!this.props || !text ? "edit-empty" : ""}`}
            style={this.props ? textBoxStyle : null}
            // onClick={event => {
            //   this.processEditClick(elementID, event);
            // }}
            ref={textRef}
          >
            {stylesString ? (
              <style dangerouslySetInnerHTML={{ __html: stylesString }} />
            ) : null}

            {/* {!this.props || !text ? (
              <div className="empty-selector">
                <div className="click-to-edit-container">
                  <h3>Click to Edit</h3>
                </div>
              </div>
            ) : null} */}
            {this.props ? (
              <div
                className={`${elementID} inner-wrapper`}
                dangerouslySetInnerHTML={{ __html: text || "" }}
                style={styles}
              />
            ) : null}
          </div>

          {/* Shadow div to create layer over module/element 
          to enable user to click to add/edit text. */}
          <div
            className="sc-edit sc-edit-module"
            onClick={event => {
              this.processEditClick(elementID, event);
            }}
          >
            {!this.props || !text ? (
              <div className="empty-selector">
                <div className="click-to-edit-container">
                  <h3>Click to Edit</h3>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else if (text) {
      return (
        <div className={`sc-module text ${customClasses}`}>
          <div
            className={`${className || ""}`}
            style={styles}
            dangerouslySetInnerHTML={{ __html: text || "" }}
            ref={textRef}
          />
        </div>
      );
    } else {
      return null; //no html needed if no text
    }
  }
}
