import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

import Logger from "utils/logger";
import { CardBrowser, CardBrowserSelectedDisplay } from "components";
import ContentContainer from "modules/ContentContainer";
import { themeSelectorMapStateToProps } from "mapToProps/themeSelector";
import * as projectEditorActions from "actions/projects";
import "./ThemeSelector.scss";

export class ThemeSelector extends React.Component {
  constructor(props) {
    super(props);

    Logger.debug(
      { selectedThemeId: props.selectedThemeId },
      "ThemeSelector: constructor"
    );

    this.state = {
      selectedThemeId: props.selectedThemeId || null
    };
    this.selectTheme = this.selectTheme.bind(this);
    this.backClickHandler = this.backClickHandler.bind(this);
  }

  // Why do we need this life cycle method? In the LayoutSelector component,
  // state isn't changed unless isEditMode is true. When is that prop true?
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedThemeId) {
      if (
        nextProps.selectedThemeId &&
        nextProps.selectedThemeId !== this.state.activeThemeId
      ) {
        this.setState({ selectedThemeId: this.props.selectedThemeId });
      }
    }
  }

  selectTheme(themeId) {
    if (this.state.selectedThemeId === themeId) {
      this.setState({ selectedThemeId: null });
    } else {
      this.setState({ selectedThemeId: themeId });
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({ selectedThemeId: null });
    }
  }

  backClickHandler() {
    this.setState({ selectedThemeId: null });
  }

  render() {
    const { isLoading, isOpen, themes } = this.props;

    // Map State to Props
    const { handleThemeSelect } = this.props;

    const topBar = this.state.selectedThemeId ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedLabel="Select Theme"
          deselectAllAction={this.props.handleCloseClick}
        />
        <div className="select-button-container">
          <Button
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleThemeSelect(this.state.selectedThemeId);
            }}
          >
            SELECT
          </Button>
        </div>
      </div>
    ) : (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedLabel="Select Theme"
          deselectAllAction={this.props.handleCloseClick}
        />
      </div>
    );

    return (
      <div className={`theme-selector ${isOpen ? "open" : ""}`}>
        <ContentContainer
          isLoading={isLoading}
          className="content-container"
          selectMode={true}
          sidebarMode="none"
          title="Select Theme"
          topBar={topBar}
          handleCloseClicked={this.props.handleCloseClicked}
        >
          {themes.length > 0 && isOpen ? (
            <CardBrowser
              selected={[this.state.selectedThemeId]}
              minCardSize={150}
              minCardMargin={60}
              items={themes}
              enableKeys={false}
              showButtons
              openModal={isOpen}
              isModal
              selectHandler={this.selectTheme}
              selectMode={false}
              exclusiveSelectHandler={this.selectTheme}
              backClickHandler={this.backClickHandler}
            />
          ) : null}
        </ContentContainer>
      </div>
    );
  }
}
ThemeSelector.defaultProps = {
  isOpen: false
};

ThemeSelector.propTypes = {
  isOpen: PropTypes.bool,
  sceneType: PropTypes.string,
  themes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  activeTheme: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return themeSelectorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, {
    handleCloseClick: ownProps.handleCloseClick,
    handleThemeSelect: ownProps.handleThemeSelect
  });
}

export default connect(
  mapStateToProps,
  projectEditorActions,
  mergeProps
)(ThemeSelector);
