import React from "react";
import PropTypes from "prop-types";

import { List } from "components";
import Sidebar from "modules/Sidebar/Sidebar";
import "./QuizSidebar.scss";

class QuizEditorSidebar extends React.Component {
  render() {
    const { navigation, slugBase } = this.props;
    return (
      <Sidebar
        title={this.props.title}
        goBackAction={this.props.goBackAction}
        actionBar={this.props.actionBar}
        className
      >
        <List items={navigation} slugBase={slugBase} styleName="boxed" />
      </Sidebar>
    );
  }
}

QuizEditorSidebar.defaultProps = {};

QuizEditorSidebar.propTypes = {
  title: PropTypes.string,
  actionBar: PropTypes.element
};

export default QuizEditorSidebar;
