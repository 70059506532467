import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import _debounce from "lodash/debounce";

import {
  Audiotrack,
  Done,
  FileDownload,
  Image,
  Videocam
} from "@material-ui/icons";

import { List, PopupMenu } from "components";
import Sidebar from "modules/Sidebar/Sidebar";
import { getLibrarySidebarProps } from "reducers/ui/library";
import * as libraryActions from "actions/library";
import "./LibrarySidebar.scss";

export class LibrarySidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      langValue: "English"
    };
    this.handleLanguage = this.handleLanguage.bind(this);
  }

  componentDidMount() {
    // The sidebar container div scrolls up for some inexplicable
    // reason sometimes. Reset the scroll position to account for this.
    const sidebarContainer =
      document.getElementsByClassName("sidebar-container")[0];
    sidebarContainer.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // The sidebar container div scrolls up for some inexplicable
    // reason sometimes. Reset the scroll position to account for this.
    if (prevProps.assetId !== this.props.assetId) {
      const sidebarContainer =
        document.getElementsByClassName("sidebar-container")[0];
      sidebarContainer.scrollTo(0, 0);
    }
  }

  getAssetEditorActionBar(type) {
    if (!type) return null;
    let icon;
    switch (type) {
      case "image":
        icon = <Image style={{ color: "white" }} />;
        break;
      case "video":
        icon = <Videocam style={{ color: "white" }} />;
        break;
      case "audio":
      default:
        icon = <Audiotrack style={{ color: "white" }} />;
    }
    return (
      <div className="sidebar-subheader-content">
        <div className="subheader-icon">{icon}</div>
        <div className="subheader-meta">
          <div className="filename">{this.props.assetData.filename}</div>
          <div className="extra-data">
            <a
              href={this.props.assetDownloadURL}
              download
              style={{ float: "right", marginTop: -20 }}
            >
              <FileDownload style={{ color: "#424242" }} />
            </a>
          </div>
        </div>
      </div>
    );
  }

  handleLanguage(value) {
    this.setState({
      langValue: value
    });
  }

  render() {
    const showButtons = !this.props.isLoadingAssetEditor && !this.props.isSaved;

    const languageOptions = [
      { value: "English", label: "English" },
      { value: "Español", label: "Español" },
      { value: "Français", label: "Français" }
    ];

    // 8/25/2024: The Language Button is hidden via CSS because it's not
    // working. TODO: Figure out how to actually change the global lang setting.
    const auxButton = (
      <PopupMenu
        options={languageOptions}
        value="English"
        onChange={this.handleLanguage}
      />
    );

    return (
      <Sidebar
        scrollY={false}
        goBackAction={showButtons ? null : this.props.backAction}
        title={this.props.header}
        handleSave={this.props.updateAsset}
        confirmRevert={true}
        handleRevert={this.props.revertAsset}
        showButtons={showButtons}
        auxButton={this.props.isAssetEditor ? auxButton : null}
        isSavingLabel="Saving..."
        headerSize="small-height"
        saveLabel="Save"
        revertLabel="Cancel"
        actionBar={this.getAssetEditorActionBar(
          this.props.assetData ? this.props.assetData.type : null
        )}
      >
        {this.props.isAssetEditor ? (
          <div className="forms-container">
            {this.props.hasData ? (
              <List
                id="asset-editor-list"
                handleInputChange={this.props.handleInputChange}
                items={this.props.sidebar}
                styleName="plain"
                data={this.props.assetData}
              />
            ) : null}
          </div>
        ) : (
          <div className="filters-container">
            <List
              items={this.props.sidebar}
              styleName="plain"
              handleInputChange={this.props.sidebarHandleInputChange}
              slugBase={`/${this.props.slug}`}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

LibrarySidebar.defaultProps = {
  isAddNewAsset: false
};

LibrarySidebar.propTypes = {
  assetDownloadURL: PropTypes.string,
  isAddNewAsset: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return getLibrarySidebarProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { assetId, assetData, revertCopy } = stateProps;
  const handleSearchDebounced = _debounce(function (property, value) {
    dispatchProps.setDataFromInput.apply(this, [
      property,
      value,
      assetId,
      assetData
    ]);
  }, 1000);

  return Object.assign({}, stateProps, {
    assetDownloadURL: ownProps.assetDownloadURL,
    updateAsset: () => dispatchProps.updateAsset(assetId, assetData),
    handleInputChange: (property, value) =>
      handleSearchDebounced(property, value),
    revertAsset: () => dispatchProps.revertAsset(assetId, revertCopy),
    sidebarHandleInputChange: (value, checked) => {
      if (checked) {
        dispatchProps.setLibraryCategoryFilter(value);
      } else {
        dispatchProps.unsetLibraryCategoryFilter(value);
      }
    },
    copyAsset: () => dispatchProps.copyAsset(assetData, true),
    saveTempAsset: () => dispatchProps.saveTempAssetCreate({ data: assetData })
  });
}

export default connect(
  mapStateToProps,
  libraryActions,
  mergeProps
)(LibrarySidebar);
