import React from "react";
import "react-image-crop/lib/ReactCrop.scss";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

import { CardBrowserSelectedDisplay } from "components";
import ContentContainer from "modules/ContentContainer";
import ReactCrop from "react-image-crop";
import "./ImageCropper.scss";

export class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: this.props.data,
      pixelCrop: null
    };
    this.functionHandler = this.functionHandler.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ selected: null });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ crop: nextProps.data });
  }

  functionHandler() {
    this.props.closeAction();
    this.props.processCropAction(this.state.crop);
  }

  handleCloseAction() {
    this.props.closeAction();
  }

  render() {
    const topBar = (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedLabel="Image Crop"
          deselectAllAction={this.props.closeAction}
        />
        <div className="select-button-container">
          <Button
            className="new-asset-button-v2"
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.functionHandler();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    );

    return (
      <div className={`image-cropper ${this.props.open ? "open" : ""}`}>
        <ContentContainer
          isLoading={this.props.isLoading}
          className="content-container"
          selectMode={true}
          sidebarMode="none"
          title="Select Layout"
          topBar={topBar}
        >
          <div className="relative-subcontainer">
            {this.props.imageScr ? (
              <ReactCrop
                src={this.props.imageScr}
                onChange={(crop, pixelCrop) => {
                  this.setState({ crop, pixelCrop });
                }}
                crop={this.state.crop}
              />
            ) : null}
          </div>
        </ContentContainer>
      </div>
    );
  }
}

ImageCropper.defaultProps = {
  open: false,
  data: null
};

ImageCropper.propTypes = {
  open: PropTypes.bool,
  imageScr: PropTypes.string,
  isLoading: PropTypes.bool,
  closeAction: PropTypes.func,
  processCropAction: PropTypes.func,
  data: PropTypes.object
};

export default ImageCropper;
