import React from "react";

import { CardBrowser } from "../CardBrowser";

export class ThumbSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || ""
    };

    this.selectHandler = this.selectHandler.bind(this);
  }

  /**
   * The selectHandler is called when the user selects a thumbnail
   * or when the component is unmounted (possibly... maybe that's
   * why id is null when the element editor is closed???)
   *
   * @param {*} id
   */
  selectHandler(id) {
    if (id !== null && this.state.selected !== id) {
      this.setState({ selected: id });
      if (this.props.onChange) {
        this.props.onChange(this.props.name, id);
      }
    }
  }

  render() {
    const { id, name, className, label, options, component } = this.props;
    return (
      <div className={`cms-thumb-select ${name}`} key={id}>
        {label}
        <CardBrowser
          className={className}
          items={options}
          CardContentsComponent={component}
          enableKeys={false}
          minCardSize={150}
          setCardMargin={20}
          selected={[this.state.selected]}
          selectHandler={this.selectHandler}
          exclusiveSelectHandler={this.selectHandler}
        />
      </div>
    );
  }
}
