import React from "react";
import PropTypes from "prop-types";

import _filter from "lodash/filter";
import _map from "lodash/map";

import { Button, Divider } from "@material-ui/core";

import { InputText } from "../InputText";
import { LabelInfo } from "../LabelInfo";
import { Select } from "../Select";
import { Switch } from "../Switch";
import "./Form.scss";

/**
 * TODO: This component is used in the Login and QuestionEditor
 * components. It seems to have been replaced by the List
 * component. See if we can update the consumers to use
 * List instead and remove this component.
 */
export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValid: true, elements: { ...props.elements } };
    this.handleChangeWrapper = this.handleChangeWrapper.bind(this);
    this.updateIsValid = this.updateIsValid.bind(this);
    this.clearForm = this.clearForm.bind(this);
    console.info("Form: Component Deprecated: Switch to List");
  }

  generateLabel(labelText, infoText, labelStyle) {
    return (
      <div className="label-container" style={labelStyle}>
        <span>{labelText}</span>
        {infoText ? <LabelInfo info={infoText} /> : null}
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ elements: nextProps.elements });
  }

  render() {
    const elements = this.state.elements;
    return (
      <form
        className={`cms-form ${this.props.className}`}
        onSubmit={event => {
          this.handleSubmitWrapper(event);
        }}
      >
        {elements &&
          _map(elements, element => {
            const infoText =
              typeof element.info !== "undefined" && element.info.length > 0
                ? element.info
                : null;
            switch (element.type) {
              case "readonly":
                return null;
              case "labelH1":
                return (
                  <h1 key={element.param}>{element.label || element.value}</h1>
                );
              case "labelH2":
                return (
                  <h2 key={element.param}>{element.label || element.value}</h2>
                );
              case "labelH3":
                return (
                  <h3 key={element.param}>{element.label || element.value}</h3>
                );
              case "divider":
                return (
                  <Divider key={element.param} style={{ marginBottom: 30 }} />
                );
              case "select":
                return (
                  <Select
                    value={element.value}
                    key={element.param}
                    style={element.style}
                    name={element.param}
                    underlineStyle={
                      element.underlineStyle
                        ? element.underlineStyle
                        : this.props.underlineStyle
                    }
                    labelStyle={this.props.labelStyle}
                    onChange={this.handleChangeWrapper}
                    hintText={element.hintText}
                    floatingLabel={element.floatingLabel}
                    label={element.label}
                    required={element.required}
                    menuStyle={this.props.menuStyle}
                    selectedMenuItemStyle={this.props.selectedMenuItemStyle}
                    listStyle={this.props.listStyle}
                    menuItemStyle={this.props.menuItemStyle}
                    instructions={element.instructions}
                    instructionsStyle={this.props.instructionsStyle}
                    options={element.options}
                  />
                );
              case "select-multiple":
                return (
                  <Select
                    multiple
                    value={element.value}
                    key={element.param}
                    style={element.style}
                    name={element.param}
                    underlineStyle={
                      element.underlineStyle
                        ? element.underlineStyle
                        : this.props.underlineStyle
                    }
                    labelStyle={this.props.labelStyle}
                    onChange={this.handleChangeWrapper}
                    hintText={element.hintText}
                    floatingLabel={element.floatingLabel}
                    label={element.label}
                    required={element.required}
                    menuStyle={this.props.menuStyle}
                    selectedMenuItemStyle={this.props.selectedMenuItemStyle}
                    listStyle={this.props.listStyle}
                    menuItemStyle={this.props.menuItemStyle}
                    instructions={element.instructions}
                    instructionsStyle={this.props.instructionsStyle}
                    options={element.options}
                  />
                );
              case "toggle":
              case "switch":
                return (
                  <Switch
                    valueFromInput={element.value}
                    key={element.param}
                    name={element.param}
                    onChange={this.handleChangeWrapper}
                    label={this.generateLabel(element.label, infoText)}
                  />
                );
              case "submit":
              case "button":
                return (
                  <Button
                    type={element.type}
                    key={element.param}
                    onClick={event => {
                      this.handleButtonClickWrapper(element.param);
                    }}
                  >
                    {element.label}
                  </Button>
                );
              default:
              case "text":
              case "email":
              case "password":
              case "textarea":
                return (
                  <InputText
                    defaultValue={element.defaultValue}
                    placeholder={element.placeholder}
                    type={element.type}
                    styleName={this.props.styleName}
                    instructions={element.instructions}
                    instructionsStyle={this.props.instructionsStyle}
                    onChange={this.handleChangeWrapper}
                    name={element.param}
                    required={element.required}
                    rows={element.rows}
                    labelStyle={this.props.labelStyle}
                    inputStyle={this.props.inputStyle}
                    inputContainerStyle={this.props.inputContainerStyle}
                    label={this.generateLabel(element.label, infoText)}
                    multiLine={element.type === "textarea"}
                    key={element.param}
                  />
                );
              case "buttonBank":
                return (
                  <div
                    key="button-bank"
                    className="button-bank"
                    style={{ display: "flex" }}
                  >
                    {element.buttons.map((button, index) => (
                      <Button
                        className={button.param}
                        key={button.param}
                        onClick={event => {
                          this[
                            "handleButtonBankButton" +
                              (index + 1) +
                              "ClickWrapper"
                          ](event);
                        }}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                );
            }
          })}
      </form>
    );
  }

  clearForm() {
    let elements = this.state.elements;
    elements &&
      _map(elements, element => {
        element.value = element.type === "select" ? null : "";
        if (this.props.handleChange !== undefined) {
          this.props.handleChange(element.param, element.value);
        }
      });
    this.setState({ elements: elements });
    this.updateIsValid();
  }

  handleChangeWrapper(attrb, value) {
    let elements = this.state.elements;
    elements[attrb].value = value;
    this.setState({ elements: elements });
    this.updateIsValid();
    if (this.props.handleChange !== undefined) {
      this.props.handleChange(attrb, value);
    }
  }

  handleButtonClickWrapper(paramName) {
    this.updateIsValid();
    if (this.props.handleButtonClick !== undefined) {
      this.props.handleButtonClick(paramName);
    }
  }

  handleSubmitWrapper(event) {
    if (this.props.handleSubmit !== undefined) {
      this.props.handleSubmit(event);
    }
  }

  handleButtonBankButton1ClickWrapper(event) {
    if (this.props.handleButtonBankButton1Click !== undefined) {
      this.props.handleButtonBankButton1Click(event);
    }
  }

  handleButtonBankButton2ClickWrapper(event) {
    if (this.props.handleButtonBankButton2Click !== undefined) {
      this.props.handleButtonBankButton2Click(event);
    }
  }

  updateIsValid() {
    let isValid = true;
    this.state.elements &&
      _map(this.state.elements, element => {
        if (
          element.required &&
          (element.value === null ||
            element.value === "" ||
            element.value === undefined)
        ) {
          isValid = false;
        }
      });

    this.setState({ isValid: isValid });

    if (typeof this.props.isValidCallback !== "undefined") {
      this.props.isValidCallback(isValid);
    }
  }

  componentDidMount() {
    this.updateIsValid();
  }
}

Form.defaultProps = {
  elements: {},
  handleChange: (attrb, value) => {
    //console.log('Value changed to ', value, ' for ', attrb);
  },
  styleName: "material",
  className: ""
};

Form.propTypes = {
  className: PropTypes.string,
  elements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelStyle: PropTypes.object,
  styleName: PropTypes.string,
  inputStyle: PropTypes.object,
  instructionsStyle: PropTypes.object,
  underlineStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  handleChange: PropTypes.func, // Function that gets executed when any element changes its value
  handleSubmit: PropTypes.func,
  isValidCallback: PropTypes.func // Callback Function to execute to check if the form is Valid
};
