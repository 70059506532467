/**
simple component to preload images 
pass an array of urls and they will be added to a invisible dom elment 
may want to have the ability to indicate if the image is a poster or regular cloud image
the array passed can be a list of asset elements or just an array of objects with a src property

Props:
- preloadList []
- isPoster
- cloudName

//TODO: prob just want to return null if we are offline...
*/

export const ImagePreloader = props => {
  //  NOTE: it may be smarter to use the cloudinary core to build urls?
  //  const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: props.cloudName});
  // const preloads = []
  props.preloadList.map((item, index) => {
    let src = item.src;
    if (!item.src && props.isPoster) {
      src = !item.posterSrc
        ? `${location.protocol}//res.cloudinary.com/${
            props.cloudName
          }/video/upload/${props.frame || ""}${item.cloudPublicId}.jpg`
        : item.posterSrc;
    } else if (!item.src) {
      src = `${location.protocol}//res.cloudinary.com/${
        props.cloudName
      }/image/upload/${props.frame || ""}${item.cloudPublicId}.jpg`;
    }
    const img = document.createElement("img");
    img.src = src; // Assigning the img src immediately requests the image
    // preloads.push(<img key={index} src={src} />)
  });
  return null;
  // NOTE: not sure if using document create is best course and may need to add a hidden div
  // return (<div style={{'visibility': 'hidden', 'width': 0, 'height': 0, 'overflow': 'hidden'}}>
  // {preloads}
  //</div>)
};
