import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Dialog } from "../Dialog";
import { Icon } from "../Icon";
import "./MenuItem.scss";

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    const activeNav = this.props.activeNav
      .replace(/^\/|\/$/g, "")
      .split("/")[0];
    const isActive =
      activeNav === this.props.to || "/" + activeNav === this.props.to;

    this.state = { hover: false, isActive: isActive, modalOpen: false };
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkIfProceedToLink = this.checkIfProceedToLink.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
  }

  handleMouseEnter() {
    this.setState({ hover: true });
  }

  handleMouseLeave() {
    this.setState({ hover: false });
  }

  componentWillReceiveProps(nextProps) {
    const activeNav = nextProps.activeNav.replace(/^\/|\/$/g, "").split("/")[0];
    const isActive =
      activeNav === nextProps.to || "/" + activeNav === nextProps.to;
    this.setState({ isActive: isActive });
  }

  checkIfProceedToLink() {
    if (this.props.preventLink) {
      this.setState({ modalOpen: true });
    } else {
      this.proceedToLink();
    }
  }

  proceedToLink() {
    this.closeModal();
    this.props.history.push(this.props.to);
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    const activeColor = "#31BCFF"; // this.props.muiTheme.palette.primary1Color;

    const activeStyles = {
      borderLeftColor: activeColor,
      color: activeColor
    };

    const isActive = this.state.isActive || this.state.hover;

    return (
      <div
        onMouseOver={this.handleMouseEnter}
        onMouseOut={this.handleMouseLeave}
        className={`menu-item  ${this.state.isActive ? "active" : ""}`}
        style={isActive ? activeStyles : null}
      >
        {this.props.icon === "exit-to-app" ? (
          <a href={window.location.origin}>
            <Icon
              icon={this.props.icon}
              color={isActive ? activeColor : this.props.iconColor}
              width={this.props.smallIcons ? 20 : 28}
              style={{ float: "left" }}
              label={this.props.mode === "large" ? "" : this.props.label}
            />
            {this.props.mode === "large" && this.props.label.length > 0 ? (
              <div
                className={`menu-label ${
                  this.props.smallIcons ? "small-label" : ""
                }`}
              >
                {this.props.label}
              </div>
            ) : null}
          </a>
        ) : (
          <button onClick={this.checkIfProceedToLink}>
            <Icon
              icon={this.props.icon}
              color={isActive ? activeColor : this.props.iconColor}
              width={this.props.smallIcons ? 20 : 28}
              style={{ float: "left" }}
              label={this.props.mode === "large" ? "" : this.props.label}
            />
            {this.props.mode === "large" && this.props.label.length > 0 ? (
              <div
                className={`menu-label ${
                  this.props.smallIcons ? "small-label" : ""
                }`}
              >
                {this.props.label}
              </div>
            ) : null}
          </button>
        )}
        <Dialog
          content={this.props.preventLinkMessage}
          onConfirm={this.proceedToLink}
          confirmLabel="Exit without saving"
          onCancel={this.closeModal}
          cancelLabel="Cancel"
          open={this.state.modalOpen}
          title="You have unsaved changes"
        />
      </div>
    );
  }
}

MenuItem.defaultProps = {
  activeNav: "",
  smallIcons: false,
  mode: "large",
  preventLink: false,
  iconColor: "rgba(255, 255, 255, 0.7)"
};

MenuItem.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  preventLink: PropTypes.bool,
  preventLinkMessage: PropTypes.string,
  mode: PropTypes.oneOf(["large", "small"]),
  smallIcons: PropTypes.bool,
  activeNav: PropTypes.string,
  iconColor: PropTypes.string
};

// export default MenuItem;
export default withRouter(MenuItem);
