import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _size from "lodash/size";
import _cloneDeep from "lodash/cloneDeep";

import { Button } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import {
  CardBrowser,
  CardBrowserSlider,
  CardBrowserFilter,
  CardBrowserSearch,
  CardBrowserSelectedDisplay
} from "components";
import ContentContainer from "modules/ContentContainer";
import { getAssetSelectorMapStateToProps } from "reducers/ui/library";
import * as libraryActions from "actions/library";
import "./AssetSelector.scss";

export class AssetSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.allowsMultiple ? [] : undefined,
      slider: 235,
      deselectAll: false
      // firstLoad: true,
    };
    this.settingSlider = this.settingSlider.bind(this);
    this.assetSelectorHandler = this.assetSelectorHandler.bind(this);
    this.assetExclusiveSelectorHandler =
      this.assetExclusiveSelectorHandler.bind(this);
    this.standardBarButtonHandler = this.standardBarButtonHandler.bind(this);
    this.deselectBarButtonAction = this.deselectBarButtonAction.bind(this);
    this.exclusiveSelectHandlerAction =
      this.exclusiveSelectHandlerAction.bind(this);
    this.selectHandlerAction = this.selectHandlerAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open === false && nextProps.open) {
      // The asset selector is being opened
      this.setState({ selected: [] });
      this.props.resetLibraryCardBrowser();
    }
  }

  componentDidMount() {
    if (
      typeof this.props.items === "undefined" ||
      !this.props.items ||
      Object.keys(this.props.items) < 1 ||
      this.props.items.length < 1
    ) {
      this.props.requestAssetsAction();
    }
    this.props.resetLibraryCardBrowser();
  }

  componentDidUpdate() {
    this.checkAndUpdateTotalCount();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizingWindow);
  }

  render() {
    let contentContainer;
    let topBar;

    // ********************************* Configuring Top Bar
    topBar = this.props.selectMode ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedCount={this.props.selectedCount}
          deselectAllAction={this.deselectBarButtonAction}
        />

        <div className="select-button-container">
          <Button
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onClick={this.props.selectBarButtonAction}
          >
            {this.props.selectBarButtonLabel}
          </Button>
        </div>
      </div>
    ) : (
      <div className="filters-bar-container">
        {this.props.isModal ? (
          <div className="close-modal-container">
            <Clear
              className="clear-icon"
              style={{
                float: "right",
                marginRight: 0,
                marginTop: 0,
                color: "#fefefe",
                cursor: "pointer",
                height: 24,
                width: 24
              }}
              onClick={this.props.closeModalAction}
            />
          </div>
        ) : null}
        <CardBrowserSearch
          action={this.props.onChangeSearchAction}
          cardSearchMinWidth={300}
          search={this.props.searchContent}
          cardSearchHeight={24}
        />
        <div className="filters-bar">
          {["596d16289e6eb00b3af44e51", "608f2a2be696ddf2ebdd8bf0"].includes(
            this.props.account
          ) &&
            this.props.onJHNButtonClick && (
              <Button
                variant="contained"
                color="primary"
                className="new-asset-button-v2"
                style={{ textTransform: "none" }}
                onClick={this.props.onJHNButtonClick}
              >
                JHN
              </Button>
            )}

          {typeof this.props.standardBarButtonLabel !== "undefined" &&
          (!this.props.hideStandardButtonIfNonSelected ||
            (this.props.hideStandardButtonIfNonSelected &&
              this.state.selected)) ? (
            <Button
              variant="contained"
              color="primary"
              className="new-asset-button-v2"
              style={{ textTransform: "none" }}
              onClick={this.standardBarButtonHandler}
            >
              {this.props.standardBarButtonLabel}
            </Button>
          ) : null}
          <div className="divider" />

          <CardBrowserSlider
            changeValue={this.settingSlider}
            initialValue={this.state.slider}
          />

          <div className="divider" />

          <CardBrowserFilter
            onSortByClick={this.props.filterOnSortByClickAction}
            onSortByOrderClick={this.props.filterOnSortByOrderClickAction}
            sortByTypeOptions={this.props.filterSortByTypeOptions}
            sortByType={this.props.filterSortByType}
            sortByOrder={this.props.filterSortByOrder}
          />
        </div>
      </div>
    );

    // ********************************* Configuring Content Container

    const cardBrowserStyleProps = {
      minCardMargin: parseInt(this.props.minCardMargin, 10),
      captionHeight: parseInt(this.props.captionHeight, 10),
      setCardMargin: this.props.setCardMargin,
      minWidthCaption: this.props.minWidthCaption
    };

    contentContainer = (
      <ContentContainer
        isLoading={this.props.isLoading}
        error={this.props.error}
        className="content-container"
        sidebarMode={this.props.sidebarMode}
        selectMode={this.props.selectMode}
        title={this.props.title}
        topBar={topBar}
      >
        {this.props.items.length > 0 &&
        (!this.props.isModal || (this.props.isModal && this.props.open)) ? (
          <CardBrowser
            showButtons={this.props.showButtons}
            cardBrowserMaxCardSize={300}
            convertTypeToIcon={true}
            forceLoad={this.props.searchContent ? false : true}
            selected={
              this.props.isAssetSelectorMode
                ? this.state.selected
                : this.props.selectedItems
            }
            items={this.props.items}
            displayCaption={true}
            deselectAll={this.state.deselectAll}
            captionPosition="below"
            captionAlignment="center"
            enableKeys={this.props.enableKeys}
            selectHandler={
              this.props.isAssetSelectorMode
                ? this.assetSelectorHandler
                : this.selectHandlerAction
            }
            selectMode={this.props.selectMode}
            exclusiveSelectHandler={
              this.props.isAssetSelectorMode
                ? this.assetExclusiveSelectorHandler
                : this.exclusiveSelectHandlerAction
            }
            backClickHandler={this.props.backClickHandlerAction}
            executeOnScroll={this.props.executeOnScrollAction}
            shiftSelectHandler={this.props.shiftSelectHandlerAction}
            setScrollPosition={this.props.setScrollPositionAction}
            editClickHandler={this.props.editClickHandlerAction}
            minWidthCaption={this.props.minWidthCaption}
            minCardSize={this.state.slider}
            minCardMargin={this.props.minCardMargin}
            isLoading={this.props.isLoading}
            openModal={this.props.open}
            isModal={this.props.isModal}
            {...cardBrowserStyleProps}
          />
        ) : null}
      </ContentContainer>
    );

    return (
      <div
        className={`asset-selector ${this.props.isModal ? "is-modal" : ""} ${
          this.props.open ? "open" : ""
        }`}
        id="assetSelector"
      >
        {contentContainer}
      </div>
    );
  }

  exclusiveSelectHandlerAction(assetId) {
    this.setState({ deselectAll: false });
    if (typeof this.props.exclusiveSelectHandlerAction !== "undefined") {
      this.props.exclusiveSelectHandlerAction(assetId);
    }
  }

  selectHandlerAction(assetId) {
    this.setState({ deselectAll: false });
    if (typeof this.props.selectHandlerAction !== "undefined") {
      this.props.selectHandlerAction(assetId);
    }
  }

  deselectBarButtonAction() {
    this.setState({ deselectAll: true });
    this.props.selectBarDeselectButtonAction();
  }

  standardBarButtonHandler(event) {
    event.stopPropagation();
    // Finding the selected object - in case we want to use it also

    // @NOTE: the following filter is not working as you'd expect.
    //        this.state.selected is an array, as the user can choose one or more assets depending on the context in
    //        which the method is called. As such, the filter condition, item._id === this.state.selected, will never
    //        result in selected being anything but undefined.
    //        As of 10-22-2018 we are leaving this code as-is, and planning on revisiting it later on down the road
    //        as time and resources permit.
    //const selected = this.props.items.filter(item => item._id === this.state.selected)[0];
    const selected = this.props.items.filter(item =>
      this.state.selected ? this.state.selected.includes(item._id) : null
    );

    this.props.standardBarButtonAction(this.state.selected, selected);
  }

  assetExclusiveSelectorHandler(asset) {
    if (this.state.selected === asset || asset === null) {
      this.setState({ selected: [], deselectAll: true });
    } else {
      this.setState({ selected: [asset], deselectAll: false });
    }
  }

  assetSelectorHandler(asset) {
    if (
      (this.state.selected.includes(asset) &&
        this.state.selected.length === 1) ||
      asset === null
    ) {
      this.setState({ selected: [], deselectAll: true });
    } else {
      if (this.props.allowsMultiple) {
        let selected = _cloneDeep(this.state.selected);
        if (!this.state.selected.includes(asset)) {
          // It's not selected
          selected.push(asset);
        } else {
          selected.splice(this.state.selected.indexOf(asset), 1);
        }
        this.setState({ selected, deselectAll: false });
      } else {
        this.setState({ selected: [asset], deselectAll: false });
      }
    }
  }

  checkAndUpdateTotalCount() {
    if (
      typeof this.props.totalCount !== "undefined" &&
      typeof this.props.actualTotalCount !== "undefined"
    ) {
      const currentStoredCount = this.props.totalCount;
      const actualCount = this.props.actualTotalCount;
      if (actualCount > 0 && currentStoredCount !== actualCount) {
        this.props.librarySetTotalCountAction(actualCount);
      }
    }
  }

  settingSlider(value) {
    this.setState({ slider: value });
  }
}

AssetSelector.defaultProps = {
  open: false,
  minWidthCaption: 135,
  setCardMargin: 20,
  hideStandardButtonIfNonSelected: false
};

AssetSelector.propTypes = {
  // Data
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  selectBarButtonLabel: PropTypes.string, // Label for the button in the select bar
  standardBarButtonLabel: PropTypes.string, // Label for the button in the standard bar
  totalCount: PropTypes.number,
  actualTotalCount: PropTypes.number,
  selectedItems: PropTypes.oneOfType([
    // Items selected
    PropTypes.array,
    PropTypes.object
  ]),

  // Specs
  open: PropTypes.bool,
  initialSliderValue: PropTypes.number, // Initial value for the resizing slider
  filterSortByTypeOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  minWidthCaption: PropTypes.number,
  minCardMargin: PropTypes.number,
  minCardSize: PropTypes.number,
  captionHeight: PropTypes.number,
  setCardMargin: PropTypes.number, // If a card margin needs to be forced (not dynamic)
  hideStandardButtonIfNonSelected: PropTypes.bool, // hides the standard button if no assets are selected
  allowsMultiple: PropTypes.bool, // enables multiple selection for asset selector mode
  // States
  isLoading: PropTypes.bool,
  error: PropTypes.string, // Setting error message
  isAssetSelectorMode: PropTypes.bool, // True when we just want to select an asset (not library browser)
  isModal: PropTypes.bool, // Flag to indicate that is a modal which can be hidden
  selectMode: PropTypes.bool,
  sidebarMode: PropTypes.string, // State of the main sidebar if any, it not set to 'none'
  selectedCount: PropTypes.number,
  filterSortByOrder: PropTypes.string, // Order in which the sort filter is being applied
  filterSortByType: PropTypes.string, // type of item set in the sort filter
  searchContent: PropTypes.string, // Content of the search field
  showButtons: PropTypes.bool, // Show buttons in the cards
  enableKeys: PropTypes.bool, // Enable keys for selection functionalities (Cmd + click, etc.)

  // Functions
  closeModalAction: PropTypes.func, // Function to close modal when it's a modal
  selectBarDeselectButtonAction: PropTypes.func, // Function to execute when clicking the 'X' to close the select bar
  selectBarButtonAction: PropTypes.func, // Action executed when pressed the button in the select bar
  onChangeSearchAction: PropTypes.func, // Function to execute during the filter search
  standardBarButtonAction: PropTypes.func, // Action for the button in the standard bar
  filterOnSortByClickAction: PropTypes.func, // Action to execute when sorting by something
  filterOnSortByOrderClickAction: PropTypes.func, // Action to execute when changing the order of the sort filter
  selectHandlerAction: PropTypes.func, // Function for multiple select action
  exclusiveSelectHandlerAction: PropTypes.func, // Function for exclusive select action
  backClickHandlerAction: PropTypes.func, // Function to handle
  setSliderMultiplierAction: PropTypes.func, // Action to execute when using the slider
  executeOnScrollAction: PropTypes.func, // Function to execute when the scroll position bottom is reached
  shiftSelectHandlerAction: PropTypes.func, // Function to execute when selecting and clicking shift key.
  setScrollPositionAction: PropTypes.func, // Action to set scroll position in the card browser
  editClickHandlerAction: PropTypes.func, // Action to execute when clicking in the card edit button
  librarySetTotalCountAction: PropTypes.func // Action to store total item count
};

function mapStateToProps(state, ownProps) {
  return getAssetSelectorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps) {
  const { account, cardBrowserConfigUserPrefsSelector } = stateProps;
  return Object.assign({}, stateProps, {
    filterOnSortByClickAction: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByType",
        value
      ),
    filterOnSortByOrderClickAction: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByOrder",
        value
      ),
    setSliderMultiplierAction: value =>
      dispatchProps.setSliderMultiplier(value),
    requestAssetsAction: () => dispatchProps.requestLibrary(account),
    librarySetTotalCountAction: count =>
      dispatchProps.librarySetTotalCount(count),
    executeOnScrollAction: columns => dispatchProps.addLibraryPage(columns * 3),
    setScrollPositionAction: value => dispatchProps.setScrollPosition(value),
    onChangeSearchAction: search => dispatchProps.setCardBrowserSearch(search),
    resetLibraryCardBrowser: () => dispatchProps.resetLibraryCardBrowser()
  });
}

export default connect(
  mapStateToProps,
  libraryActions,
  mergeProps
)(AssetSelector);
