import React from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import _debounce from "lodash/debounce";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";

import { Button } from "@material-ui/core";

import { Dialog, List } from "components";
import { userEditorMapStateToProps } from "mapToProps/userEditor";
import * as userActions from "actions/userEditor";
import ContentContainer from "modules/ContentContainer";
import styles from "./styles.scss";

const topBar = (isSavedEnabled, handleSaveRequest) => {
  return (
    <div className="user-editor-topbar">
      <Button
        variant="contained"
        color="primary"
        disabled={!isSavedEnabled}
        className="new-asset-button-v2"
        onClick={e => {
          e.stopPropagation();
          handleSaveRequest();
        }}
        style={{
          textTransform: "none",
          float: "right",
          marginRight: 50,
          marginTop: 15
        }}
      >
        SAVE
      </Button>
    </div>
  );
};

class UserEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModal: false
    };

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (param, value) {
      this.handleInputChanges.apply(this, [param, value]);
    }, 500);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
  }

  componentWillMount() {
    /*
    When the user accesses the UserEditor from the UserBrowser, user data
    will be injected as a prop. In that scenario, notify the application 
    that the editor has received initial data. 
    
    We have to check for whether or not userData._id is defined because when
    life cycle method is called on a deep link the user data has not yet 
    been retrieved from the database, and is therefore unavailable to this 
    component. So only notify the application in this life cycle method 
    that the editor has received initial data if it's actually present at 
    this point!
    */
    if (this.props.userData._id) {
      this.props.handleEditorDataInitialized();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    On a deep link, we use this life cycle method to notify the application
    that the editor has received initial data. Once the user data has been 
    written to Redux state on a deep link, this component's props are updated.
    In that scenario, the first time this method is executed, the previous 
    userData._id property will be undefined and the current userData._id 
    property will have a value. Take that opportunity to notify the application
    that the editor has received initial data.
    */
    if (
      prevProps.userData._id === undefined &&
      this.props.userData._id !== undefined
    ) {
      this.props.handleEditorDataInitialized();
    }
  }

  render() {
    let headerTitle;
    if (this.props.userData) {
      headerTitle =
        this.props.userData.firstname + " " + this.props.userData.lastname;
    } else {
      headerTitle = "Loading...";
    }

    // If the user has set the first or last name to an empty string,
    // disable the "Save" button to prevent him from saving the changes.
    const isSavedEnabled =
      this.props.userData.firstname === "" ||
      this.props.userData.lastname === ""
        ? false
        : !this.props.userData.isSaved;

    return (
      <div className="user-editor">
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="user-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={"/account/users"}
          handleCloseClicked={this.handleCloseClicked}
          confirmNavigation={this.props.isSaved}
          headerTheme="light"
          title={headerTitle}
          headerMode="extended-extra"
          topBar={topBar(isSavedEnabled, this.props.handleSaveRequest)}
        >
          <div className="user-editor-container">
            <div className="tab-form-container">
              <div className="tab-column column-general column-right-border">
                {this.props.form ? (
                  <List
                    styleName="plain"
                    data={this.props.userData ? this.props.userData : null}
                    items={this.props.form}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(param, value)
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          onConfirm={this.handleRevertActionFromModal}
          onCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
        <Dialog
          content={`An error occurred updating the user. ${this.props.error}`}
          confirmLabel="OK"
          onConfirm={this.props.handleSaveErrorConfirm}
          onCancel={this.props.handleSaveErrorConfirm}
          open={this.props.error === false ? false : true}
          title="Error Saving Changes"
        />
      </div>
    );
  }

  handleInputChanges(param, value) {
    if (this.props.userData[param] !== value) {
      this.props.handleInputChange(param, value);
    }
  }

  handleCloseClicked() {
    if (!this.props.userData.isSaved) {
      this.openDisplayRevertCancel();
    } else {
      this.props.handleClose();
    }
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  handleRevertActionFromModal() {
    this.closeDisplayRevertCancel();
    this.props.handleRevertChanges();
    this.props.handleClose();
  }
}

UserEditor.defaultProps = {};

UserEditor.propTypes = {};

function mapStateToProps(state, ownProps) {
  return userEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { userID } = ownProps.match.params;
  return Object.assign({}, stateProps, {
    handleEditorDataInitialized: () => {
      dispatchProps.handleEditorDataInitialized(userID);
    },

    // Copied from CollectionItemEditor. Assess necessity of
    // all values passed to the action creator.
    handleInputChange: (property, value) => {
      dispatchProps.handleInputChange(userID, property, value);
    },

    handleSaveRequest: () => {
      dispatchProps.handleSaveRequest(userID);
    },

    handleSaveErrorConfirm: () => {
      dispatchProps.handleSaveErrorConfirm();
    },

    handleRevertChanges: () => {
      dispatchProps.handleRevertChanges();
    },

    handleClose: () => {
      ownProps.history.push("/account/users");
    }
  });
}

export default withRouter(
  connect(mapStateToProps, userActions, mergeProps)(UserEditor)
);
