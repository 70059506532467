import React from "react";
import PropTypes from "prop-types";

import _map from "lodash/map";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  ModeEdit
} from "@material-ui/icons";

import { EnhancedTableHead as TableHead } from "./TableHead";

const getSorting = (order, orderBy) => {
  const desc = (a, b, orderBy) => {
    if (b.columns[orderBy].label < a.columns[orderBy].label) {
      return -1;
    }
    if (b.columns[orderBy].label > a.columns[orderBy].label) {
      return 1;
    }
    return 0;
  };

  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

const convertToArray = (object, order, orderBy) => {
  let array = [];
  _map(object, item => {
    array.push(item);
  });
  return array.sort(getSorting(order, orderBy));
};

export class TableGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: 1, // The first column
      items: convertToArray(this.props.items, "asc", 1),
      selected: []
    };
    this.clickHeaderHandler = this.clickHeaderHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleRowSelectionChange = this.handleRowSelectionChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: convertToArray(
        nextProps.items,
        this.state.order,
        this.state.orderBy
      )
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sortByColumn !== this.state.sortByColumn ||
      prevState.sortByTypeKey !== this.state.sortByTypeKey
    ) {
      this.setState({
        items: convertToArray(
          this.props.items,
          this.state.order,
          this.state.orderBy
        )
      });
    }
  }

  clickHeaderHandler(columnName) {
    const newTypeKey =
      this.state.sortByTypeKey === 2 ? 0 : this.state.sortByTypeKey + 1;
    this.setState({
      sortByTypeKey: newTypeKey,
      sortByColumn: columnName
    });
  }

  isSelected(index) {
    return this.state.selected.indexOf(index) !== -1;
  }

  handleRowSelectionChange = () => {
    if (this.props.handleRowSelectionFunc) {
      const { items, selected } = this.state;
      const selectedItems = items.reduce((accumulator, item, index) => {
        if (selected.includes(index)) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);

      this.props.handleRowSelectionFunc(selected, selectedItems);
    }
  };

  /**
   *
   * @param {*} event - not used.
   * @param {number} id - 0...x-1 where x is the number of items.
   */
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    // selectedRows: number[];
    let selectedRows = [];

    if (selectedIndex === -1) {
      selectedRows = selectedRows.concat(selected, id);
    } else if (selectedIndex === 0) {
      selectedRows = selectedRows.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      selectedRows = selectedRows.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      selectedRows = selectedRows.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: selectedRows }, this.handleRowSelectionChange);
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(
        state => (
          {
            selected: this.state.items.map((n, index) => index)
          },
          this.handleRowSelectionChange
        )
      );
      return;
    }
    this.setState({ selected: [] }, this.handleRowSelectionChange);
  };

  handleRequestSort = (event, orderBy) => {
    let order = "desc";

    if (this.state.orderBy === orderBy && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  render() {
    const { columns, idNode, editAction, viewAction } = this.props;

    return (
      <div className="tablegrid-container">
        <Table>
          <TableHead
            columns={columns}
            numSelected={this.state.selected.length}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onSelectAllClick={this.handleSelectAllClick}
            onRequestSort={this.handleRequestSort}
            rowCount={this.state.items.length}
          />

          <TableBody>
            {this.state.items
              .sort(getSorting(this.state.order, this.state.orderBy))
              .map((item, key) => {
                const isSelected = this.isSelected(key);
                const isDisabled =
                  item.selectable !== undefined ? !item.selectable : false;
                return (
                  <TableRow
                    key={key}
                    onClick={
                      !isDisabled ? event => this.handleClick(event, key) : null
                    }
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} disabled={isDisabled} />
                    </TableCell>
                    {_map(item.columns, column => {
                      return (
                        <TableCell key={column[idNode]}>
                          {column.label &&
                          column.label.substring(0, 4) === "rgba" ? (
                            <div
                              className="color-value"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="color-chip"
                                style={{
                                  marginRight: 6,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: column.label,
                                  boxShadow: "1px 1px 5px grey"
                                }}
                              ></div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: column.label
                                }}
                              />
                            </div>
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{ __html: column.label }}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                    {viewAction && (
                      <TableCell style={{ textAlign: "right" }}>
                        <IconButton onClick={() => viewAction(item[idNode])}>
                          <ArrowForward />
                        </IconButton>
                      </TableCell>
                    )}
                    {typeof editAction !== "undefined" ? (
                      <TableCell style={{ textAlign: "right" }}>
                        <IconButton onClick={() => editAction(item[idNode])}>
                          <ModeEdit />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

TableGrid.defaultProps = {
  multiSelectable: false
};

TableGrid.propTypes = {
  idNode: PropTypes.string,
  editAction: PropTypes.func,
  viewAction: PropTypes.func,
  handleRowSelectionFunc: PropTypes.func,
  multiSelectable: PropTypes.bool,
  sortable: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  //Styles
  headerStyle: PropTypes.object
};
