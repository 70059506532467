import React, { useEffect, useState } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";

export const PartialDate = ({
  name,
  id,
  hasLabel,
  label,
  value: initFormValues,
  required = false,
  disabled = false,
  handleInputChange
}) => {
  const [formValues, setFormValues] = useState();

  const handleChange = (param, value) => {
    if (formValues[param] !== value) {
      const newFormValues = { ...formValues };
      newFormValues[param] = value;
      setFormValues(newFormValues);
      if (handleInputChange) {
        handleInputChange(name, { [param]: value });
      }
    }
  };

  useEffect(() => {
    const newFormValues = {
      year: initFormValues
        ? initFormValues.year
          ? initFormValues.year
          : ""
        : "",
      month: initFormValues
        ? initFormValues.month
          ? initFormValues.month
          : ""
        : "",
      day: initFormValues ? (initFormValues.day ? initFormValues.day : "") : "",
      era: initFormValues ? (initFormValues.era ? initFormValues.era : "") : ""
    };
    console.log("newFormValues=", newFormValues);
    setFormValues(newFormValues);
  }, [initFormValues]);

  const months = [
    {
      value: 1,
      label: "Jan"
    },
    {
      value: 2,
      label: "Feb"
    },
    {
      value: 3,
      label: "Mar"
    },
    {
      value: 4,
      label: "Apr"
    },
    {
      value: 5,
      label: "May"
    },
    {
      value: 6,
      label: "Jun"
    },
    {
      value: 7,
      label: "Jul"
    },
    {
      value: 8,
      label: "Aug"
    },
    {
      value: 9,
      label: "Sep"
    },
    {
      value: 10,
      label: "Oct"
    },
    {
      value: 11,
      label: "Nov"
    },
    {
      value: 12,
      label: "Dec"
    }
  ];

  if (!formValues) {
    return null;
  }

  return (
    <div className="cms-input-text partial-date-container" key={id}>
      {hasLabel ? <div className="label-container">{label}</div> : null}
      <div className="inputs-container">
        <TextField
          type="text"
          className={`cms-input-text ${
            required &&
            (formValues === null ||
              formValues === undefined ||
              formValues === "")
              ? "invalid-input"
              : ""
          }`}
          id={id}
          disabled={disabled}
          name={name + "Year"}
          value={formValues ? formValues.year : ""}
          label="Year"
          fullWidth={true}
          onChange={event => handleChange("year", event.target.value)}
        />

        <FormControl className="select-input" style={{ width: "25%" }}>
          <InputLabel htmlFor="month">Month</InputLabel>
          <Select
            value={formValues ? formValues.month : undefined}
            onChange={event => handleChange("month", event.target.value)}
            className="select-input"
            inputProps={{
              name: "month",
              id: "month"
            }}
          >
            <MenuItem key="null-value" value={null} />
            {months.map((option, index) => (
              <MenuItem key={`${index}-${option.value}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="select-input" style={{ width: "25%" }}>
          <InputLabel htmlFor="day-simple">Day</InputLabel>
          <Select
            value={formValues ? formValues.day : ""}
            onChange={event => handleChange("day", event.target.value)}
            className="select-input"
            autoWidth={true}
            inputProps={{
              name: "day",
              id: "day-simple"
            }}
          >
            <MenuItem key="null-value" value={""} />
            {Array(31)
              .fill()
              .map((_, i) => {
                const day = i + 1;
                return (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        {/*
          The value for this is sorta whacked because originally, era was
          a select list with two values: BCE and CE. BCE had a value of 0
          and CE had a value of 1. To avoid having to change all existing
          data, the checkbox is coded to be checked if era is 0 (essentially
          false) and not checked if era is 1 (essentially true). So when
          the user checks or unchecks the box, store the opposite of the
          checked value as the value for era. Must cast checked value to
          integer with value of 1 or 0 because the checked property is boolean.
          */}
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues ? !formValues.era : false}
              onChange={e => {
                const newValue = e.target.checked ? 0 : 1;
                handleChange("era", newValue);
              }}
              className="cms-checkbox"
              color="primary"
            />
          }
          label="BCE"
        />
      </div>
      <div style={{ clear: "both" }} />
    </div>
  );
};
