import React from "react";
import { connect } from "react-redux";

import { CardBrowser } from "components";
import ContentContainer from "modules/ContentContainer";
import { fetchThemeEditorData } from "actions/themeEditor";
import { getThemeBrowserProps } from "reducers/ui/themeEditor";
import ThemeCard from "./ThemeCard";
import "./ThemeBrowser.scss";

// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// import _camelCase from 'lodash/camelCase';
// import _values from 'lodash/values';

export class ThemeBrowser extends React.Component {
  // we think all use cases are handled with the unmount of editor
  // componentWillMount(){
  // if(this.props.themeList){
  // this.props.dispatch(resetEditor());
  // }
  // }
  componentDidMount() {
    if (!this.props.themeList) {
      // is the data present? if not dispatch to get it
      this.props.dispatch(fetchThemeEditorData());
    }
  }

  render() {
    // const {backAction, header, sidebar} = this.props;
    const { themeList, params, slugBase } = this.props;

    return (
      <ContentContainer
        isLoading={this.props.isLoading}
        className="content-container"
        sidebarMode={this.props.sidebarMode}
        title="Theme Browser"
      >
        <CardBrowser
          // onCardClick={this.props.copyTheme}
          params={params}
          items={themeList}
          card={ThemeCard}
          slugBase={slugBase}
        />
      </ContentContainer>
    );
  }
}

// i need to be able to get the selected them from the store
// that i can pass down, so doing a connect here...
// themeIndex : activeThemeIndex

function mapStateToProps(state, ownProps) {
  return getThemeBrowserProps(state, ownProps);
}

export default connect(mapStateToProps)(ThemeBrowser);
