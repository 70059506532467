import React from "react";

import Logger from "utils/logger";
import { List } from "components";
import Sidebar from "modules/Sidebar/Sidebar";
import "./ThemeSidebar.scss";

const ActionBar = props => {
  // gray bar to display if theme is active
  const statusClass = props.isActive
    ? "theme-status-indicator active"
    : "theme-status-indicator in-active";
  return (
    <div className={statusClass}>
      {props.isActive ? <i className="material-icons">&#xE86C;</i> : null}
      <span className="status-text">
        {props.isActive ? "Active" : "In-Active"}
      </span>
    </div>
  );
};

class ThemeEditorSidebar extends React.Component {
  render() {
    const {
      navigation,
      themeData,
      slugBase,
      updateTheme,
      revertTheme,
      isSaving,
      isSaved
    } = this.props;
    Logger.debug({ slugBase }, "[ThemeEditorSidebar] slugBase");
    return (
      <Sidebar
        //isSaved={themeData.isSaved} //if the buttons are seen
        showButtons={themeData ? true : false} //can they save from the screen
        disabled={isSaving || isSaved}
        goBackAction="/themes"
        title={themeData.label}
        scrollY={true}
        handleSave={updateTheme} // action passed down to button to handle click
        handleRevert={revertTheme} // action passed down to button to handle click
        isSaving={isSaving} //buttons disablaled show diff label
        actionBar={
          themeData ? (
            <ActionBar type="title" isActive={themeData.isActive} />
          ) : null
        }
      >
        {themeData ? <List items={navigation} slugBase={slugBase} /> : null}
      </Sidebar>
    );
  }
}

export default ThemeEditorSidebar;
