import React, { Component } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

/**
 * SidebarNav
 *
 * @type                    Component
 * @description             Navigation item for the sidebar
 *
 * @TODO                    an iconElementRight can be added to the right, currently commented
 * @props
 *  goBackAction {string}   Path for the Back Arrow icon to link
 */
const SidebarNav = ({ goBackAction }) => {
  const history = useHistory();
  return (
    <div
      className="sidebar-nav"
      style={{ display: goBackAction ? "block" : "none" }}
    >
      {goBackAction ? (
        <IconButton
          style={{ paddingTop: 20 }}
          // disableTouchRipple={true}
          // className="back-arrow"
          // containerElement={<Link to={this.props.goBackAction} />}
          onClick={() => history.push(goBackAction)}
        >
          <ArrowBack viewBox="4 0 24 24" />
        </IconButton>
      ) : null}
    </div>
  );
};

SidebarNav.defaultProps = {};

SidebarNav.propTypes = {
  goBackAction: PropTypes.string
};

export default SidebarNav;
