import React, { Component } from "react";
import PropTypes from "prop-types";

import _map from "lodash/map";
import _isUndefined from "lodash/isUndefined";
import _kebabCase from "lodash/kebabCase";

import { AssetContainer } from "../AssetContainer";
import { SequenceContainer } from "../SequenceContainer";
import { TextContainer } from "../TextContainer";

import "./Slideshow.scss";

export class SlideshowScene extends Component {
  constructor(props) {
    super(props);
    this.handleSelectBack = this.handleSelectBack.bind(this);

    this.textElement = null;
    this.state = {
      textScroll: false,
      uiChecked: false
    };
  }

  // USED by CMS
  handleSelectBack(event) {
    event.preventDefault();
    event.stopPropagation();
    if (typeof this.props.backClickHandler !== "undefined") {
      this.props.backClickHandler(null);
    }
  }

  // this function is used to pass down a prop, that inside the component we set a ref on the DOM element that holds the text

  /**
  this function will look at text of certain layouts 
  which start default to being centered
  but if the text is bigger than the area, the layout will change 
  to account for scrolling, making the text top aligned and 
  potentially fix image
  depending on the layout, this is just a method to add a class so we know text should scroll
  since it can be called multiple times we want a means to exit if check is no longer needed
  a. we already set the layout class addendum
  b. the layout itself has no text
  c. layout has text but is not centered by default
  */
  setUIAdjustments(isNewScene) {
    if (this.state.uiChecked === true && !isNewScene) return;
    // we are going to call the poster frame correcotor from here since it only need to be called once
    // and if needed we can leverage the switch, but i think all layouts will leverage
    //this.setPosterFrameWrapper();

    this.determineTextScroll(isNewScene); // <- coudl change the state if a condition is met

    // the uiChecked will still need to be set even if no scroll is needed, we set as a default and an else on if condition
  }

  // TODO: could this be moved to the text continer?
  determineTextScroll(isNewScene) {
    // switch on layout to detemine if calculation is needed
    switch (this.props.layout) {
      case "layout-2-left":
      case "layout-2-right":
        if (
          this.textElement //&&
          //this.textElement.current
        ) {
          // target the parent column....
          const column = this.textElement.parentNode;
          const parentHeight = column ? column.clientHeight : 0;
          const parentScrollHeight = column ? column.scrollHeight : 0;
          const scrollHeight = this.textElement.scrollHeight;
          const needsToScroll =
            column && parentHeight > 0 && parentScrollHeight > parentHeight
              ? true
              : false;

          // only set if its changed
          if (needsToScroll !== this.state.textScroll) {
            // we put the values in state to determine classes to apply
            this.setState({
              textScroll: needsToScroll,
              uiChecked: true
            });
          } else {
            this.setState({ uiChecked: true });
          }
        }
        break;
      default:
        this.setState({ uiChecked: true });
        break;
    }
  }

  render() {
    // see if these can be replaced with the containter components used by DEST

    const { props } = this;
    const {
      useContext,
      layout,
      styles,
      elements,
      backgroundColor,
      template,
      layoutElements,
      playAsModal,
      isEditMode,
      activeEditingElement,
      textBoxStyle,
      onContentAreaClick,
      triggerEditFunc,
      assetSelectorFunc,
      storyProperties
    } = this.props;
    //if(!elements) elements = this.props; // FOR CMS
    const { background } = elements || this.props.background || {}; // only need background at this level

    // loop through template and populate dom elements in the contents array
    let contents = [];

    template.map((columns, index) => {
      let columnElements = []; //
      //loop through value array and
      columns.map((elementID, index2) => {
        // its possible the elementID contains a pipe that gives an option
        // NOTE: this is a quick fragile solution....
        // it assumes that one of the ids will be present while the other is NOT
        const elementIDOptions = elementID.split("|");
        let elementProps = elements[elementIDOptions[0]];
        elementID = elementIDOptions[0];
        // may need to be a loop later
        if (!elementProps) {
          elementProps = elements[elementIDOptions[1]];
          elementID = elementIDOptions[1];
        }

        //target from the scene's elements
        const showAssetText = elementProps.isCaptionVisible
          ? elementProps.isCaptionVisible
          : layoutElements[elementID]
          ? layoutElements[elementID].showCaption
          : false;
        const isActiveEditingElement = activeEditingElement === elementProps;
        //const elementStyle = props[elementID + "Style"]; // i dont think this is used anymore, any inline styles will be in styles node in the element
        //look at scene first, then the individual prop
        const videoPlaybackModal = !_isUndefined(playAsModal)
          ? playAsModal
          : !_isUndefined(elementProps.playAsModal)
          ? elementProps.playAsModal
          : true;
        let Element;
        if (elementID.indexOf("asset") >= 0) {
          // single asset
          Element = (
            <AssetContainer
              widthLimit={useContext === "scene-browser" ? 300 : null}
              heightLimit={useContext === "scene-browser" ? 300 : null}
              key={`${index}-${index2}`}
              layout={layout}
              storyLayoutConfig={storyProperties.layoutConfig}
              className="asset-content asset"
              {...elementProps}
              elementID={`${elementID}`}
              showAssetText={showAssetText}
              isEditMode={isEditMode}
              isActiveEditingElement={isActiveEditingElement}
              triggerEditFunc={triggerEditFunc} // used by processEditClick
              assetSelectorFunc={assetSelectorFunc}
              playAsModal={videoPlaybackModal} //allow for this to over ride from a scene prop
              triggerModalFunc={value => this.openModal(value)} // only used for video assets and only SOME of them, not sure we pass everytime...
              controls
              poster
            />
          );
        } else if (elementID.indexOf("sequence") >= 0) {
          //
          Element = (
            <SequenceContainer
              key={`${index}-${index2}`}
              layout={layout}
              className="asset-content asset"
              {...elementProps}
              elementID={`${elementID}`}
              showAssetText={showAssetText}
              isEditMode={isEditMode}
              isActiveEditingElement={isActiveEditingElement}
              triggerEditFunc={triggerEditFunc} // used by processEditClick
              assetSelectorFunc={assetSelectorFunc}
              playAsModal={videoPlaybackModal} //allow for this to over ride from a scene prop
              triggerModalFunc={value => this.openModal(value)} // only used for video assets and only SOME of them, not sure we pass everytime...
              controls
              poster
            />
          );
        } else {
          //elementStyle={elementStyle}
          Element = (
            <TextContainer
              key={`${index}-${index2}`}
              layout={layout}
              className="text-content"
              {...elementProps}
              elementID={`${elementID}`}
              stylesString="" //TODO figure out what CMS needs for this
              textBoxStyle={textBoxStyle}
              isEditMode={isEditMode}
              isActiveEditingElement={isActiveEditingElement}
              triggerEditFunc={triggerEditFunc} // used by processEditClick
              textRef={el => (this.textElement = el)} //for now we only will have the last text element to add as a ref
            />
          );
        }

        columnElements.push(Element); //add element to be used in column
      }); // --end inner map

      const contentAreaStyleSettings =
        styles && styles.contentAreas && styles.contentAreas[`area${index + 1}`]
          ? styles.contentAreas[`area${index + 1}`]
          : {};

      const contentAreaClassNames = Object.keys(contentAreaStyleSettings)
        .reduce((accumulator, propName) => {
          if (propName === "classNames") {
            return accumulator + `${contentAreaStyleSettings.classNames} `;
          }
          return (
            accumulator +
            `sc-${_kebabCase(propName)}-${contentAreaStyleSettings[propName]} `
          );
        }, "")
        .trim();

      let column = (
        <div
          className={`col content-area content-area-${
            index + 1
          } ${contentAreaClassNames}`}
          key={`${index}`}
          ref={el => (this["col" + index] = el)}
        >
          {columnElements}
          {/* 
          Shadow div to create layer over column/content-area to
          enable user to click to edit content area style settings.
          */}
          <div
            className="sc-edit sc-edit-content-area"
            onClick={() => onContentAreaClick(index + 1)}
          />
        </div>
      );
      contents.push(column);
    }); // -- end outer map

    const classAddendum = this.state.textScroll === true ? "scroll-text" : ""; // certain layouts may also have addendum to fix image

    // build out the content
    const content = (
      <section className={`content-container ${layout} ${classAddendum}`}>
        {contents}
        {/* 
        Shadow div to create layer over content container to enable
        user to click to edit content container style settings.
        */}
        <div
          className="sc-edit sc-edit-content-container"
          // onClick={}
        />
      </section>
    );

    // NOTE in dest background color is set using the theme css and targets the #app dom element
    // only need to inject the style when in the CMS
    const { activeElementID, openModal } = this.state || {};
    const activeVideoElement =
      activeElementID && elements && elements[activeElementID]
        ? elements[activeElementID]
        : {}; // this is the video element to display in the modal this.state &&

    const regExResults = /sc\-tm\-(.*?)\-.*/.exec(layout);
    const layoutClass = regExResults ? `sc-layout-${regExResults[1]}` : "";

    const sceneStyleSettings = styles && styles.scene ? styles.scene : {};

    const sceneClassNames = Object.keys(sceneStyleSettings)
      .reduce((accumulator, propName) => {
        if (propName === "classNames") {
          return accumulator + `${sceneStyleSettings.classNames} `;
        }
        return (
          accumulator +
          `sc-${_kebabCase(propName)}-${sceneStyleSettings[propName]} `
        );
      }, "")
      .trim();

    const sceneBackgroundOverrideClasses = `${
      background.color ? "custom-bg-color" : ""
    } ${background.assetID ? "custom-bg-asset" : ""}`;

    const themeBackgroundClassName =
      background.classes && background.classes.themeBackground
        ? `theme-bg-${background.classes.themeBackground}`
        : "";

    let backgroundContainerClasses = "";
    if (background.classes) {
      const backgroundAnimationClassName =
        background.classes && background.classes.backgroundAnimation
          ? `animate ${background.classes.backgroundAnimation} ${background.classes.backgroundAnimationSpeed}`
          : "";
      backgroundContainerClasses = `${background.classes.backgroundFilter} ${background.classes.backgroundEffect} ${backgroundAnimationClassName}`;
    }

    return (
      <article
        className={`slideshow-slide scene ${layout} ${layoutClass} ${classAddendum} ${sceneClassNames} ${sceneBackgroundOverrideClasses} ${themeBackgroundClassName}`}
        onClick={this.handleSelectBack}
      >
        {content}
        {/* -----------------> BACKGROUND ASSET <------------------- */}
        {
          <div className={`background-container ${backgroundContainerClasses}`}>
            <div
              className="background-color"
              style={
                backgroundColor
                  ? { backgroundColor: backgroundColor }
                  : background.color
                  ? { backgroundColor: background.color }
                  : {}
              }
            />
            <AssetContainer
              className="background-image asset"
              {...background}
            />
            <div className="background-overlay-scrim" />
          </div>
        }

        {/* 
        Shadow div to create layer over article/scene 
        to enable user to click to edit scene style settings.
        */}
        <div
          className="sc-edit sc-edit-scene"
          // onClick={}
        />
      </article>
    );
  }

  componentDidMount() {
    this.setUIAdjustments(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const isNewScene = prevProps._id !== this.props._id ? true : false;
    this.setUIAdjustments(isNewScene);
  }
}

SlideshowScene.defaultProps = {
  assetFit: "cover",
  descriptionStyle: {
    fontFamily: " 'Open Sans', sans-serif",
    fontSize: 14,
    color: "white",
    lineHeight: 1.5
  },
  titleStyle: {
    fontFamily: " 'Open Sans', sans-serif",
    fontSize: 20,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "white",
    paddingBottom: 16
  },
  layout: "bottom-left",
  isEditMode: false
};

SlideshowScene.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types

  // Elements
  asset1: PropTypes.object,
  asset2: PropTypes.object,
  asset3: PropTypes.object,
  background: PropTypes.object,
  title: PropTypes.string, // Title for the asset
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  description: PropTypes.string,
  styles: PropTypes.object, // Global styles passed selecting by elements (h1, h2, h3, caption-1, body-2, etc)

  //Func
  assetSelectorFunc: PropTypes.func, // Function that triggers the asset selector

  text1Style: PropTypes.object,
  assetFit: PropTypes.string, // How the asset fits in the container: options (fill, cover, contain, scale-down, none), is this a per scene or per item?
  active: PropTypes.object, // holds active Ids and  URL to got for Close,Previous, Next
  activeItem: PropTypes.object, // data specific to the active asset
  activeEditingElement: PropTypes.string, // Element being edited when working from the CMS
  backClickHandler: PropTypes.func, // Function to execute when clicking on the back
  backgroundColor: PropTypes.string, // Background color for the viewer (for example if the image is "contained")
  descriptionStyle: PropTypes.object,
  isEditMode: PropTypes.bool, // Flag to indicate that we are in the CMS editing the scene
  triggerEditFunc: PropTypes.func, // Function that gets triggered when editing a scene and clicking in an element
  layout: PropTypes.string, // Ie.: three-columns-image-text, 2-images-small-2-text
  textBoxStyle: PropTypes.object, // Styles for the box containing text
  titleStyle: PropTypes.object
};
