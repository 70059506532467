import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Help } from "@material-ui/icons";

import * as quizActions from "actions/quizEditor";
import "../QuizSidebar.scss";
import styles from "../QuizSidebar.scss";

class QuestionItem extends React.Component {
  render() {
    return (
      <div
        className={`question-item ${
          this.props.active === this.props.id ? "active" : ""
        }`}
        onClick={() => this.props.copyQuestion(this.props)}
      >
        <Help color={styles.itemColor} className="question-icon" />
        <div className="question-title">{this.props.title}</div>
      </div>
    );
  }
}

QuestionItem.defaultProps = {
  active: null
};

QuestionItem.propTypes = {
  title: PropTypes.string,
  active: PropTypes.string // Id of the active question being edited
};

function mapStateToProps(state, ownProps) {
  if (typeof state.toJS().quizEditor.editingQuestion !== "undefined") {
    return { ...ownProps, active: state.toJS().quizEditor.editingQuestion };
  }
  return ownProps;
}

export default connect(mapStateToProps, quizActions)(QuestionItem);
