import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import _debounce from "lodash/debounce";
import _map from "lodash/map";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";

import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Tabs,
  Tab
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import Logger from "utils/logger";
import { CardBrowser, Dialog, List } from "components";
import ContentContainer from "modules/ContentContainer";
import { mapPlaceEditorMapStateToProps } from "mapToProps/sceneEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";
import config from "config";

export class MapPlaceEditor extends React.Component {
  constructor(props) {
    super(props);

    const data = this.props.activeEditingSubScene.elements;

    this.state = {
      tab: "details",
      isDirty: this.props.activeEditingSubScene.isSaved
        ? !this.props.activeEditingSubScene.isSaved
        : false,
      data,
      flying: false,
      movingMap: false,
      confirmModal: false,
      activeLanguage: this.props.langID,
      tempLink: null
    };

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (baseParam, param, value) {
      this.handleInputChanges.apply(this, [baseParam, param, value]);
    }, 500);
    this.handleMapMove = this.handleMapMove.bind(this);
    const this2 = this;
    this.handleMapMoveDebounced = _debounce(() => {
      this2.handleMapMove.apply(this2, [this2.state.map]);
    }, 500);

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  handleRevertActionFromModal() {
    this.closeDisplayRevertCancel();
    this.props.revertScene();
    this.proceedToLink(this.state.tempLink);
    this.setState({ tempLink: null });
  }

  componentWillReceiveProps(nextProps) {
    const data = _cloneDeep(nextProps.activeEditingSubScene.elements);
    if (nextProps.activeEditingSubScene.elements) {
      this.setState({ data });
      if (
        nextProps.activeEditingSubScene.elements.place &&
        !_isEqual(
          nextProps.activeEditingSubScene.elements.place.config,
          this.state.data.place.config
        )
      ) {
        const map = this.state.map;
        const mapConfig = nextProps.activeEditingSubScene.elements.place.config;
        const getParsedCoord = (value, def) => {
          const defaultVal = typeof def === "undefined" ? 0 : def;
          if (typeof value === "undefined" || value === null) return defaultVal;
          return parseFloat(value);
        };
        if (!this.state.movingMap) {
          if (mapConfig.bounds) {
            map.fitBounds(mapConfig.bounds);
          } else {
            map.flyTo({
              center: [
                getParsedCoord(mapConfig.longitude),
                getParsedCoord(mapConfig.latitude)
              ],
              pitch: [getParsedCoord(mapConfig.pitch)],
              bearing: [getParsedCoord(mapConfig.bearing)],
              zoom: [getParsedCoord(mapConfig.zoomLevel, 1)]
            });
          }

          map.fire("flystart");
        } else {
          this.setState({ movingMap: false });
        }
      }
    }
    if (typeof nextProps.activeEditingSubScene.isSaved !== "undefined") {
      this.setState({
        isDirty: !nextProps.activeEditingSubScene.isSaved
      });

      this.props.storySetIsSaved(nextProps.activeEditingSubScene.isSaved);
    }
  }

  proceedToLink(link) {
    this.setState({ isDirty: false }, () => {
      this.props.storySetIsSaved(true);
      this.props.history.push(link);
    });
  }

  componentDidMount() {
    /*
    Check for whether or not place is a property on the data object
    since this life cycle method is executed on a deep link to the 
    Map Place Gallery Image Settings Editor, and in that scenario,
    there won't be a place object on state.data.
    */
    if (this.state.data && this.state.data.place) {
      const mapboxgl = require("mapbox-gl");
      const mapboxGeocoder = require("mapbox-gl-geocoder");
      // mapboxgl.accessToken =
      //   "pk.eyJ1Ijoib21hcmpjYW1vIiwiYSI6ImNqZHZzYXp2ZTBnOGgyeHM0cmhyY252ZWQifQ.V7C9Jz_k4zAZ9yxWK4mWhg";
      mapboxgl.accessToken = this.props.activeEditingScene.elements.basemap
        .accessToken
        ? this.props.activeEditingScene.elements.basemap.accessToken
        : config("mapboxAccessToken");

      const mapConfig =
        this.state.data && this.state.data.place
          ? this.state.data.place.config
          : null;
      const baseMapConfig = this.props.activeEditingScene
        ? this.props.activeEditingScene.elements.basemap.config
        : null;

      const getParsedCoord = (value, def) => {
        const defaultVal = typeof def === "undefined" ? 0 : def;
        if (typeof value === "undefined" || value === null) return defaultVal;
        return parseFloat(value);
      };

      const map = new mapboxgl.Map({
        style: baseMapConfig.style, // "mapbox://styles/mapbox/streets-v10",
        container: this.mapContainer,
        center: mapConfig
          ? [
              getParsedCoord(mapConfig.longitude),
              getParsedCoord(mapConfig.latitude)
            ]
          : [
              getParsedCoord(baseMapConfig.longitude),
              getParsedCoord(baseMapConfig.latitude)
            ],
        pitch: mapConfig
          ? [getParsedCoord(mapConfig.pitch)]
          : [getParsedCoord(baseMapConfig.pitch)],
        bearing: mapConfig
          ? [getParsedCoord(mapConfig.bearing)]
          : [getParsedCoord(baseMapConfig.bearing)],
        zoom: mapConfig
          ? [getParsedCoord(mapConfig.zoomLevel, 1)]
          : [getParsedCoord(baseMapConfig.zoomLevel, 1)]
      });

      if (mapConfig.bounds) {
        map.fitBounds(mapConfig.bounds);
      }

      map.addControl(
        new mapboxGeocoder({
          accessToken: mapboxgl.accessToken
        })
      );
      map.addControl(new mapboxgl.NavigationControl());
      const this2 = this;

      if (baseMapConfig.layers) {
        map.on("load", () => {
          baseMapConfig.layers.forEach(layer => {
            const { type, paint, beforeId, source } = layer;
            map.addSource(source.id, {
              type: source.config.type,
              url: `https://res.cloudinary.com/${config(
                "cloudName"
              )}/image/upload/${source.config.cloudPublicId}`,
              coordinates: source.config.coordinates
            });
            map.addLayer(
              {
                id: `${source.id}-layer`,
                type,
                source: source.id,
                paint
              },
              beforeId
            );
          });
        });
      }

      map.on("movestart", () => {
        this2.setState({ movingMap: true });
      });
      map.on("moveend", () => {
        if (this.state.flying) {
          map.fire("flyend");
        } else {
          this2.handleMapMoveDebounced();
        }
      });

      map.on("flystart", () => {
        this2.setState({ flying: true, movingMap: false });
      });
      map.on("flyend", () => {
        this2.setState({ flying: false });
      });

      this.setState({ map });
    }
  }

  handleMapMove(map) {
    const getParsedCoord = (value, def) => {
      const defaultVal = typeof def === "undefined" ? 0 : def;
      if (typeof value === "undefined" || value === null) return defaultVal;
      return parseFloat(value);
    };
    const { lng, lat } = map.getCenter();
    Logger.debug(
      { bounds: map.getBounds() },
      "[MAP PLACE EDITOR] - handleMapMove"
    );

    const bounds = map.getBounds();
    const parsedBounds = [
      [bounds._sw.lng, bounds._sw.lat],
      [bounds._ne.lng, bounds._ne.lat]
    ];
    this.setState(
      {
        data: {
          ...this.state.data,
          place: {
            ...this.state.data.place,
            config: {
              ...this.state.data.place.config,
              latitude: getParsedCoord(lat),
              longitude: getParsedCoord(lng),
              bearing: getParsedCoord(map.getBearing()),
              pitch: getParsedCoord(map.getPitch()),
              zoomLevel: getParsedCoord(map.getZoom(), 1),
              bounds: parsedBounds
            }
          }
        },
        isDirty: true
      },
      () => this.props.storySetIsSaved(false)
    );
    this.props.handleInputChange(
      "place",
      {
        ...this.state.data.place.config,
        latitude: getParsedCoord(lat),
        bearing: getParsedCoord(map.getBearing()),
        pitch: getParsedCoord(map.getPitch()),
        longitude: getParsedCoord(lng),
        zoomLevel: getParsedCoord(map.getZoom(), 1),
        bounds: parsedBounds
      },
      "geography"
    );
  }

  render() {
    const {
      activeEditingSubScene,
      activeSlideAssetCloudId,
      galleryData,
      cardBrowserConfig
    } = this.props;

    const tabState = this.state.tab;
    const topBar = (
      <div className="map-place-editor-topbar">
        <div className="scene-navigation-component">
          {this.props.goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("prev")}
            >
              <ChevronLeft viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {this.props.curSlideNo ? this.props.curSlideNo : null}
            &nbsp; of &nbsp;
            {this.props.totalSlideNo ? this.props.totalSlideNo : null}
          </div>
          {this.props.goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("next")}
            >
              <ChevronRight viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!this.state.isDirty}
          className="new-asset-button-v2"
          onClick={e => {
            e.stopPropagation();
            this.handleSave();
          }}
          style={{
            textTransform: "none",
            float: "right",
            marginRight: 50,
            marginTop: 15
          }}
        >
          SAVE
        </Button>

        <Select
          className="language-select"
          value={this.state.activeLanguage}
          onChange={this.handleLanguageChange}
        >
          {this.props.languages &&
            _map(this.props.languages, item => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
        </Select>

        {this.state.tab === "gallery" ? (
          this.props.cardBrowserConfig.selected &&
          this.props.cardBrowserConfig.selected.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              className="new-asset-button-v2"
              onClick={e => {
                e.stopPropagation();
                this.openDeleteModal();
              }}
              style={{
                textTransform: "none",
                float: "right",
                marginRight: 50,
                marginTop: 15
              }}
            >
              DELETE
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="new-asset-button-v2"
              onClick={e => {
                e.stopPropagation();
                this.props.openAssetSelectorFunc(null, true, {
                  tabState
                });
              }}
              style={{
                textTransform: "none",
                float: "right",
                marginRight: 50,
                marginTop: 15
              }}
            >
              ADD NEW
            </Button>
          )
        ) : null}
      </div>
    );

    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)",
        zIndex: 0
      }
    };

    const tabs = (
      <Tabs value={this.state.tab} onChange={this.handleTabChange}>
        <Tab label="DETAILS" value="details" {...tabStyle} />
        <Tab label="GEOGRAPHY" value="geography" {...tabStyle} />
        <Tab label="GALLERY" value="gallery" {...tabStyle} />
      </Tabs>
    );

    return (
      <div className="map-place-editor">
        <Helmet>
          <title>Map Place Editor</title>
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v2.2.0/mapbox-gl-geocoder.css"
            type="text/css"
          />
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="quiz-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          handleCloseClicked={this.handleCloseClicked}
          headerTheme="light"
          confirmNavigation={this.state.isDirty}
          title={
            activeEditingSubScene.elements
              ? activeEditingSubScene.elements.title
              : "Loading..."
          }
          headerMode="extended-extra"
          topBar={topBar}
          headerTabs={tabs}
        >
          <div className="tab-form-container map-place-editor-container">
            {/* General Details Editor */}
            {this.state.tab === "details" && (
              <div
                className="details-form-container"
                // style={{
                //   visibility:
                //     this.state.tab === "details" ? "visible" : "hidden"
                // }}
              >
                {this.props.forms ? (
                  <div className="tab-column column-general column-right-border">
                    <List
                      styleName="plain"
                      data={this.state.data ? this.state.data : null}
                      items={this.props.forms.details.general}
                      handleInputChange={(param, value) =>
                        this.debounceInputChange("general", param, value)
                      }
                    />
                  </div>
                ) : null}
                <div className="tab-column column-description column-right-border">
                  {this.props.forms ? (
                    <List
                      styleName="plain"
                      data={this.state.data ? this.state.data : null}
                      items={this.props.forms.details.extendedDescription}
                      handleInputChange={(param, value) =>
                        this.debounceInputChange(
                          "extendedDescription",
                          param,
                          value
                        )
                      }
                    />
                  ) : null}
                </div>
                <div className="tab-column column-image">
                  {this.props.forms ? (
                    <List
                      styleName="plain"
                      handleAddClick={this.handleAddAsset}
                      handleDeleteClick={this.handleDeleteAsset}
                      data={this.state.data ? this.state.data : null}
                      items={this.props.forms.details.featuredAsset}
                      handleInputChange={(param, value) =>
                        this.debounceInputChange("featuredAsset", param, value)
                      }
                    />
                  ) : null}
                </div>
              </div>
            )}

            {/* Geography Editor */}
            {this.state.tab === "geography" && (
              <div className="viewer-map-square" />
            )}

            <div
              className="tab-form-container geography-form-container"
              style={{
                visibility:
                  this.state.tab === "geography" ? "visible" : "hidden"
              }}
              ref={el => (this.mapContainer = el)}
            />

            {/* Gallery Editor */}
            {this.state.tab === "gallery" && (
              <div
                className="tab-form-container gallery-form-container"
                // style={{
                //   visibility:
                //   this.state.tab === "gallery" ? "visible" : "hidden"
                // }}
              >
                {Object.keys(galleryData).length > 0 ? (
                  <CardBrowser
                    items={galleryData}
                    minCardSize={cardBrowserConfig.cardSizeMultiplier}
                    enableKeys={false}
                    cardBrowserMaxCardSize={
                      cardBrowserConfig.cardBrowserMaxCardSize
                    }
                    showButtons
                    setCardMargin={20}
                    displayCaption={false}
                    isSequentiable
                    executeOnReSort={this.props.setSubSceneOrder}
                    cardStyle={{
                      backgroundColor: "whitesmoke",
                      titleBackgroundColor: "whitesmoke",
                      titleColor: "black",
                      imageFit: "contain"
                    }}
                    editClickHandler={this.props.editClickHandler}
                    selectHandler={this.props.selectHandler}
                    exclusiveSelectHandler={this.props.exclusiveSelectHandler}
                    backClickHandler={this.props.backClickHandler}
                  />
                ) : null}
              </div>
            )}
          </div>
        </ContentContainer>
        <Dialog
          content='If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          onConfirm={this.handleRevertActionFromModal}
          onCancel={this.closeDisplayRevertCancel}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
        <Dialog
          content='Press "Delete" to remove the selected asset(s) from this gallery.'
          onConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          onCancel={this.closeDeleteModal}
          open={this.state.deleteModalOpen}
          title="Delete Asset(s)?"
        />
      </div>
    );
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });

    this.props.deleteSubScene(this.state.tab);
    this.props.exclusiveSelectHandler(null); // deselect
  }

  handleDeleteAsset() {
    this.props.sceneSetAssetElement("featuredAsset", "");
    this.props.storySetIsSaved(false);
  }

  handleAddAsset() {
    this.props.openAssetSelectorFunc("featuredAsset", false);
  }

  handleAssetSelected(assetID) {
    this.props.sceneSetAssetElement("featuredAsset", assetID);
    this.props.storySetIsSaved(false);
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.storySetIsSaved(true);
    this.props.updateScene();
  }

  handleCloseClicked() {
    this.handleSceneExit(this.props.slugBase);
  }

  handlePrevNextClicked(direction) {
    const targetLink =
      direction === "prev" ? this.props.goPrevURL : this.props.goNextURL;
    this.handleSceneExit(targetLink);
  }

  handleSceneExit(targetLink) {
    if (this.state.isDirty) {
      this.setState({ tempLink: targetLink }, () =>
        this.openDisplayRevertCancel()
      );
    } else {
      this.proceedToLink(targetLink);
    }
  }

  handleInputChanges(baseParam, param, value) {
    /*
    The forms are bound to state.data. Therefore, we have to keep
    state.data up to date with changes made by the user in the forms.
    Otherwise, the user's changes will be overwritten.
    */
    const formData = _cloneDeep(this.state.data);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (formData[param] === "") {
        return;
      }
      value = "";
    }

    // As of 11/5, none of the form elements have values of
    // type object, so commenting this out until we can find
    // evidence that it's relevant.
    // if (typeof value === 'object') {
    //   data[param] = { ...data[param], ...value };
    //   this.setState({ isDirty: true });
    //   // this.props.storySetIsSaved(false);
    // } else

    // If the value of the altered form input doesn't equal the previous
    // form input value, update state.data and trigger an update to the store.
    if (formData[param] !== value) {
      formData[param] = value;
      this.setState({ formData });
      this.props.handleInputChange(param, value, baseParam);
    }
  }

  handleTabChange(event, value) {
    this.setState({
      tab: value
    });
  }
}

MapPlaceEditor.contextTypes = {
  router: PropTypes.object
};

MapPlaceEditor.defaultProps = {};

MapPlaceEditor.propTypes = {
  activeEditingSubScene: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
  type: PropTypes.string,
  openLayoutSelectorFunc: PropTypes.func,
  openAssetSelectorFunc: PropTypes.func,
  openSceneEditorModalFunc: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return mapPlaceEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    langID,
    sceneID,
    slugBase,
    activeEditingSubScene,
    localizedElements,
    dataReadyForSave,
    forms,
    cardBrowserConfig
  } = stateProps;
  const cardBrowserSelector = ["sceneTypes", "map-place", "cardBrowserConfig"]; // immutable path to locate config
  return Object.assign({}, stateProps, {
    handleInputChange: (property, value, baseParam) => {
      const form =
        baseParam === "geography" ? forms[baseParam] : forms.details[baseParam];
      dispatchProps.setElementInput(
        property,
        value,
        langID,
        activeEditingSubScene._id,
        false,
        false,
        localizedElements.includes(property),
        form
      );
    },
    openAssetSelectorFunc: (param, multiple, selectedTab) => {
      const tab = selectedTab ? selectedTab.tabState : null;
      ownProps.openAssetSelectorFunc(param, multiple, tab);
    },

    deleteSubScene: selectedTab => {
      const { selected } = cardBrowserConfig;
      dispatchProps.deleteSubScene(activeEditingSubScene._id, selected, false);
    },

    /*
    Once the necessary work is done in the scene editor map
    state to props method to define activeSlideAssetCloudId
    (the url to the image) and percCropData (crop coordinates)
    for the map place featured image, then define a 
    handleEditClick propery on the primary image List component
    and set it to this.props.handleEditClick.
    */
    handleEditClick: props => {
      ownProps.openImageCropperFunc(props);
    },
    sceneSetAssetElement: (elementID, assetID) => {
      dispatchProps.sceneSetAssetElement(
        activeEditingSubScene._id,
        elementID,
        assetID
      );
    },
    storySetIsSaved: isSaved => {
      dispatchProps.setStoryBrowserIsSaved(isSaved);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    updateScene: () => {
      const sceneType = "map-places";
      const subsceneType = "map-place";

      dispatchProps.updateScene(
        activeEditingSubScene._id,
        dataReadyForSave,
        forms,
        sceneType,
        subsceneType
      );
    },
    revertScene: () => {
      dispatchProps.revertScene();
    },
    backClickHandler: id => {
      dispatchProps.exclusiveSelectToggle(null, cardBrowserSelector);
    },
    selectHandler: id => {
      dispatchProps.selectToggle(id, cardBrowserSelector);
    },
    exclusiveSelectHandler: id => {
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector);
    },
    editClickHandler: (id, coords) => {
      ownProps.openSceneEditorModalFunc(id, coords.left, coords.top);
    },
    setSubSceneOrder: (oldIndex, newIndex) =>
      dispatchProps.setSubSceneOrder(
        activeEditingSubScene._id,
        oldIndex,
        newIndex,
        false
      )
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps, { withRef: true })(
    MapPlaceEditor
  )
);
