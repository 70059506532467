import React, { Component } from "react";
import { Video } from "cloudinary-react";

import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";

import { Dialog } from "@material-ui/core";

import { Icon } from "../Icon";
import { ImageTag } from "../ImageTag";
import { ImagePreloader } from "../ImagePreloader";
import "./SequenceContainer.scss";
/**
* SequenceContainer
this component is an element that can be added alongside other elements of a scene
he holds an array of playlist that are "looped" through
most of the time this will be "run" in a modal
so display a poster
some parts are only needed for cms editing, the cms may also need to pass more info in the props than destination would
<div class="sequence-container" (conditional:isEditMode)>
    <div class="(image|video|audio)-container">
      <img> | <video> | <audio>
      <div class="asset-text" (conditional:showCaption)/>
      
  
*/
const AssetText = props => {
  if (props.show && props.metaConfig) {
    const { title, caption, caption2 } = props.metaConfig;
    const fullCaption = caption + (caption2 || "");
    //console.log("[CaptionArea] props:", props);
    return (
      <section className="asset-text">
        {title ? <h6 dangerouslySetInnerHTML={{ __html: title }} /> : null}
        {caption ? (
          <div dangerouslySetInnerHTML={{ __html: fullCaption }} />
        ) : null}
      </section>
    );
  } else {
    return null;
  }
};

const SpriteContainer = props => {
  //console.log('[SEQUENCE CONTAINER] SpriteArea ', props);
  const { elements, advanceFunc } = props;
  let buttons = [];
  if (!_isEmpty(elements)) {
    // loop and add buttons
    _map(elements, (element, index) => {
      const label = element.text ? element.text.en || element.text : "-";
      //const direction = true:false;
      buttons.push(
        <button
          className="button1 sequence-button"
          key={index}
          onClick={() => advanceFunc(true)}
        >
          {label}
        </button>
      );
    });
  } else {
    // temp
    //buttons.push(<button onClick={()=>advanceFunc(false)}> prev</button>);
    //buttons.push(<button onClick={()=>advanceFunc(true)}> next</button>);
  }

  return <div className="sprite-container">{buttons}</div>;
};

// the asset display..
const SequenceAsset = props => {
  /*
  when the advance is triggered, we look to the asset in the array to build out what should be displayed...
  we may end up having a switch here - or might be able to reference the AssetContainer....
  we will potentially load a different asset type 
  */
  console.log("[SEQUENCE CONTAINER] SequenceAsset ", props);
  const {
    className,
    cloudName,
    cloudPublicId,
    vidType,
    advanceFunc,
    vidRef,
    elements,
    autoAdvance,
    posterSrc
  } = props;
  let { src, vidProps, useCloudinary } = props;
  const vidTypes = ["mp4", "ogg", "webm"];
  //const vidStyle = {height:"94%"}
  // we have to set a src value if not using the cloudinary- so forcing it for now
  // can't seem to get the targeting to work when using the cloudinay
  if (!src) {
    useCloudinary = false;
    src = `${location.protocol}//res.cloudinary.com/${cloudName}/video/upload/v1/${cloudPublicId}`;
  }
  if (autoAdvance === "loop") {
    vidProps = { ...vidProps, loop: true };
  }
  const endFunc = autoAdvance === "next" ? () => advanceFunc(true) : () => {};
  //

  // NOTE if offline, may not need to write all three source tags, since we only download mp4
  return (
    <div className={`${className} asset col`}>
      {useCloudinary ? (
        <Video
          id={"sequence-player"}
          className={"cld-video-player cld-fluid"}
          ref={vidRef}
          publicId={cloudPublicId}
          cloudName={cloudName}
          poster={posterSrc}
          onEnded={endFunc}
          {...vidProps}
          autoPlay
        />
      ) : (
        <video
          id={"sequence-player"}
          ref={vidRef}
          {...vidProps}
          poster={posterSrc}
          onEnded={endFunc}
          autoPlay
        >
          {vidTypes.map((vtype, index) => {
            return (
              <source
                key={index}
                src={`${src}.${vtype}`}
                type={`video/${vtype}`}
              />
            );
          })}
        </video>
      )}
      <SpriteContainer advanceFunc={advanceFunc} elements={elements} />
    </div>
  );
};

export class SequenceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false, activeIndex: 0, nextIndex: 1 };
    this.processEditClick = this.processEditClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.advanceSequence = this.advanceSequence.bind(this);
    this.videoPLayer;
  }

  // right now the modal is only used for playlist that are videos....
  closeModal() {
    this.setState({ openModal: false, activeIndex: 0 });
  }

  openModal(elementID) {
    console.log("[SEQUENCE-CONTAINER] openModal:", elementID);
    this.setState({ openModal: true });
  }

  // sequencing
  advanceSequence(direction) {
    const { activeIndex } = this.state;
    const { playlist } = this.props;
    console.log(
      "[SEQUNCE] advance function click",
      this.props,
      ", next?",
      direction,
      ", state:",
      this.state
    );
    // change the activeIndex based on next variable
    // make sure we don't go beyond or below total playlist...
    let newIndex = direction ? activeIndex + 1 : activeIndex - 1;
    let nextIndex = newIndex + 1;
    if (newIndex >= playlist.length) {
      newIndex = 0;
      nextIndex = 1;
    }
    if (newIndex < 0) {
      newIndex = playlist.length - 1;
      nextIndex = 0;
    }
    //if(this.videoPlayer){
    //this.videoPlayer.pause();
    //}
    // changing the state then updates the video source
    this.setState({ activeIndex: newIndex, nextIndex: nextIndex });
  }

  // Functions used by Editing Mode
  processEditClick(value, event) {
    event.preventDefault();
    event.stopPropagation();
    if (
      typeof this.props.triggerEditFunc !== "undefined" &&
      this.props.isEditMode
    ) {
      this.props.triggerEditFunc(value);
    }
  }

  assetIsSet(asset) {
    return (
      typeof this.props !== "undefined" &&
      (typeof this.props.cloudPublicId !== "undefined" ||
        typeof this.props.src !== "undefined")
    );
  }

  /**
sequence1 : {
  type: "sequence",
  use: "sequence1",
  playlist : [
    {
      autoAdvance: "next",
      assetID : "5ace1e209d63e964941048c3",
      assetType : "video",
      cloudPublicId : "9bc41362d65b424a980c1ee7/carbon-capture-clip-01-intro_luhsig",
      elements : {}
    },
    {
      autoAdvance: "loop",
      assetID : "5ace1e209d63e964941048c4",
      assetType : "video",
      cloudPublicId : "9bc41362d65b424a980c1ee7/carbon-capture-clip-01-loop_kaefxx",
      elements : {
          sprite1 : {
            entry : "00:00:00",
            exit : "00:00:00",
            type : "button",
            use : "sprite1",
            text : {en:"Continue"}
          },
      }
    },
    {
      autoAdvance: "none",
      assetID : "5ace1e209d63e964941048c5",
      cloudPublicId : "9bc41362d65b424a980c1ee7/carbon-capture-clip-02-intro_ysjxno",
      src : "path/to/offline/video/file",
      elements : { }
    }            
  ]
}
*/
  render() {
    const {
      layout, // slideshow or other scene layout name
      className,

      // cms related
      isEditMode,
      elementID,
      assetFit,
      isActiveEditingElement,
      assetSelectorFunc,

      // sequencer
      playlist
    } = this.props;
    // console.log('[SEQUENCE CONTAINER] props', this.props, ', state', this.state);
    //console.log("[ASSETCONTAINER] props:", this.props);

    // NOTE we need the abiilty if we are in edit mode to
    // 1. add a special onClick function
    // 2. allow the different types

    let content;

    const showTextClass = showAssetText ? "with-text" : "";
    const extension = /(?:\.([^.]+))?$/;

    const clickFunc = isEditMode
      ? event => {
          event.preventDefault();
          event.stopPropagation();
          this.assetIsSet()
            ? this.processEditClick(elementID, event)
            : assetSelectorFunc(elementID);
        }
      : null;
    const editClass = isEditMode
      ? `edit-mode ${layout} ${
          isActiveEditingElement ? "active-editing" : ""
        } ${this.assetIsSet() ? "" : "no-image"}`
      : "";

    // target the activeAsset
    let activeAsset = playlist[this.state.activeIndex];
    // target props
    const {
      assetID,
      assetType, // image|audio|video
      // cloudinary
      cloudName,
      cloudPublicId,
      assetConfig,
      src,

      showAssetText, // boolean to know if we need the extra text, change to showAssetText
      metaConfig,

      // multimedia
      controls,
      autoPlay,
      loop,
      playAsModal,
      triggerModalFunc,
      poster,
      posterSrc
    } = activeAsset;
    // first pull from the props, however the modal is always autoplay
    let vidProps = { controls, autoPlay, loop, poster };

    let useCloudinary = src ? false : true;
    let vidType = "mp4"; // this can later be read from project data and passed down
    // be sure backgronds ARE never played as modal
    if (playAsModal || _isUndefined(playAsModal)) {
      // MODAL PLAYBACK VIDEO
      vidProps = { autoPlay, loop }; //don't have controlls on modal
      // the posterframe will be a special url
      // for a sequence - the "thumb" will use poster of first asset in the sequence array
      // for the first poster frame that shows we use the default, for all the rest we will want the first frame
      // we also need to target the next in the list in order to preload the poster frame
      const imgPosterSrc = useCloudinary
        ? `${location.protocol}//res.cloudinary.com/${cloudName}/video/upload/${
            this.state.activeIndex > 0 || (this.state && this.state.openModal)
              ? "so_0.01/"
              : ""
          }${cloudPublicId}.jpg`
        : posterSrc;

      console.log("[SEQUENCE CONTAINER] activeAsset", activeAsset);
      content = (
        <div
          className={`${className} video-poster-frame-container ${showTextClass}`}
          onClick={isEditMode ? null : this.openModal}
        >
          <ImageTag
            src={imgPosterSrc}
            className={"img-wrapper"}
            isPoster={true}
            cloudPublicId={cloudPublicId}
            layout={layout}
          >
            <Icon icon="play" className="play" />
          </ImageTag>

          <AssetText
            show={isEditMode ? false : showAssetText}
            metaConfig={metaConfig}
          />
          {/* --- SEQUENCE MODAL---  */}
          {isEditMode ? null : (
            <Dialog
              className={`asset-modal ${layout}`}
              open={this.state && this.state.openModal}
              onCancel={this.closeModal}
              // This title will not work.
              title={
                <header>
                  <div className="modal-close" onClick={this.closeModal}>
                    <Icon icon="close" />
                  </div>
                </header>
              }
            >
              <section className="sequence-content">
                <SequenceAsset
                  className={className}
                  vidRef={el => (this.videoPlayer = el)}
                  useCloudinary={useCloudinary}
                  vidProps={vidProps}
                  vidType={vidType}
                  advanceFunc={this.advanceSequence}
                  {...activeAsset}
                  posterSrc={imgPosterSrc}
                />
                <ImagePreloader
                  isPoster={true}
                  cloudName={cloudName}
                  preloadList={[playlist[this.state.nextIndex]]}
                  frame="so_0.01/"
                />
              </section>
            </Dialog>
          )}
        </div>
      );
    } else {
      // INLINE PLAYBACK VIDEO
      content = (
        <div
          className={`${editClass} ${className} sequence-container ${showTextClass}`}
          onClick={clickFunc}
        >
          <SequenceAsset
            className={className}
            useCloudinary={useCloudinary}
            vidRef={el => (this.videoPlayerRef = el)}
            vidProps={vidProps}
            vidType={vidType}
            {...activeAsset}
            posterSrc={imgPosterSrc}
            advanceFunc={this.advanceSequence}
          />
          <AssetText
            show={isEditMode ? false : showAssetText}
            metaConfig={metaConfig}
          />
          <ImagePreloader
            isPoster={true}
            cloudName={cloudName}
            preloadList={[playlist[this.state.nextIndex]]}
          />
        </div>
      );
    }

    // if we are Editing and there is nothing add the placeholder
    if (_isEmpty(playlist) && isEditMode) {
      content = (
        <div
          className={`empty-selector ${editClass} ${className}`}
          onClick={clickFunc}
        >
          <div className="click-to-edit-container">
            <h3>Click to Edit</h3>
          </div>
        </div>
      );
    }

    //console.log('[SEQUENCE CONTAINER] decide what to return...');
    if (_isEmpty(playlist) && !isEditMode) {
      // nothing to write out if the data isnt there
      // just as a catch if not editing AND no asset is present
      return null;
    } else {
      return content;
    }
  }

  // Preload poster images at the earliest possible lifecycle event
  // NOTE: not sure we want this here, seems like only if its inline, so started a simple preload component

  componentDidUpdate(prevProps, prevState) {
    //console.log("[SEQUENCE CONTAINER] onComponentDidUpdate prevState.activeIndex:",prevState.activeIndex ,"state.activeIndex", this.state.activeIndex);
    //console.log("[SEQUENCE CONTAINER] onComponentDidUpdate, modal:", this.state.openModal);
    if (this.state.openModal === true) {
      //console.log("[SEQUENCE CONTAINER] onComponentDidUpdate videoPlayer:", this.videoPlayer);
      //console.log("[SEQUENCE CONTAINER] onComponentDidUpdate this.videoPlayerRef:", this.videoPlayerRef);
      // check to only the first time...
      //if(!this.videoPlayer ){
      // could do a check for isCloudinary here to determine how to target
      //let activeAsset = this.props.playlist[this.state.activeIndex];
      //  const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: activeAsset.cloudName});
      //console.log("[SEQUENCE CONTAINER] cloudinaryCore ->", cloudinaryCore);
      //this.videoPlayer = cloudinaryCore.video('sequence-player'); // use the core to target?
      //this.videoPlayer.playlist(this.props.playlist);
      //console.log("[SEQUENCE CONTAINER] videoPlayer ->", this.videoPlayer);
      //const seqPlayer = document.getElementById('sequence-player');
      //  }else
      if (
        this.videoPlayer &&
        prevState.activeIndex !== this.state.activeIndex
      ) {
        // NOTE i dont like using a timeOut but just not sure how else to accomplish, without it there is an odd flicker when switching because the previous video is still playing
        this.videoPlayer.pause();
        let pause = setTimeout(() => {
          clearTimeout(pause);
          this.videoPlayer.load();
        }, 500);
        //console.log("[SEQUENCE CONTAINER] onComponentDidUpdate this.videoPlayer:", this.videoPlayer);
      }
      //
    }
  }
}
