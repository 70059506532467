import React from "react";

import { CircularProgress } from "@material-ui/core";

const Loader = props => {
  const { size, thickness, className, message } = props;
  return (
    <div className={`loader ${className}`}>
      {message ? (
        <h3>{message}</h3>
      ) : (
        <CircularProgress
          className="progress-indicator"
          size={size || 60}
          thickness={thickness || 8}
        />
      )}
    </div>
  );
};

export default Loader;
