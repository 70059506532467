import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import _cloneDeep from "lodash/cloneDeep";

import Logger from "utils/logger";
import { CardBrowser, Dialog, PreviewSelector, TopBar } from "components";
import Sidebar from "../StoryEditor/Sidebar";
import ContentContainer from "modules/ContentContainer";
import ItemSelector from "./ItemSelector";
import { projectEditorMapStateToProps } from "mapToProps/projectEditor";
import * as projectActions from "actions/projects";
import "./ProjectEditor.scss";

export class ProjectEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      // isThemeSelectorOpen: false,
      addModalOpen: false,
      deselectAll: false,
      selected: [],
      selectMode: false
    };

    this.exclusiveSelectHandler = this.exclusiveSelectHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.openItemSelector = this.openItemSelector.bind(this);
    this.closeItemSelector = this.closeItemSelector.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  componentDidMount() {
    this.props.requestProjectBrowser();
    this.props.requestStoryBrowser();
    this.props.requestCollectionBrowser();
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    If the projectRevertCopy prop is undefined, that means this component has never 
    been mounted before, and a revert copy hasn't been created before. As long as
    the project data is defined, that means the store has been populated, and the 
    project data is accessible to be copied. It may be possible that the projectData
    prop will always be defined by this point, but I'm just not certain, so we're
    checking to make certain.

    Otherwise, if the projectRevertCopy is defined and the ID of that project doesn't
    match the projectID prop, that means a new project is being edited, and a new 
    revert copy needs to be created.
    */
    if (typeof this.props.projectRevertCopy === "undefined") {
      if (typeof this.props.projectData !== "undefined") {
        this.props.createProjectRevertCopy(this.props.projectID);
        this.props.createMainMenuRevertCopy();
      }
    } else {
      if (this.props.projectID !== this.props.projectRevertCopy._id) {
        this.props.createProjectRevertCopy(this.props.projectID);
        this.props.createMainMenuRevertCopy();
      }
    }
  }

  render() {
    const {
      accountID,
      projectData,
      section,
      userPreferences,
      userPreferencesSelector,
      projectItems,
      setProjectOrder,
      sidebarProps,
      sidebarMode,
      projectItemType,
      iFrameKey
    } = this.props;

    const SidebarActionBar = (
      <div>
        <div className="story-type-container">
          {projectData ? projectData.title : ""}
        </div>
      </div>
    );

    let sidebarActions;
    if (section === "publish") {
      sidebarActions = {
        handleInputChange: (prop, value, isLocalized) => null,
        handleSave: data => this.props.publishProject(data),
        setSaved: value => Logger.debug({ value }, "[ProjectEditor] Set Saved"),
        saveLabel: "Publish",
        revertLabel: "Cancel",
        showButtons: true
      };
    } else if (section === "settings" || section === "design") {
      sidebarActions = {
        handleInputChange: (prop, value, isLocalized) =>
          this.props.handleSettingsInputChange(prop, value, isLocalized),
        handleSave: () => {
          // Update the project revert copy to reflect the current state of
          // the project now that it's being saved.
          this.props.createProjectRevertCopy();
          this.props.saveProject();
        },
        handleRevert: () => this.props.revertProjectChanges(),
        changeLanguage: value => this.props.changeLanguage(value),
        languages: this.props.languages,
        langID: this.props.langID,
        saveLabel: "Save",
        showButtons: true
      };
    } else if (section === "mainmenu") {
      sidebarActions = {
        handleInputChange: (prop, value, isLocalized) =>
          this.props.handleMainMenuInputChange(prop, value, isLocalized),
        handleSave: () => {
          // Update the scene revert copy to reflect the current state of
          // the scene now that it's being saved.
          this.props.createMainMenuRevertCopy();
          this.props.updateMainMenu();
        },
        handleRevert: () => this.props.revertMainMenuChanges(),
        changeLanguage: value => this.props.changeLanguage(value),
        languages: this.props.languages,
        langID: this.props.langID,
        saveLabel: "Save",
        showButtons: true
      };
    }
    const cardBrowserConfig = {
      ...this.props.cardBrowserConfig,
      selectMode: this.state.selectMode,
      selected: this.state.selected
    };
    const topBar = (
      <TopBar
        cardBrowserConfig={cardBrowserConfig}
        exclusiveSelectHandler={this.exclusiveSelectHandler}
        proceedToAdd={() => {
          this.openItemSelector();
        }}
        openDeleteModal={this.openDeleteModal}
      />
    );

    const destSubDomain =
      process.env.BUILD_ENV === "local" ||
      process.env.BUILD_ENV === "development"
        ? "dev.dest2"
        : "dest";
    const projectPath =
      projectData && projectData._id
        ? `${projectData._id}${
            projectData.themeId ? `?themeId=${projectData.themeId}` : ""
          }`
        : "";

    const iFrameSrc = `https://${destSubDomain}.storycrafter.net/${projectPath}`;

    return (
      <div className="cms-screen project-editor">
        <Helmet>
          <title>Project Editor</title>
        </Helmet>
        <Sidebar
          {...sidebarProps}
          actionBar={SidebarActionBar}
          {...sidebarActions}
        />

        {section === "stories" || section === "collections" ? (
          <ContentContainer
            isLoading={cardBrowserConfig.isLoading}
            className="content-container"
            selectMode={cardBrowserConfig.selectMode}
            sidebarMode={sidebarMode}
            topBar={topBar}
          >
            {projectItems.length > 0 ? (
              <CardBrowser
                // className="project-editor"
                thumbHelperClassName="project-editor"
                minCardSize={this.props.minCardSize}
                items={projectItems}
                itemName={this.props.projectItemType}
                setCardMargin={20}
                enableKeys={false}
                deselectAll={this.state.deselectAll}
                editClickHandler={this.props.editClickHandler}
                showButtons
                isSequentiable
                executeOnReSort={setProjectOrder}
                selectHandler={this.selectHandler}
                selectMode={this.state.selectMode}
                selected={this.state.selected}
                exclusiveSelectHandler={this.exclusiveSelectHandler}
                backClickHandler={() => this.exclusiveSelectHandler(null)}
              />
            ) : null}
          </ContentContainer>
        ) : (
          <div className="content-container">
            <div className="relative-content-container">
              <div className="header-container">
                <div className="header-content">
                  <PreviewSelector
                    value={userPreferences.preview}
                    userPreferencesSelector={userPreferencesSelector}
                  />
                </div>
              </div>
              <div className="container-body">
                <div
                  className={`project-editor-container preview-${
                    userPreferences.preview || "dm"
                  }`}
                >
                  {/* 
                  Don't waste a network request on the base dest URL.
                  Wait for the project path to be defined.
                  */}
                  {projectPath !== "" && (
                    <iframe
                      key={iFrameKey}
                      style={{ width: "100%", height: "100%", border: "none" }}
                      src={iFrameSrc}
                    ></iframe>
                  )}
                </div>
                {sidebarProps.isFormDataDirty && (
                  <div className="notice-overlay">
                    <span>Save to see changes.</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {section === "publish" ? (
          <div className="publish-container">Content for Publish Section</div>
        ) : null}
        {this.state.addModalOpen && (
          <ItemSelector
            open
            projectID={projectData ? projectData._id : null}
            itemType={projectItemType}
            closeAction={this.closeItemSelector}
          />
        )}

        <Dialog
          content='Press "Delete" to remove the selected stories from the project. This will not delete the stories from your account.'
          onConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          onCancel={this.closeDeleteModal}
          open={this.state.deleteModalOpen}
          title="Remove Stories From Project?"
        />
      </div>
    );
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    // this.props.setLibraryIsLoading(true); //NOTE: is this correct?
    this.props.deleteStoriesFromProject(this.state.selected);
    this.exclusiveSelectHandler(null); // deselect
  }

  openItemSelector() {
    this.setState({ addModalOpen: true });
  }

  closeItemSelector() {
    this.setState({ addModalOpen: false });
  }

  selectHandler(assetId) {
    const existingSelected = _cloneDeep(this.state.selected);
    const index = existingSelected.indexOf(assetId);

    if (index === -1) {
      existingSelected.push(assetId);
    } else {
      existingSelected.splice(index, 1);
    }
    const selectMode = existingSelected.length > 0;

    this.setState({ selected: existingSelected, selectMode });
  }

  exclusiveSelectHandler(assetId) {
    const removingOneAsset =
      this.state.selected.length === 1 && this.state.selected[0] === assetId;
    const selected = assetId && !removingOneAsset ? [assetId] : [];
    this.setState({ selected, selectMode: selected.length > 0 });
  }
}

ProjectEditor.defaultProps = {
  isLoading: false
};

ProjectEditor.propTypes = {
  isLoading: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return projectEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    username,
    projectID,
    projectType,
    projectData,
    sidebarProps,
    langID
  } = stateProps;
  const { storyMenuSceneID } = projectData;
  const { navigation } = sidebarProps;
  return Object.assign({}, stateProps, {
    requestStoryBrowser: () => dispatchProps.requestStoryBrowser(account),
    requestCollectionBrowser: () =>
      dispatchProps.requestCollectionBrowser(account),
    requestProjectBrowser: () => dispatchProps.requestProjectBrowser(username),
    setProjectOrder: (oldIndex, newIndex) =>
      dispatchProps.setProjectOrder(projectID, oldIndex, newIndex),
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    publishProject: data => {
      dispatchProps.publishProject(projectID, projectType, data);
    },
    handleSettingsInputChange: (prop, value, isLocalized) => {
      dispatchProps.setProjectProp(projectID, prop, value, isLocalized, langID);
    },
    saveProject: () => {
      dispatchProps.updateProject(projectID);
    },
    deleteStoriesFromProject: data => {
      dispatchProps.deleteStoriesFromProject(projectID, data);
    },
    createProjectRevertCopy: () =>
      dispatchProps.createProjectRevertCopy(projectID),
    revertProjectChanges: () => dispatchProps.revertProjectChanges(),
    //
    // Main Menu Dispatch Functions
    //
    handleMainMenuInputChange: (prop, value, isLocalized) => {
      // No idea why the form object is called navigation.
      dispatchProps.setMainMenuElement(
        storyMenuSceneID,
        prop,
        value,
        isLocalized,
        langID,
        prop !== "layoutID" ? false : true,
        prop !== "background" ? false : true,
        navigation
      );
    },
    createMainMenuRevertCopy: () =>
      dispatchProps.createMainMenuRevertCopy(storyMenuSceneID),
    revertMainMenuChanges: () => dispatchProps.revertMainMenuChanges(),
    updateMainMenu: () => dispatchProps.updateMainMenu(storyMenuSceneID)
  });
}

export default connect(
  mapStateToProps,
  projectActions,
  mergeProps
)(ProjectEditor);
